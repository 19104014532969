import { useTrimesh } from "@react-three/cannon";
import { RigidBody } from "@react-three/rapier";
import { useEffect, useRef } from "react";
import { FunctionVariableContext, SceneContext } from "../../context/game-context";

export const ChangeSceneDetection = (props) => {
    
    const setScene = SceneContext((state) => state.setScene);
    const setChangeSceneCounter = SceneContext((state) => state.setChangeSceneCounter);

    useEffect(() => {
        return () => {
            props.data.geometry.geometry.dispose();
        }
    }, []);

    const onCollideBegin = () => {
        if (setScene) {
            setChangeSceneCounter(props.data.data.scene, props.data.data.displayName ? props.data.data.displayName : props.data.data.scene);
        }
    }

    const onCollideEnd = () => {
        if (setScene) {
            setChangeSceneCounter(null);
        }
    }


    return (
        <RigidBody
            colliders={"trimesh"}
            type={"kinematicPosition"}
            sensor
            onIntersectionEnter={() => {
                onCollideBegin();
            }}
            onIntersectionExit={() => {
                onCollideEnd();
            }}
            includeInvisible
        >
            <primitive object={props.data.geometry} visible={false}/>
        </RigidBody>
    )
}