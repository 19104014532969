import { useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import moment from 'moment';
import { syncActivity } from "../../store/activity.store";
import { GamePlayerContext, LocalPlayerContext, VisitorTrackerContext, SceneContext } from "../context/game-context";
import { useWeb3Service } from "../../blockhain/provider/web3.provider";
import { useThree } from "@react-three/fiber";
import Cookies from 'universal-cookie';

const cookiesObj = new Cookies();
export const VisitorTracker = ({ sceneID }) => {
    const [cookies, setCookie] = useCookies([`animaverse`]);
    const [time, setTime] = useCookies(null);
    const currentSceneID = useRef(sceneID.current);
    const counter = useRef(1);
    const getFirstPlayerId = LocalPlayerContext((state) => state.id);
    let intervalDuration = useRef();
    const {
        isAuthenticated,
        user,
    } = useWeb3Service();
    const isAccountAuthenticated = useRef(isAuthenticated);
    const userIdRef = useRef(isAuthenticated ? user.id : getFirstPlayerId);

    useEffect(() => {
        // addin1g handler is authenticated
        isAccountAuthenticated.current = isAuthenticated;
        userIdRef.current = isAuthenticated ? user.id : getFirstPlayerId;
    }, [isAuthenticated]);
    useEffect(() => {
        // setCookie("animaverse", null)
        cookiesVisitSceneHandler();
        sendCookieHandler();


        let eventSubs = VisitorTrackerContext.subscribe(state => ({ event: state.event, additionalData: state.additionalData }), (data) => {
            if (data.event) {
                // showModalHandler(data)
                insertEvent(data.event, data.additionalData);
            }
        });

        
        const sceneSub = SceneContext.subscribe(state => state.scene, (data) => {
            currentSceneID.current = data;
            cookiesVisitSceneHandler();
        });

        const intervalTime = setInterval(() => {
            setTime(moment().format("Y-m-d|H:m:s"));
            counter.current += 1;
            if (counter.current % 30 == 0) {
                sendCookieHandler();
            }

        }, 1000)
        

        return () => {
            eventSubs();
            sceneSub();
            clearInterval(intervalTime);
        }
    }, [])

    useEffect(() => {
        currentSceneID.current = sceneID.current;
        cookiesVisitSceneHandler();
    }, [sceneID.current])

    const insertEvent = (type, additionalData) => {
        // adding duration for this scene
        const getCookie = cookiesObj.get("animaverse");
        const generateNewCookies = getCookie != 'null' ? getCookie : [];

        /* DURATION SECTION */
        // find last duration
        generateNewCookies.push({
            type,
            scene: currentSceneID.current,
            eventCount: 1,
            additionalData: additionalData,
            idUser: userIdRef.current,
            isAuthenticated: isAccountAuthenticated.current
        })

        cookiesObj.set("animaverse", JSON.stringify(generateNewCookies), {
            path: '/',
            maxAge: 2 * 24 * 3600
        })

    }

    const sendCookieHandler = async () => {

        const getCookie = cookiesObj.get("animaverse");

        if (cookiesObj.get("animaverse") != null) {
            // get user id
            const isSuccess = await syncActivity(getFirstPlayerId, cookiesObj.get("animaverse"));
            if (isSuccess) cookiesObj.set("animaverse", []);
        }

    }

    useEffect(() => {
        cookiesDurationHandler();
    }, [time])

    const cookiesVisitSceneHandler = () => {
        // adding duration for this scene
        const getCookie = cookiesObj.get("animaverse");
        const generateNewCookies = getCookie && getCookie != 'null' && getCookie != 'undefined' ? getCookie : [];

        /* DURATION SECTION */
        // find last duration
        const lastDur = generateNewCookies ? generateNewCookies.find((r) => r.type == 'scene_visit' && r.scene == currentSceneID.current) : null;
        if (lastDur == null) {
            generateNewCookies.push({
                type: "scene_visit",
                scene: currentSceneID.current,
                visit: 1,
                idUser: userIdRef.current,
                isAuthenticated: isAccountAuthenticated.current
            })
        } else {
            lastDur.visit += 1;
        }
        /*  END DURATION SECTION */

        /* Save the cookie */
        cookiesObj.set("animaverse", JSON.stringify(generateNewCookies), {
            path: '/',
            maxAge: 2 * 24 * 3600
        })
    }

    const cookiesDurationHandler = () => {
        // adding duration for this scene
        const getCookie = cookiesObj.get("animaverse");
        const generateNewCookies = getCookie != 'null' ? getCookie : [];

        /* DURATION SECTION */
        // find last duration
        const lastDur = generateNewCookies ? generateNewCookies.find((r) => r.type == 'scene_stay_duration' && r.scene == currentSceneID.current) : null;
        if (lastDur == null) {
            generateNewCookies.push({
                type: "scene_stay_duration",
                scene: currentSceneID.current,
                duration: 1,
                idUser: userIdRef.current,
                isAuthenticated: isAccountAuthenticated.current
            })
        } else {
            lastDur.duration += 1;
        }
        /*  END DURATION SECTION */

        /* Save the cookie */
        cookiesObj.set("animaverse", JSON.stringify(generateNewCookies), {
            path: '/',
            maxAge: 2 * 24 * 3600
        })

        // console.log(cookiesObj.get("animaverse"))
    }
    return <></>
}