
import { useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Container, Fade, Modal, Nav, Row, Tab, Image, Form, InputGroup, Spinner } from 'react-bootstrap';
import { boostVotePaymentCheck, buyVote, claimEvent, claimVote, getAchievement, getEvent, getPrize, initiateBoostVotePayment, redeem } from '../../../store/treasure-hunt.store';
import { FunctionVariableContext, GamePlayerContext, LocalPlayerContext, NotificationContext, TreasureHuntContext, VisitorTrackerContext } from '../../context/game-context';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWeb3Service } from '../../../blockhain/provider/web3.provider';
import AccountComponent from './account-component';
import { v4 as uuidv4 } from 'uuid';


function iconVictor(content, type) {
    return <div className={"treasure-hunt-icon-modal"}>
        <svg
            data-src="./icon/victor-left.svg"
            fill="currentColor"
            className="full-height icon-left"
        />
        {
            type == 'icon' &&
            <svg
                data-src={`./icon/${content}.svg`}
                fill="currentColor"
                className=" full-height icon-center"
            />
        }
        {
            type == 'text' && <h4>{content}</h4>
        }
        <svg
            data-src="./icon/victor-right.svg"
            fill="currentColor"
            className=" full-height icon-right"
        />
    </div>
}

export default function TreasureHuntEvent({ eventCode, currentScene, showTutorial, showAchievementModalRef, showRedeemModalRef }) {
    const {
        isAuthenticated,
        user,
    } = useWeb3Service();

    const [showModal, setShowModal] = useState(false);
    const [eventData, setEventData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [prizeData, setPrizeData] = useState(null);
    const [openGetPoints, setOpenGetPoints] = useState(false);
    const [newPoint, setNewPoint] = useState(0);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [openConfirmationVote, setOpenConfirmationVote] = useState(false);
    const [confirmationMsg, setConfirmationMsg] = useState(null);
    const [confirmationData, setConfirmationData] = useState(null);
    const [prizeRedeemMsg, setPrizeRedeemMsg] = useState(null);
    const [showModalLogin, setShowModalLogin] = useState(false);
    const [hasDailySignIn, setHasDailySignIn] = useState(true);
    const [showDailySignInModal, setShowDailySignInModal] = useState(false);
    const [showInformationModal, setShowInformationModal] = useState(false);
    const [contentInformationModal, setContentInformationModal] = useState("");
    const [iconInformationModal, setIconInformationModal] = useState("");
    const [showModalAchievement, setShowModalAchievement] = useState(false);
    showAchievementModalRef.current = setShowModalAchievement;
    const [showModalRedeem, setShowModalRedeem] = useState(false);
    showRedeemModalRef.current = setShowModalRedeem;
    const [showModalRedeemResponse, setShowModalRedeemResponse] = useState(false);
    const [modalRedeemResponseTitle, setModalRedeemResponseTitle] = useState("");
    const [modalRedeemResponseContent, setModalRedeemResponseContent] = useState("");
    const [time, setTime] = useState(null)
    const eventDataRef = useRef();
    const userDataRef = useRef();
    const achievementDataRef = useRef();
    const phoneNumberRef = useRef();
    const isAccountAuthenticated = useRef(isAuthenticated);

    // vote data
    const [freeVote, setFreeVote] = useState(false);
    const [canVote, setCanVote] = useState(true);
    const [voteList, setVoteList] = useState(null);
    const [showModalVote, setShowModalVote] = useState(false);
    const [loadingBtnVote, setLoadingBtnVote] = useState(false);
    const [boostVoteItem, setBoostVoteItem] = useState("");
    const [boostVoteKey, setBoostVoteKey] = useState("");
    const [showBoostVoteModal, setShowBoostVoteModal] = useState(false);
    const [showBoostVotePhoneModal, setShowBoostVotePhoneModal] = useState(false);
    const [showBoostVotePaymentCheckModal, setShowBoostVotePaymentCheckModal] = useState(false);
    const [countdownVotePayment, setCountdownVotePayment] = useState(10);
    const [startCoundownVotePayment, setStartCoundownVotePayment] = useState(false);
    const [statusVotePayment, setStatusVotePayment] = useState("pending");
    const [btnManualRecheckDisabled, setBtnManualRecheckDisabled] = useState(false);
    const [showSuccessBoostVote, setShowSuccessBoostVote] = useState(false);
    const [showFailedBoostVote, setShowFailedBoostVote] = useState(false);
    const [boostPointReward, setBoostPointReward] = useState(0);
    const [orderIdSaved, setOrderIdSaved] = useState("");
    const [disabledConfirmPayment, setDisabledConfirmPayment] = useState(false);
    const setOpenLoginPopup = FunctionVariableContext(state => state.setOpenLoginPopup);

    // for test purpose
    const [devStatusPayment, setDevStatusPayment] = useState("pending");
    // end vote data

    // schedule
    const [listSchedule, setListSchedule] = useState([])
    const [currentSchedule, setCurrentSchedule] = useState(null)
    const [showModalEvent, setShowModalEvent] = useState(false)
    // end schedule

    const getFirstPlayerId = LocalPlayerContext((state) => state.id);
    const addCollectedStaticItemList = GamePlayerContext((state) => state.addCollectedStaticItemList);
    const addCollectedDropItemList = GamePlayerContext((state) => state.addCollectedDropItemList);
    const getCollectedStaticItemList = GamePlayerContext((state) => state.getCollectedStaticItemList);
    const showEventModalTrigger = TreasureHuntContext((state) => state.showEventModal);

    const [userId, setUserId] = useState(getFirstPlayerId)
    const userIdRef = useRef(getFirstPlayerId)

    const addToast = NotificationContext((state) => state.addToast);
    const setPoint = TreasureHuntContext((state) => state.setPoint);

    const minuteRef = useRef();
    const hourRef = useRef();
    const dayRef = useRef();

    useEffect(() => {
        // adding handler is authenticated
        isAccountAuthenticated.current = isAuthenticated;
        if (user.id) {
            setUserId(user.id)
            userIdRef.current = user.id;
        }
    }, [isAuthenticated]);

    useEffect(() => {
        getEventData();
    }, [userId]);

    useEffect(() => {
        setOpenLoginPopup((data) => {
            showLoginFirstHandler(data);
        });
    });

    useEffect(() => {
        setOpenLoginPopup((data) => {
            showLoginFirstHandler(data);
        });
    });

    useEffect(() => {

        getEventData();
    }, [showModalAchievement]);

    const getEventData = async () => {
        const getData = await getEvent(eventCode, userIdRef.current);
        const prize = await getPrize(eventCode);
        const achievement = await getAchievement(eventCode, userIdRef.current)

        const userData = getData.userdata;
        setUserData(userData);
        // setPoint(userData.points);
        setPrizeData(prize);
        setEventData(getData.data);
        eventDataRef.current = getData.data;
        userDataRef.current = userData;
        achievementDataRef.current = achievement


        const mainEvent = eventDataRef.current.find((r) => r.type == 'check_in_main_event');
        setListSchedule(mainEvent ? mainEvent.listSchedule : []);

        // adding collectedStaticItem 
        if (userData.claimedEvent) {
            userData.claimedEvent.filter((r) => r.type == 'find_dg_icon' && moment(r.createdAt).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD"))
                .map((r) => {
                    addCollectedStaticItemList(r.id);
                });

            // check daily sign in
            const isAlreadySignIn = userData.claimedEvent.find((rb) => rb.type == "daily_login" && rb.date == moment().format("YYYY-MM-DD")) != null;
            // make  sure daily sign in date is available
            const isSignInDateAvailable = getData.data.find((r) => r.scheduled == 'daily' && r.type == 'daily_login' && r.date == moment().format("YYYY-MM-DD")) != null;
            if (isSignInDateAvailable) {
                setHasDailySignIn(isAlreadySignIn);
            }

            const isRegisterUserHasClaimed = userData.claimedEvent.find((rb) => rb.type == "user_register") != null;
            // checking register
            if (isAccountAuthenticated.current && !isRegisterUserHasClaimed) {
                claimEventHandler("user_register", 5);
            }
        }

    }
    const setVisitorTrackerData = VisitorTrackerContext((state) => state.setVisitorTrackerData);
    useEffect(() => {
        getEventData();


        let pointSubs = TreasureHuntContext.subscribe(state => ({ pointCollected: state.pointCollected, type: state.pointType }), (data) => {
            if (data.pointCollected != null || data.type != null) {
                addPointHandler(data.pointCollected, data.type)
                setVisitorTrackerData(data.type, data.pointCollected)
            }

        });

        let voteModalSubs = TreasureHuntContext.subscribe(state => state.voteModal, (data) => {
            if (data) {
                showModalHandler(data)
                setVisitorTrackerData("show_modal_vote", data)
            }


        });


        let tenantSubs = TreasureHuntContext.subscribe(state => state.clickTenant, (data) => {

            if (data) {
                // showModalHandler(data)
                tenantPointHandler(data);
                setVisitorTrackerData("open_booth", data)
            }


        });

        let totemSubs = TreasureHuntContext.subscribe(state => state.totem, (data) => {

            if (data) {
                // showModalHandler(data)
                totemPointHandler(data);
                setVisitorTrackerData("totem", data)
            }


        });

        let showModalEventSub = TreasureHuntContext.subscribe(state => state.eventModal, (data) => {
            if (data) {
                showModalEventHandler()
            }


        });


        // counter interval
        const intervalTime = setInterval(() => {
            setTime(new Date().toLocaleTimeString());
        }, 1000)

        return () => {
            pointSubs();
            voteModalSubs();
            tenantSubs();
            totemSubs();
            showModalEventSub();
            clearInterval(intervalTime)
        }

    }, []);

    useEffect(() => {
        if (currentScene == 'dgplaza' && !showTutorial && !hasDailySignIn && isAuthenticated) {
            setShowDailySignInModal(true);
        }
    }, [showTutorial])

    useEffect(() => {
        const momentNow = moment();
        const momentMinuteNow = momentNow.clone().format("mm");
        const momentHourNow = momentNow.clone().format("hh");
        const momentDayNow = momentNow.clone().format("DD");

        if (startCoundownVotePayment) {
            if (countdownVotePayment == 0) {
                recheckVotePayment();
            } else {
                // console.log("TIME UPDATED", startCoundownVotePayment, countdownVotePayment)
                setCountdownVotePayment(countdownVotePayment - 1);
            }
        }


        if (minuteRef.current != momentMinuteNow) {
            // trigger changes minute
            minuteRef.current = momentMinuteNow;
        }

        if (hourRef.current != momentHourNow) {
            // trigger changes hour
            hourRef.current = momentHourNow;
        }

        if (dayRef.current != momentDayNow) {
            // trigger changes day
            dayRef.current = momentDayNow;

            // check free vote
            const checkFreeVote = userData && userData.claimedEvent ? userData.claimedEvent.find((r) => r.type == 'vote') : null;
            if (!checkFreeVote) {
                setFreeVote(true);
                setCanVote(true);
            } else {

                setCanVote(true);
            }
        }
        // seee schedule
        const findSchedule = listSchedule.find((r) => moment().unix() >= r.start && moment().unix() <= r.end)
        if (findSchedule != null) {
            setCurrentSchedule(findSchedule)
        } else {
            setCurrentSchedule(null)
        }
    }, [time])


    const showLoginFirstHandler = (state) => {
        setShowModalLogin(state);
    }

    const showModalHandler = async (data) => {


        getEventData();
        const findVote = eventData ? eventData.find((r) => r.type == 'vote' && r.key == data) : (eventDataRef.current ? eventDataRef.current.find((r) => r.type == 'vote' && r.key == data) : null);

        const getUserDataCond = userDataRef.current;
        const isFreeVote = getUserDataCond ? getUserDataCond.claimedEvent.find((r) => r.type == 'vote' && r.key == data) == null : false;
        setFreeVote(isFreeVote)
        setShowModalVote(true)
        setVoteList({ key: data, voteList: findVote.voteList, label: findVote.label })
    }

    const claimEventHandler = async (type, key) => {
        const claim = await claimEvent(eventCode, type, key, userIdRef.current, null, addToast);
        if (claim) {
            setUserData(claim);
            userDataRef.current = claim;

            setNewPoint(claim.lastEvent.point);
            setOpenGetPoints(true)
        }
    }

    const voteHandler = async (type, key, voteKey) => {
        setLoadingBtnVote(true);
        const claim = await claimVote(eventCode, type, key, voteKey, freeVote ? 1 : 1, userIdRef.current, addToast);
        if (claim) {
            setUserData(claim);
            userDataRef.current = claim;

            const isFreeVote = claim.claimedEvent.find((r) => r.type == 'vote' && r.key == key) == null;
            setFreeVote(isFreeVote)
            setOpenConfirmationVote(false)

            setNewPoint(claim.lastEvent.point);
            setOpenGetPoints(true)
        }
        setLoadingBtnVote(false);
    }

    const buyVoteHandler = async (type) => {
        setLoadingBtnVote(true);
        const claim = await buyVote(eventCode, type, 1, userIdRef.current, addToast);
        if (claim) {
            setUserData(claim);
            userDataRef.current = claim;
            setOpenConfirmation(false)
        }
        setLoadingBtnVote(false);
    }

    const addPoint = TreasureHuntContext((state) => state.addPoint);
    const addPointHandler = async (id, type) => {

        if (!isAccountAuthenticated.current) {
            showLoginFirstHandler(true);
            addPoint(null);

        } else {

            if (type == 'find_dg_icon') {

                // get collected first 
                const collectedItem = getCollectedStaticItemList();
                if (collectedItem.indexOf(id) == -1) {

                    addCollectedStaticItemList(id);
                    const refEventData = eventDataRef.current;
                    const treasureHuntEvent = refEventData ? refEventData.find((r) => r.type == type) : (eventData ? eventData.find((r) => r.type == type) : null);
                    if (treasureHuntEvent) {
                        const claim = await claimEvent(eventCode, type, treasureHuntEvent.key, userIdRef.current, { id }, addToast);
                        if (claim) {
                            setNewPoint(treasureHuntEvent.point);
                            setOpenGetPoints(true);
                            setUserData(claim);
                            userDataRef.current = claim;
                        }
                    }
                }
            } else if (type == 'treasure_hunt') {

                addCollectedDropItemList(id);
                const refEventData = eventDataRef.current;
                const treasureHuntEvent = refEventData ? refEventData.find((r) => r.type == type) : (eventData ? eventData.find((r) => r.type == type) : null);
                if (treasureHuntEvent) {
                    const claim = await claimEvent(eventCode, type, treasureHuntEvent.key, userIdRef.current, { id }, addToast);
                    if (claim) {
                        setNewPoint(treasureHuntEvent.point);
                        setOpenGetPoints(true);
                        setUserData(claim);
                        userDataRef.current = claim;
                    }
                }

            } else {
                const refEventData = eventDataRef.current;
                const treasureHuntEvent = refEventData ? refEventData.find((r) => r.type == type) : (eventData ? eventData.find((r) => r.type == type) : null);
                if (treasureHuntEvent) {
                    const claim = await claimEvent(eventCode, type, treasureHuntEvent.key, userIdRef.current, { id }, addToast);
                    if (claim) {
                        setNewPoint(claim.lastEvent.point);
                        setOpenGetPoints(true);
                        setUserData(claim);
                        userDataRef.current = claim;
                    }
                }
            }
        }
    }

    const tenantPointHandler = async (id) => {

        if (!isAccountAuthenticated.current) {
            showLoginFirstHandler(true);
            addPoint(null);

        } else {
            const type = "open_booth";
            const refEventData = eventDataRef.current;
            const treasureHuntEvent = refEventData ? refEventData.find((r) => r.type == type) : (eventData ? eventData.find((r) => r.type == type) : null);

            if (treasureHuntEvent) {
                // check is special booth
                const isSpecialBooth = treasureHuntEvent.specialBoothId.indexOf(id) != -1;
                // check if user claimed
                const getUserDataCond = userDataRef.current;
                const isAlreadyClaimed = getUserDataCond ? getUserDataCond.claimedEvent.find((r) => r.type == type && r.boothId == id) != null : false;
                if (!isAlreadyClaimed) {
                    const claim = await claimEvent(eventCode, type, treasureHuntEvent.key, userIdRef.current, { boothId: id }, addToast);
                    if (claim) {
                        setNewPoint(isSpecialBooth ? treasureHuntEvent.specialPoint : treasureHuntEvent.point);
                        setOpenGetPoints(true);
                        setUserData(claim);
                        userDataRef.current = claim;
                    }

                }
            }
        }
    }



    const totemPointHandler = async (id) => {

        if (!isAccountAuthenticated.current) {
            showLoginFirstHandler(true);
            addPoint(null);

        } else {
            const type = "totem";
            const refEventData = eventDataRef.current;
            const treasureHuntEvent = refEventData ? refEventData.find((r) => r.type == type) : (eventData ? eventData.find((r) => r.type == type) : null);

            if (treasureHuntEvent) {
                // check if user claimed
                const getUserDataCond = userDataRef.current;
                const isAlreadyClaimed = getUserDataCond ? getUserDataCond.claimedEvent.find((r) => r.type == type && r.totemId == id) != null : false;
                if (!isAlreadyClaimed) {
                    const claim = await claimEvent(eventCode, type, treasureHuntEvent.key, userIdRef.current, { totemId: id }, addToast);
                    if (claim) {
                        setNewPoint(treasureHuntEvent.point);
                        setOpenGetPoints(true);
                        setUserData(claim);
                        userDataRef.current = claim;
                    }

                }
            }
        }
    }

    const redeemPrizeHandler = async (prizeCode) => {
        setLoadingBtnVote(true);
        const claim = await redeem(eventCode, prizeCode, userIdRef.current, addToast);
        if (claim) {
            setShowModalRedeemResponse(true)
            if (claim.error) {
                setModalRedeemResponseTitle("Redeem Hadiahmu Gagal");
                setModalRedeemResponseContent(claim.message);
                getEventData();
            } else {
                setModalRedeemResponseTitle("Redeem Hadiahmu Berhasil!");
                setModalRedeemResponseContent(`Kamu berhasil claim hadiahmu, segera check email kamu untuk informasi lebih lanjut`);
                setUserData(claim);
                userDataRef.current = claim;
                setOpenConfirmation(false)
            }
        }
        setLoadingBtnVote(false);
    }

    const confirmHandler = async (type, data) => {
        if (type == 'redeem') {
            setConfirmationMsg(`Are you sure , you want to redeem ${data.label} for ${data.point} Exp ?`);
            setOpenConfirmation(true)
        } else if (type == 'buy_vote') {
            setConfirmationMsg(`Are you sure , you want to buy Vote Point for ${data.point} Exp ?`);
            setConfirmationData({ type, data });
            setOpenConfirmation(true)
        } else if (type == 'vote') {
            if (!isAccountAuthenticated.current) {
                showLoginFirstHandler(true);

            } else {
                let message = `Are you sure , you want to Vote for ${data.label} ? \n\r`;
                if (!freeVote && userData.votePoint == 0) {
                    const votePoint = eventData.find((r) => r.type == 'vote_point');
                    // message += `(Your Vote Point Is Insufficient , it will consume ${votePoint.point} Exp to vote)`;
                }
                setConfirmationMsg(message);
                setOpenConfirmationVote(true);
            }
        }
        setConfirmationData({ type, data });

    }

    const userConfirmedHandler = async () => {
        if (confirmationData.type == 'redeem') {
            redeemPrizeHandler(confirmationData.data.prizeCode)
        } else if (confirmationData.type == 'buy_vote') {
            buyVoteHandler(confirmationData.data.type)
        } else if (confirmationData.type == 'vote') {
            if (!freeVote) {
                setShowBoostVoteModal(true)
            } else {
                voteHandler("vote", confirmationData.data.voteList, confirmationData.data.key)
            }
        }
    }


    const dailySignInHandler = () => {
        if (!isAccountAuthenticated.current) {
            showLoginFirstHandler(true);
        } else {
            claimEventHandler("daily_login", 1);
            setShowDailySignInModal(false);
        }
    }


    // Boost Vote Handler
    const boostVotePayment = (itemId, voteKey) => {

        setDisabledConfirmPayment(false);
        setBtnManualRecheckDisabled(false);
        setBoostVoteItem(itemId);
        setBoostVoteKey(voteKey);
        setShowBoostVoteModal(false);
        setShowBoostVotePhoneModal(true);
    }

    const confirmBoostVotePayment = async () => {
        setDisabledConfirmPayment(true);
        // generate order id
        const phoneNumber = phoneNumberRef.current.value;
        const orderId = `${moment().format("YYYYMMDD")}${moment().unix()}${phoneNumber}`;
        const dataToSend = {
            item: boostVoteItem,
            orderId,
            paymentType: "telkomsel",
            phoneNumber,
            callbackUrl: `${process.env.TREASURE_API_URL}/callback_payment`,
            event: eventCode,
            key: confirmationData.data.voteList,
            voteKey: confirmationData.data.key,
            idUser: userDataRef.current.idUser,
            boostVoteKey: boostVoteKey
        }
        setOrderIdSaved(orderId);

        const initiatePayment = await initiateBoostVotePayment(dataToSend);

        if (initiatePayment.transaction_status == 'failed') {
            setBtnManualRecheckDisabled(false);
            setDisabledConfirmPayment(false);
            setShowBoostVotePaymentCheckModal(false);
            setOpenConfirmationVote(false);
            setShowFailedBoostVote(true);
            setDevStatusPayment("pending")
        } else {

            setDisabledConfirmPayment(false);
            setShowBoostVotePhoneModal(false);
            setShowBoostVotePaymentCheckModal(true)
            recheckVotePayment(orderId);
        }
    }

    const recheckVotePayment = async (orderId) => {
        setBtnManualRecheckDisabled(true);
        const checkPayment = await boostVotePaymentCheck(orderId ? orderId : orderIdSaved);
        setStartCoundownVotePayment(false);
        setStatusVotePayment(checkPayment.transaction_status)
        if (checkPayment.transaction_status == 'pending' || checkPayment.response_code == 500) {
            setCountdownVotePayment(10);
            setStartCoundownVotePayment(true);
            setBtnManualRecheckDisabled(false);
        } else if (checkPayment.transaction_status == 'success') {
            setShowBoostVotePaymentCheckModal(false);
            setBoostPointReward(checkPayment.reward_point);
            setShowSuccessBoostVote(true);
            setOpenConfirmationVote(false);
            setDevStatusPayment("pending")
        } else if (checkPayment.transaction_status == 'failed') {
            setShowBoostVotePaymentCheckModal(false);
            setOpenConfirmationVote(false);
            setShowFailedBoostVote(true);
            setDevStatusPayment("pending")
        }
        setBtnManualRecheckDisabled(false);

        getEventData();
    }

    const finishBoostVote = () => {
        setShowSuccessBoostVote(false);
        // setNewPoint(boostPointReward);
        // setOpenGetPoints(true);
    }
    const finishFailedBoostVote = () => {
        setShowFailedBoostVote(false);
    }

    const showModalEventHandler = () => {
        getEventData();
        setShowModalEvent(true);
    }
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return eventData && <>
        <div className='points-div'>

            <span className='points'>EXP : {userData && userData.points}</span>
        </div>

        {/* <div className="button-group-vote modal-transparent">
            <div className="modal-body">
                <Button
                    variant='dark'
                    className='w-100 my-1'
                    onClick={() => {
                        showEventModalTrigger()
                    }}>
                    OPEN MAIN EVENT
                </Button><br />
            </div>
        </div> */}
        {/* <div className="button-group-vote modal-transparent">
            <div className="modal-body">
                <h5>Vote Your Favorite Team!</h5>
                {eventData.filter((r) => r.type == 'vote').map((r) => <>
                    <Button
                        variant='dark'
                        className='w-100 my-1'
                        onClick={() => {
                            setShowModalVote(true)
                            setVoteList({ key: r.key, voteList: r.voteList, label: r.label })
                        }}>

                        {r.label}
                    </Button><br />
                </>)}
            </div>
        </div> */}

        {!isAuthenticated &&
            <Container>
                <Modal
                    show={showModalLogin}
                    centered
                    contentClassName='treasure-hunt-modal'
                    size="md">
                    <Modal.Header>
                        <button type="button" className="btn-close" onClick={() => setShowModalLogin(false)} aria-label="Close"></button>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        {iconVictor('btn-account', 'icon')}
                        <h4 align="center">You need to login first</h4>
                        <br />
                        <AccountComponent />
                    </Modal.Body>
                </Modal>
            </Container>
        }

        <Container>
            <Modal
                show={showInformationModal}
                centered
                contentClassName='treasure-hunt-modal'
                size="md">
                <Modal.Header>
                    <button type="button" className="btn-close" onClick={() => setShowInformationModal(false)} aria-label="Close"></button>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {iconInformationModal && iconVictor(iconInformationModal, 'icon')}
                    {contentInformationModal}
                    <Button variant="default" className={"full-width"} onClick={() => setShowInformationModal(false)}>Okay</Button>
                </Modal.Body>
            </Modal>
        </Container>

        {/* Modal Boost Vote */}
        <Container>
            <Modal
                show={showBoostVoteModal}
                centered
                contentClassName='treasure-hunt-modal'
                size="md">
                <Modal.Header>
                    <button type="button" className="btn-close" onClick={() => setShowBoostVoteModal(false)} aria-label="Close"></button>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <h5>TIME TO BOOST VOTE</h5>
                    <Row>
                        <Col>
                            <p>
                                Here a great benefit come after boost vote,
                                you will get more Exp based on your vote.

                            </p>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Button variant="default-dark" className={"full-width"} onClick={() => boostVotePayment("5vt", "5vote")}>
                                5 Vote - Rp. 11.000
                            </Button>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Button variant="default-dark" className={"full-width"} onClick={() => boostVotePayment("10vt", "10vote")}>
                                10 Vote - Rp. 22.000
                            </Button>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Button variant="default-dark" className={"full-width"} onClick={() => boostVotePayment("25vt", "25vote")}>
                                25 Vote - Rp. 55.000
                            </Button>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Button variant="default-dark" className={"full-width"} onClick={() => boostVotePayment("50vt", "50vote")}>
                                50 Vote - Rp. 110.000
                            </Button>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Button variant="default-dark" className={"full-width"} onClick={() => boostVotePayment("100vt", "100vote")}>
                                100 Vote - Rp. 220.000
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal
                show={showBoostVotePhoneModal}
                centered
                contentClassName='treasure-hunt-modal'
                size="md">
                <Modal.Header>
                    <button type="button" className="btn-close" onClick={() => setShowBoostVotePhoneModal(false)} aria-label="Close"></button>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <h5>Masukan No Telkomsel Kamu</h5>
                    <Row>
                        <Col>
                            <p>
                                Masukan No. Telkomsel kamu agar kami dapat mengirimkan OTP konfirmasi pembayaran ke No. kamu
                            </p>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">+62</InputGroup.Text>
                                <Form.Control
                                    style={{ background: 'rgba(255, 255, 255, 0.66)', borderRadius: '3px' }}
                                    placeholder="Enter Your Message"
                                    aria-label="Enter Your Message"
                                    aria-describedby="basic-shoutout"
                                    autoComplete="off" maxLength="30"
                                    // onKeyPress={e => phoneNumberKeyPress(e)}
                                    // onFocus={() => enableControlToggle(false)}
                                    // onBlur={() => enableControlToggle(true)}
                                    ref={phoneNumberRef}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant="default-dark" className={"full-width"} disabled={disabledConfirmPayment} onClick={() => confirmBoostVotePayment()}>Konfirmasi</Button>

                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>


            <Modal
                show={showBoostVotePaymentCheckModal}
                centered
                contentClassName='treasure-hunt-modal'
                size="md">
                <Modal.Header>
                    <button type="button" className="btn-close" onClick={() => setShowBoostVotePaymentCheckModal(false)} aria-label="Close"></button>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <h5>Kami sedang memeverifikasi pembayaran anda</h5>
                    <br />
                    <Row>
                        <Col>
                            <Spinner animation="border" size="lg" />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            Auto Recheck Pada {countdownVotePayment} Detik
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Button variant="default-dark" className={"full-width"} disabled={btnManualRecheckDisabled} onClick={() => recheckVotePayment()}>Manual Check</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal
                show={showSuccessBoostVote}
                centered
                contentClassName='treasure-hunt-modal'
                size="md">
                <Modal.Header>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {iconVictor('btn-vote', 'icon')}
                    <h5>Selamat Boost Vote Berhasil!</h5>
                    <br />

                    <Row>
                        <Col>
                            Selamat kamu berhasil memberikan boost vote untuk {confirmationData && confirmationData.data.label}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Button variant="default-dark" className={"full-width"} onClick={() => finishBoostVote()}>Okay</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>


            <Modal
                show={showFailedBoostVote}
                centered
                contentClassName='treasure-hunt-modal'
                size="md">
                <Modal.Header>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {iconVictor('btn-vote', 'icon')}
                    <h5>Maaf, Boost Vote Kamu Gagal!</h5>
                    <br />

                    <Row>
                        <Col>
                            Mohon maaf, boost vote untuk {confirmationData && confirmationData.data.label} telah gagal , silahkan coba lagi yah!
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Button variant="default-dark" className={"full-width"} onClick={() => finishFailedBoostVote()}>Okay</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </Container>
        {/* End Modal Boost */}

        <Container>
            <Modal
                show={showDailySignInModal}
                centered
                contentClassName='treasure-hunt-modal'
                size="md">
                <Modal.Header>
                    <button type="button" className="btn-close" onClick={() => setShowDailySignInModal(false)} aria-label="Close"></button>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {iconVictor('btn-account', 'icon')}
                    <h4 align="center">Daily Sign In</h4>
                    <br />
                    <Button variant="default" className={"full-width"} disabled={hasDailySignIn} onClick={() => dailySignInHandler()}>Sign In</Button>
                </Modal.Body>
            </Modal>
        </Container>

        <Container>
            <Modal
                show={showModalVote}
                centered
                contentClassName='treasure-hunt-modal'
                size="lg">
                <Modal.Header>
                    <button type="button" className="btn-close" onClick={() => setShowModalVote(false)} aria-label="Close"></button>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {iconVictor('btn-voting', 'icon')}
                    {/* <Row>
                        <Col md={6} className="text-left">
                            You Have <b>{userData.votePoint}</b> Vote Points & <b>{userData.points}</b> Exp
                        </Col>
                        <Col md={6} className="text-right">
                            <Button
                                variant="default"
                                disabled={loadingBtnVote}
                                onClick={() => confirmHandler("buy_vote", eventData.find((r) => r.type == 'vote_point'))}
                            >Buy Vote Point For 20 Exp</Button>
                        </Col>
                    </Row> */}
                    <h4 align="center">{voteList && voteList.label}</h4>
                    <Row className={"cover-vote-list"}>
                        {voteList && voteList.voteList.map((r, i) => {

                            let countVoted = 0;
                            const expiredVote = moment().unix() >= 1666890000;
                            userData.claimedEvent.filter((rv) => rv.type == 'vote' && rv.voteKey == r.key).map((rq) => {
                                countVoted += rq.qtyVote
                            });
                            return <Col xs={6} sm={4} className={"divider-vote"} key={i}>
                                <Card>
                                    <Card.Body style={{ background: "#f5f5f5" }}>
                                        <Image loading="lazy" src={r.image ? `${process.env.RESOURCE_URL}${r.image}` : `/img/animaverse-logo2.png`} className="w-100 my-2" rounded={true} />
                                        <h5>{r.label}</h5>
                                    </Card.Body>
                                    <Card.Footer style={{ background: "#f5f5f5" }}>
                                        Voted {countVoted} x
                                        <br />
                                        <Button
                                            variant="default-dark"
                                            className='full-width'
                                            disabled={!canVote || loadingBtnVote || expiredVote}
                                            onClick={() => confirmHandler("vote", { voteList: voteList.key, key: r.key, label: r.label })}
                                        >{expiredVote ? "Event Vote Berakhir" : (freeVote ? "Free Vote" : "Boost Vote")}</Button>
                                    </Card.Footer>
                                </Card>
                            </Col>

                        })}

                    </Row>
                </Modal.Body>
            </Modal>
        </Container>
        {/* 
        <Button
            variant='transparent'
            className='treasure-event-button'
            onClick={() => setShowModalAchievement(true)}>

            Achievement
        </Button>

        <Button
            variant='transparent'
            className='treasure-event-button'
            style={{ top: "190px" }}
            onClick={() => setShowModalRedeem(true)}>

            Redeem
        </Button> */}
        <Container>
            <Modal
                show={openGetPoints}
                centered
                contentClassName='treasure-hunt-modal'
                size="md">
                <Modal.Header>
                    <button type="button" className="btn-close" onClick={() => setOpenGetPoints(false)} aria-label="Close"></button>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {iconVictor('EXP', 'text')}

                    <h4>+ {newPoint} Exp Points Collected</h4>
                    <br />

                    <p>
                        Kamu baru saja mendapatkan {newPoint} Exp,
                        temukan dan kumpulkan Exp points. Lalu
                        dapatkan hadiah keren dari Dunia Games.
                    </p>
                    <br />
                    <Button variant="default-dark"
                        onClick={() => {
                            setOpenGetPoints(false);
                        }}
                    >
                        Okay
                    </Button>
                </Modal.Body>
            </Modal>
        </Container>

        <Container>
            <Modal
                show={openConfirmation}
                centered
                contentClassName='treasure-hunt-modal'
                size="lg">
                <Modal.Header>
                    <button type="button" className="btn-close" onClick={() => setOpenConfirmation(false)} aria-label="Close"></button>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <h4>{confirmationMsg}</h4>
                    <br />
                    <Button variant="default-dark-outline"
                        onClick={() => {
                            setOpenConfirmation(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button variant="default-dark mx-3"
                        onClick={() => {
                            userConfirmedHandler();
                        }}
                    >
                        Confirm
                    </Button>
                </Modal.Body>
            </Modal>
        </Container>


        <Container>
            <Modal
                show={openConfirmationVote}
                centered
                contentClassName='treasure-hunt-modal'
                size="md">
                <Modal.Header>
                    <button type="button" className="btn-close" onClick={() => setOpenConfirmationVote(false)} aria-label="Close"></button>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <h4>{confirmationMsg}</h4>
                    <br />
                    <Button variant="default-dark-outline"
                        onClick={() => {
                            setOpenConfirmationVote(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button variant="default-dark mx-3"
                        onClick={() => {
                            userConfirmedHandler();
                        }}
                    >
                        Confirm
                    </Button>
                </Modal.Body>
            </Modal>
        </Container>

        <Container>
            <Modal
                show={showModalAchievement}
                centered
                contentClassName='treasure-hunt-modal'
                size="md">
                <Modal.Header>
                    <button type="button" className="btn-close" onClick={() => setShowModalAchievement(false)} aria-label="Close"></button>
                </Modal.Header>
                <Modal.Body>
                    {iconVictor('btn-thropy', 'icon')}
                    <h4 align="center">ACHIEVEMENT</h4>
                    {achievementDataRef.current && achievementDataRef.current
                        .map((r,i) => {

                            return <Col sm={12} key={i}>
                                <Row>
                                    <Col xs={6}>{r.label}</Col>
                                    <Col xs={6} className="text-right">
                                        {
                                            r.total > 0 ?
                                                <>
                                                    ({r.collected}/{r.total})
                                                </> :
                                                <>
                                                    {r.value ? <FontAwesomeIcon icon="far fa-square-check" /> : <FontAwesomeIcon icon="far fa-square" />}
                                                </>

                                        }
                                    </Col>
                                </Row>
                                <hr />
                            </Col>
                        })
                    }
                </Modal.Body>
            </Modal>

            <Modal
                show={showModalRedeem}
                centered
                contentClassName='treasure-hunt-modal'
                size="md">
                <Modal.Header>
                    <button type="button" className="btn-close" onClick={() => setShowModalRedeem(false)} aria-label="Close"></button>
                </Modal.Header>
                <Modal.Body>
                    {iconVictor('btn-prize', 'icon')}
                    <h4 align="center">EXP Redemption</h4>
                    <br />
                    <p className="text-center">
                        Your EXP will expires on 30th October 2022 at 23:59 midnight.<br />
                        Please redeem your prize on 28-30 October 2022.<br />
                        No extension will be given.
                    </p>
                    <Row className={"cover-vote-list"}>
                        {prizeData.map((r, i) => {
                            const claimed = userData.claimedPrize.find((rb) => rb.prizeCode == r.prizeCode) != null;
                            const notInTime = moment().unix() >= 1666890000 && moment().unix() <= 1667149200 ? false : true;
                            return <Col xs={6} sm={6} className={"divider-vote"} key={i}>
                                <Card>
                                    <Card.Body style={{ background: "#f5f5f5" }} className="text-center">
                                        <Image loading="lazy" src={r.image ? `${process.env.RESOURCE_URL}${r.image}` : `/img/animaverse-logo2.png`} className="w-100 my-2" rounded={true} />
                                        <h5>{r.quantity} {r.label}</h5>
                                    </Card.Body>
                                    <Card.Footer style={{ background: "#f5f5f5" }}>
                                        <p className="text-center" style={{ marginBottom: '5px' }}>Stock {r.quantity - r.claimedQty}</p>
                                        <Button
                                            disabled={claimed || r.quantity == r.claimedQty || notInTime}
                                            onClick={() => confirmHandler('redeem', { prizeCode: r.prizeCode, label: r.label, point: r.cutoff })}
                                            variant="default-dark"
                                            className='full-width'
                                        >{claimed ? 'Claimed' : (r.quantity == r.claimedQty ? 'Stock Habis' : <><FontAwesomeIcon icon="fas fa-coins" /> {r.cutoff} Exp</>)}</Button>
                                    </Card.Footer>
                                </Card>
                            </Col>
                            // return <Col sm={12}>
                            //     <Row>
                            //         <Col xs={6}>{r.label}</Col>
                            //         <Col xs={6} className="text-right">
                            //             <Button
                            //                 disabled={claimed}
                            //                 onClick={() => confirmHandler('redeem', { prizeCode: r.prizeCode, label: r.label, point: r.cutoff })}
                            //                 variant='default'>{claimed ? 'Claimed' : <><FontAwesomeIcon icon="fas fa-coins" /> {r.cutoff} Exp</>}</Button>
                            //         </Col>
                            //     </Row>
                            //     <hr />
                            // </Col>
                        })
                        }
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal
                show={showModalRedeemResponse}
                centered
                contentClassName='treasure-hunt-modal'
                size="md">
                <Modal.Header>
                    <button type="button" className="btn-close" onClick={() => setShowModalRedeemResponse(false)} aria-label="Close"></button>
                </Modal.Header>
                <Modal.Body>
                    {iconVictor('btn-prize', 'icon')}

                    <h4 align="center">{modalRedeemResponseTitle}</h4>
                    <br />
                    <p className="text-center">
                        {modalRedeemResponseContent}
                    </p>
                </Modal.Body>
            </Modal>
            {/* <Modal
                show={showModal}
                className="treasure-event-modal"
                centered
                contentClassName='modal-transparent'
                size="lg">
                <Modal.Header>
                    <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='event-div'>
                        <h5>Event</h5>
                        <hr />
                        <span className='points'>My Exp : {userData.points}</span>
                    </div>
                    <Tab.Container
                        defaultActiveKey='achievement'
                        id="list-tab-setting"
                        className="mb-3 settings-menu-tab"
                        fill
                    >
                        <Nav className="flex-row nav-tabs-setting" variant={"tabs"} fill>
                            <Nav.Item>
                                <Nav.Link eventKey="achievement" href="#">
                                    Achievement
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="redeem" href="#">
                                    Redeem
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="achievement" key={1}>
                                <Row className='my-2'>
                                    <h5 className='my-4'>Daily Login</h5>
                                    <hr />
                                    {
                                        eventData.filter((r) => r.scheduled == 'daily' && r.type == 'daily_login')
                                            .map((r) => {
                                                const canClaim = moment(r.date).format("YYYY-MM-DD").toString() == moment().format("YYYY-MM-DD").toString();
                                                const claimed = userData.claimedEvent.find((rb) => rb.type == r.type && rb.date == r.date) != null;
                                                return <Col sm={3} className={"treasure-hunt daily-login-section"}>

                                                    <Card>
                                                        <Card.Body>
                                                            <Card.Title>
                                                                {moment(r.date, "YYYY-MM-DD").format('DD')}<br />
                                                                {moment(r.date, "YYYY-MM-DD").format('MMMM YYYY')}
                                                            </Card.Title>
                                                            <Card.Text>
                                                                {r.label}
                                                                <br />
                                                                <Button
                                                                    variant="default"
                                                                    className='full-width'
                                                                    disabled={claimed || !canClaim}
                                                                    onClick={() => claimEventHandler(r.type, r.key)}
                                                                >{claimed ? 'Claimed' : <>{moment(r.date).format("YYYY-MM-DD").toString() == moment().format("YYYY-MM-DD").toString() ? <><FontAwesomeIcon icon="fas fa-coins" /> {r.point} Exp</> : (moment(r.date).isBefore(moment()) ? "Expired" : "Coming Soon")} </>}</Button>
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            })
                                    }
                                </Row>
                                <Row>

                                    <h5>Claim Exp From This Event Too</h5>
                                    <hr />
                                    {eventData.filter((r) => r.scheduled == 'daily' && r.type != 'daily_login' && r.type != 'vote' && r.type != 'vote_point')
                                        .map((r) => {

                                            const claimed = userData.claimedEvent.find((rb) => rb.key == r.key) != null;
                                            return <Col sm={12}>
                                                <Row>
                                                    <Col xs={6}>{r.label}</Col>
                                                    <Col xs={6} className="text-right">
                                                        <Button

                                                            disabled={claimed}
                                                            onClick={() => claimEventHandler(r.type, r.key)}
                                                            variant='default'
                                                        >{claimed ? 'Claimed' : <><FontAwesomeIcon icon="fas fa-coins" /> {r.point} Exp</>}</Button>
                                                    </Col>
                                                </Row>
                                                <hr />
                                            </Col>
                                        })
                                    }
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="onetime" key={2}>
                                <h5 className='my-4'>Redeem Your Point , to get prize!</h5>
                                <hr />
                                {eventData.filter((r) => r.scheduled == 'onetime' && r.type != 'vote' && r.type != 'vote_point')
                                    .map((r) => {
                                        const claimed = userData.claimedEvent.find((rb) => rb.key == r.key) != null;

                                        return <Col sm={12}>
                                            <Row>
                                                <Col xs={6}>{r.label}</Col>
                                                <Col xs={6} className="text-right">
                                                    <Button
                                                        disabled={claimed}
                                                        onClick={() => claimEventHandler(r.type, r.key)}
                                                        variant='default'>{claimed ? 'Claimed' : <><FontAwesomeIcon icon="fas fa-coins" /> {r.point} Exp</>}</Button>
                                                </Col>
                                            </Row>
                                            <hr />
                                        </Col>
                                    })
                                }
                            </Tab.Pane>

                            <Tab.Pane eventKey="redeem" key={3}>

                                <Row>
                                    <h5 className='my-4'>Redeem Your Point , to get prize!</h5>
                                    <hr />
                                    {prizeData.map((r) => {
                                        const claimed = userData.claimedPrize.find((rb) => rb.prizeCode == r.prizeCode) != null;

                                        return <Col sm={12}>
                                            <Row>
                                                <Col xs={6}>{r.label}</Col>
                                                <Col xs={6} className="text-right">
                                                    <Button
                                                        disabled={claimed}
                                                        onClick={() => confirmHandler('redeem', { prizeCode: r.prizeCode, label: r.label, point: r.cutoff })}
                                                        variant='default'>{claimed ? 'Claimed' : <><FontAwesomeIcon icon="fas fa-coins" /> {r.cutoff} Exp</>}</Button>
                                                </Col>
                                            </Row>
                                            <hr />
                                        </Col>
                                    })
                                    }
                                </Row>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Modal.Body>
            </Modal> */}
            {/* Schedule */}

            <Modal
                show={showModalEvent}
                centered
                contentClassName='treasure-hunt-modal'
                size="md">
                <Modal.Header>
                    <button type="button" className="btn-close" onClick={() => setShowModalEvent(false)} aria-label="Close"></button>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <h5>{currentSchedule == null ? "Tidak Ada Schedule" : currentSchedule.name2}</h5>
                    {currentSchedule != null && <Button variant="default-dark" className={"full-width"}
                        disabled={userDataRef.current.claimedEvent.find((r) => r.type == "check_in_main_event" && r.id == currentSchedule.id) != null}
                        onClick={() => addPointHandler(currentSchedule.id, "check_in_main_event")}>{userDataRef.current.claimedEvent.find((r) => r.type == "check_in_main_event" && r.id == currentSchedule.id) != null ? "Checked In" : "Check In"}</Button>}
                    <br />
                    <br />
                    <p className="text-center">Check in during each DG Talk Show, Press Conference, DG Award, Coswalk Competition, DGL, Celebrity Match to get EXP</p>

                    <br />
                    <Button variant="default-dark" className={"full-width"} onClick={() => openInNewTab("https://dgcon.xyz/")}>View Schedule</Button>
                </Modal.Body>
            </Modal>
        </Container>
    </>
}
