import { useTrimesh } from "@react-three/cannon";
import { RigidBody } from "@react-three/rapier";
import { useEffect, useRef } from "react";
import { FunctionVariableContext } from "../../context/game-context";

export const TeleportDetection = (props) => {
    
    const onCollide = useRef(FunctionVariableContext(state => state.onTeleportDetection));

    useEffect(() => {
        const onCollideSubs = FunctionVariableContext.subscribe((state) => state.onTeleportDetection, (data) => {
            onCollide.current = data;
        });

        return () => {
            onCollideSubs();
        }
    });

    const onCollideBegin = (collider) => {
        console.log(collider);
        if (onCollide.current) onCollide.current(props.data.data.target, collider);
    }

    return (
        <RigidBody
            colliders={"trimesh"}
            type={"kinematicPosition"}
            sensor
            onIntersectionEnter={(e) => {
                onCollideBegin(e);
            }}
            includeInvisible
        >
            <primitive object={props.data.geometry} visible={false} />
        </RigidBody>
    )
}