import * as THREE from 'three';
import { useFrame, useThree } from "@react-three/fiber";
import UsePersonControls from '../player-controls';
import { CanvasContext } from './canvas-context';
import { PlayerContext } from './player-context';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { Vector3 } from 'three';
import { UpdateParameter } from './player-update-parameter';
import { degToRad } from 'three/src/math/MathUtils';
import { SocketContext } from './socket-context';
import { FunctionVariableContext, GamePlayerContext, SceneContext } from '../../../context/game-context';
import { useAfterPhysicsStep } from '@react-three/rapier';

export const PlayerControl = (props) => {
    const { forward, backward, left, right, jump, run } = UsePersonControls();
    
    const { setDirectionalLightTarget } = useContext(CanvasContext);
    const { networkManager } = useContext(SocketContext);

    const setOnSelectEmoji = FunctionVariableContext(state => state.setOnSelectEmoji);
    const isPlayerDie = useRef(false);
    const setControlToggle = GamePlayerContext(state => state.setControlToggle);
    const setPlayerDie = GamePlayerContext(state => state.setPlayerDie);
    const dynamicGroundColliders = useRef(SceneContext(state => state.dynamicGroundColliders));
    const capturePhoto = useRef(GamePlayerContext(state => state.capturePhoto));

    const { camera } = useThree();
    const forwardDirection = useRef(new THREE.Vector3(0, 0, 0));

    const updatePlayer = useRef();
    const currentPosition = useRef(new Vector3());
    const currentRaycastHitPoint = useRef(new Vector3());
    const currentInteractiveMesh = useRef();
    const currentSeat = useRef("");
    const currentRaycastHitNormal = useRef(new Vector3(0, 0, 0));
    const currentTargetVelocity = useRef(new Vector3());
    const currentMoveDirection = useRef(new Vector3(0, 0, 0));
    const currentSpeeed = useRef(0);
    const lastGroundPosition = useRef(new Vector3());
    let jumpingTime = useRef(0);
    let isJump = useRef(false);
    let isGrounded = useRef(false);
    let isSpecialAnimation = useRef(false);
    let playerSpeed = 10;
    let playerRunSpeed = 2;
    let playerAirSpeed = 6;
    let playerRotationSpeed = 8;
    let maxGroundAngle = 50;
    let jumpHeight = 1.2;

    let upDirection = new THREE.Vector3(0, 1, 0);
    let rightDirection = new THREE.Vector3(0, 0, 0);
    let targetQuaternion = new THREE.Quaternion();
    let moveDirection = new THREE.Vector3(0, 0, 0);
    let maxJumpingTime = 0.05;
    let jumpingSpeed = 6;
    let groundRaycastSafeDistance = 0.2;
    let groundRaycastFar = 1;
    let raycastHitPoint = new THREE.Vector3(0, 0, 0);
    let raycastHitNormal = new THREE.Vector3(0, 0, 0);
    let gravity = useRef(9.8);
    let raycastDir = new THREE.Vector3(0, -1, 0);
    let groundAngle = useRef(0);
    let colliders = useRef([]);
    let groundHeightWhenJump = useRef(0);
    let airAcceleration = useRef(0);
    const isFPS = useRef(GamePlayerContext((state) => state.isFirstPersonCamera));
    let jumpingTargetPosition = useRef(0);
    let jumpKeyDown = useRef(false);

    const controlToggle = useRef(GamePlayerContext((state) => state.controlToggle));

    const setCameraTarget = props.setCameraTarget.current;

    colliders.current = props.colliders;

    props.onPlayerSit.current = (command, parameter, mesh) => {

        isSpecialAnimation.current = true;
        props.refPlayerModel.current.setAnimation("sit");
        props.refPlayerModel.current.rotation.set(mesh.current.rotation.x, mesh.current.rotation.y, mesh.current.rotation.z, "XYZ");
        mesh.current.visible = false;

        if (currentInteractiveMesh.current) currentInteractiveMesh.current.visible = true;
        currentInteractiveMesh.current = mesh.current;
        currentSeat.current = parameter;
    }

    useEffect(() => {
        let gamePlayerSubs = GamePlayerContext.subscribe((state) => state.controlToggle, (data) => {
            controlToggle.current = data;
        });

        let isPlayerDieSubs = GamePlayerContext.subscribe((state) => state.dieTeleportPoint, (data) => {

            isPlayerDie.current = true;
            setPlayerDie(true);
            if (data) {
                props.refPlayerModel.current.setAnimation("die");
                setControlToggle(false);
                setTimeout(() => {
                    props.playerObject.current.setTranslation(data.position);
                    props.playerObject.current.setRotation(data.rotation);
                    setPlayerDie(false);
                    setControlToggle(true);

                    isPlayerDie.current = false;
                }, 2000);
            }

        });

        let dynamicGroundCollidersSubs = SceneContext.subscribe((state) => state.dynamicGroundColliders, (data) => {
            dynamicGroundColliders.current = data;
        });

        let capturePhotoSubs = GamePlayerContext.subscribe((state) => state.capturePhoto, (data) => {
            capturePhoto.current = data;
        });

        return () => {
            gamePlayerSubs();
            isPlayerDieSubs();
            dynamicGroundCollidersSubs();
            capturePhotoSubs();
        }
    }, []);

    useEffect(() => {
        props.onSelectEmoji.current = changeAnimation;
        props.refPlayerModel.current.rotation.x = 0;
        props.refPlayerModel.current.rotation.z = 0;
    }, []);

    useEffect(() => {
        let firstPersonSubs = GamePlayerContext.subscribe((state) => state.isFirstPersonCamera, (data) => {
            isFPS.current = data;

            props.refPlayerModel.current.traverse((child) => {
                if (child.isMesh) {
                    child.visible = !isFPS.current;
                }
            });
        });

        return () => {
            firstPersonSubs();
        };
    }, []);

    const radToDeg = (radians) => {
        let pi = Math.PI;
        return radians * (180 / pi);
    }

    const lerp = (v0, v1, t) => {
        return v0 + t * (v1 - v0);
    }

    const changeAnimation = (id) => {
        props.refPlayerModel.current.onSpecialAnimationFinish = onSpecialAnimationFinish;
        props.refPlayerModel.current.setAnimation(id);
        isSpecialAnimation.current = true;
    }

    useEffect(() => {
        setOnSelectEmoji((animationID) => {
        });
    }, []);

    const onSpecialAnimationFinish = (animationName) => {
        isSpecialAnimation.current = false;
    }   

    const inputHandler = () => {
        camera.getWorldDirection(forwardDirection.current);
        forwardDirection.current.y = 0;
        forwardDirection.current.normalize();
        rightDirection.crossVectors(forwardDirection.current, upDirection);

        if (forward) {
            moveDirection.add(forwardDirection.current);
        }
        else if (backward) {
            forwardDirection.current.multiplyScalar(-1);
            moveDirection.add(forwardDirection.current);
        }

        if (right) {
            moveDirection.add(rightDirection);
        }
        else if (left) {
            rightDirection.multiplyScalar(-1);
            moveDirection.add(rightDirection);
        }

        if (props.analogInput && props.analogInput.current) {

            let analog = props.analogInput.current;
            let angle = Math.atan2(-analog.y, analog.x) - Math.atan2(0, 0);
            angle = 270 - radToDeg(angle);
            moveDirection.add(forwardDirection.current);
            moveDirection.applyAxisAngle(upDirection, degToRad(angle))
        }
    }

    const jumpHandler = () => {

        let position = props.playerObject.current.translation();
        if (jump && controlToggle.current === true && !isJump.current && isGrounded.current && !jumpKeyDown.current) {
            jumpingTime.current = maxJumpingTime;
            isJump.current = true;
            jumpKeyDown.current = true;

            groundHeightWhenJump.current = position.y;

            jumpingTargetPosition.current = position.y + jumpHeight;
            if (updatePlayer.current != undefined) updatePlayer.current();
        } 

        if (isJump.current && position.y > jumpingTargetPosition.current) {
            if (updatePlayer.current != undefined) updatePlayer.current();
            isJump.current = false;
        }

        if(jumpKeyDown.current && !jump){
            jumpKeyDown.current = false;
        }
    }

    const movementHandler = (delta) =>{
        if (((moveDirection.x !== 0 || moveDirection.z !== 0) && controlToggle.current === true) && groundAngle.current < maxGroundAngle) {
            isSpecialAnimation.current = false;
            if (currentInteractiveMesh.current) {
                currentInteractiveMesh.current.visible = true;
                currentInteractiveMesh.current = undefined;
                currentSeat.current = "";
            }

            moveDirection.normalize();
            var targetMatrix = new THREE.Matrix4().lookAt(moveDirection, new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 1, 0));
            targetQuaternion.setFromRotationMatrix(targetMatrix);

            props.refPlayerModel.current.quaternion.slerp(targetQuaternion, delta * playerRotationSpeed);

            let acceleration = (isGrounded.current ? (run ? playerRunSpeed : playerSpeed) : playerAirSpeed);
            currentSpeeed.current = acceleration;
            if (currentSpeeed.current > acceleration) currentSpeeed.current = acceleration;
            currentMoveDirection.current.set(moveDirection.x, moveDirection.y, moveDirection.z);
            currentMoveDirection.current.multiplyScalar(currentSpeeed.current);

            lastGroundPosition.current.set(currentRaycastHitPoint.current[0], currentRaycastHitPoint.current[1], currentRaycastHitPoint.current[2]);

            if (isGrounded.current) {
                let up = new THREE.Vector3(0, 1, 0);
                let normalQuaternion = new THREE.Quaternion().setFromUnitVectors(up, currentRaycastHitNormal.current);
                let matrixQuaternion = new THREE.Matrix4().makeRotationFromQuaternion(normalQuaternion);
                currentMoveDirection.current.applyMatrix4(matrixQuaternion);
            }
        }
        else {
            currentSpeeed.current = 0;
            currentMoveDirection.current.set(0, 0, 0);
        }
    }

    const groundHandler = (delta) => {
        currentPosition.current = props.playerObject.current.translation();
        raycastDir.set(0, -1, 0);
        let raycasterGround = new THREE.Raycaster(props.playerObject.current.translation(), raycastDir, 0.001, groundRaycastFar);

        let groundColliders = [];
        groundColliders = groundColliders.concat(colliders.current);
        groundColliders = groundColliders.concat(dynamicGroundColliders.current);
        let intersectGround = raycasterGround.intersectObjects(groundColliders, true);
        if (intersectGround.length > 0) {

            isGrounded.current = (intersectGround[0].distance < props.colliderRadius * 2 + groundRaycastSafeDistance);

            raycastHitPoint.set(intersectGround[0].point.x, intersectGround[0].point.y, intersectGround[0].point.z);
            currentRaycastHitPoint.current.set(intersectGround[0].point.x, intersectGround[0].point.y, intersectGround[0].point.z);
            raycastHitNormal.set(intersectGround[0].face.normal.x, intersectGround[0].face.normal.y, intersectGround[0].face.normal.z);
            currentRaycastHitNormal.current.set(intersectGround[0].face.normal.x, intersectGround[0].face.normal.y, intersectGround[0].face.normal.z);

            groundAngle.current = 180 - radToDeg(raycastDir.angleTo(raycastHitNormal));

            jumpingTime.current = 0;
        }
        else {
            jumpingTime.current += delta;
            isGrounded.current = false;
        }
    }

    const groundAngleHandler = (delta) => {
        if (groundAngle.current > maxGroundAngle && isGrounded.current) {
            let up = new THREE.Vector3(0, -1, 0);

            let distance = currentRaycastHitNormal.current.dot(up) * -1;
            let normal = currentRaycastHitNormal.current.clone();
            normal.multiplyScalar(distance).add(up).normalize().multiplyScalar(playerAirSpeed);
            currentTargetVelocity.current = normal;

            var direction = normal.clone();
            direction.y = 0;
            var playerMatrix = new THREE.Matrix4().lookAt(direction, new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 1, 0));
            targetQuaternion.setFromRotationMatrix(playerMatrix);
            props.refPlayerModel.current.quaternion.slerp(targetQuaternion, delta * playerRotationSpeed);

        }
        else {

            if (isJump.current) {
                airAcceleration.current = jumpingSpeed;
            }
            else if (isGrounded.current) {
                airAcceleration.current = 0;
            }
            else if (!isGrounded.current) {
                airAcceleration.current = lerp(airAcceleration.current, -gravity.current, 0.1);
            }

            currentTargetVelocity.current.set(currentMoveDirection.current.x, currentMoveDirection.current.y + airAcceleration.current, currentMoveDirection.current.z);
        }

        // console.log(currentTargetVelocity.current);
    }

    const playAnimation = () => {

        let animationToPlay = "";
        if (isPlayerDie.current) {
            animationToPlay = "die";
        }
        else if (((!isGrounded.current || groundAngle.current > maxGroundAngle) && jumpingTime.current > 0.1) || isJump.current) {
            animationToPlay = "jumping";
        }
        else if ((moveDirection.x !== 0 || moveDirection.y !== 0 || moveDirection.z !== 0)) {

            if (run) {
                animationToPlay = "walk";
            }
            else {
                animationToPlay = "run";
            }
        }
        else {
            animationToPlay = "idle";
        }

        if (isSpecialAnimation.current) {
            currentMoveDirection.current.set(0, 0, 0);
            jumpingTime.current = -1;
            // isJumping = false;
            if (updatePlayer.current != undefined) updatePlayer.current();
        }
        else {
            if (props.refPlayerModel && props.refPlayerModel.current && props.refPlayerModel.current.setAnimation && controlToggle.current == true) props.refPlayerModel.current.setAnimation(animationToPlay);
        }
    }

    const playerGroundControl = () => {
        if (isGrounded.current && !isJump.current) {
            let groundPoint = currentRaycastHitPoint.current.y + props.colliderRadius * 2;
            let position = props.playerObject.current.translation();
            position.y = lerp(position.y, groundPoint, 0.5) ;

            props.playerObject.current.setTranslation(position);
        }
    }

    const cameraMovementControl = ()=>{
        
        if (props.refPlayerModel.current && props.refPlayerModel.current.topHead) {
            let position = props.playerObject.current.translation();
            let topHeadPosition = new Vector3();
            props.refPlayerModel.current.getWorldPosition(topHeadPosition);
            topHeadPosition.y += 1.5;
            setCameraTarget(position.x, position.y, position.z, colliders.current, props.refPlayerModel.current, topHeadPosition, props.isFPS);

        }
    }

    useFrame((src, delta) => {

        inputHandler();

        groundHandler(delta);


        movementHandler(delta);

        playAnimation();

        jumpHandler();

        groundAngleHandler(delta);

        props.playerObject.current.setLinvel(currentTargetVelocity.current);

    });

    useAfterPhysicsStep(() => {

        cameraMovementControl();
        
        playerGroundControl();
    });

    return (
        <group>
            <UpdateParameter
                updatePlayer={updatePlayer}
                currentPosition={currentPosition}
                networkManager={networkManager}
                setDirectionalLightTarget={setDirectionalLightTarget}
                forwardDirection={forwardDirection}
                sit={currentSeat}
                {...props}
            />
        </group>
    )
}