import HTMLReactParser from "html-react-parser";
import { useEffect, useRef, useState } from "react"
import { Button, Col, Form, Modal, ProgressBar, Row } from "react-bootstrap";
import { changeLocalAudioTrack, changeLocalVideoTrack, changeRemoteVideoTrack, listRemoteAudioStream } from "../../../agora/agoraService";
import { FunctionVariableContext, GameConfigContext, GamePlayerContext, SceneContext, AudioContext as AudioContextZustand } from "../../context/game-context";
import { getLocalPlayerStream, ResetAudioConnection } from "../../rtc-manager";
// import '../../../css/audio-settings.css'


export const HardwareDevicesSetting = ({ rtcRef }) => {

    const audioSettingInputDefault = localStorage.getItem('audio_setting_input');
    const audioSettingOutputDefault = localStorage.getItem('audio_setting_output');
    const audioSettingOutputVolumeDefault = localStorage.getItem('audio_setting_output_volume');
    const videoSettingInputDefault = localStorage.getItem('video_setting_input');

    const audioSettingBGMVolumeDefault = localStorage.getItem('audio_setting_bgm_volume');

    const videoSourceRef = useRef();
    const audioOutputRef = useRef();
    const audioInputRef = useRef();
    const [audioGainNode, setAudioGainNode] = useState(0);
    const audioAnalyser = useRef();
    const audioSource = useRef();
    const frequencyData = useRef();
    const micPreviewRef = useRef();
    const outputVolume = useRef(audioSettingOutputVolumeDefault >= 0 && audioSettingOutputVolumeDefault != null ? audioSettingOutputVolumeDefault : 1.0);
    const bgmVolume = useRef(audioSettingBGMVolumeDefault >= 0 && audioSettingBGMVolumeDefault != null ? audioSettingBGMVolumeDefault : 1.0);

    const [showModal, setShowModal] = useState(false);
    const [listOptionAudioInput, setListOptionAudioInput] = useState();
    const [listOptionAudioOutput, setListOptionAudioOutput] = useState();
    const [listOptionVideoInput, setListOptionVideoInput] = useState();
    const [selectedAudioInput, setSelectedAudioInput] = useState(audioSettingInputDefault ? audioSettingInputDefault : undefined);
    const [selectedAudioOutput, setSelectedAudioOutput] = useState(audioSettingOutputDefault ? audioSettingOutputDefault : undefined);
    const [selectedVideoInput, setSelectedVideoInput] = useState(videoSettingInputDefault ? videoSettingInputDefault : undefined);

    const setOnOpenAudioAndCameraSetting = FunctionVariableContext(state => state.setOnOpenAudioAndCameraSetting);

    const renderFrame = () => {
        requestAnimationFrame(renderFrame);
        audioAnalyser.current.getByteFrequencyData(frequencyData.current);
    }
    const setVolume = AudioContextZustand(state => state.setVolume);
    const setBGMVolume = AudioContextZustand(state => state.setBGMVolume);

    const handlingAudioUnmute = () => {
        // audioOutputRef.current.muted = false;
        // if (audioOutputRef.current.paused) {
        //     audioOutputRef.current.play();
        // }
    }
    useEffect(() => {
        setOnOpenAudioAndCameraSetting(() => {
            setShowModal(true)
        });

        if (document.stream) {
            document.stream.getTracks().forEach(track => {
                track.stop();
            });
        }
        const getHardwareDevices = async () => {
            // prompt permission

            let devicesAudio = [];
            let devicesVideo = [];
            try {
                devicesAudio = await navigator.mediaDevices.getUserMedia({ audio: true }).then(async () => {
                    return await navigator.mediaDevices.enumerateDevices();
                });
            } catch (err) {
                console.log(err)
            }
            
            try {
                devicesVideo = await navigator.mediaDevices.getUserMedia({ audio: false, video: true }).then(async () => {
                    return await navigator.mediaDevices.enumerateDevices();
                });
            } catch (err) {
                console.log(err)
            }
            console.log(JSON.stringify(devicesAudio), "DEVICES");
            const audioInput = devicesAudio.filter((r) => r.kind == 'audioinput');
            const audioOutput = devicesAudio.filter((r) => r.kind == 'audiooutput');
            const videoInput = devicesVideo.filter((r) => r.kind == 'videoinput');

            setListOptionAudioInput(audioInput.map((r, i) => {
                if (selectedAudioInput == null && i == 0) {
                    console.log(selectedAudioInput, i, selectedAudioInput == null && i == 0, "LIST PERTAMA")
                    setSelectedAudioInput(r.deviceId)
                }
                return (<option value={r.deviceId} key={i}>{r.label}</option>)
            }));
            setListOptionAudioOutput(audioOutput.map((r, i) => {
                if (selectedAudioOutput == null && i == 0) {
                    // console.log(selectedAudioOutput == null && i == 0, r.deviceId, selectedAudioOutput, i, "OUTPUT ARRY")

                    setSelectedAudioOutput(r.deviceId)
                }
                return (<option value={r.deviceId} key={i}>{r.label}</option>)
            }));
            setListOptionVideoInput(videoInput.map((r, i) => {
                if (selectedVideoInput == null && i == 0) {
                    setSelectedVideoInput(r.deviceId)
                }
                return (<option value={r.deviceId} key={i}>{r.label}</option>)
            }));

            handlingOutputVolume(outputVolume.current);
        }

        getHardwareDevices();

        setVolume(outputVolume.current)
        setBGMVolume(bgmVolume.current)

        // console.log(showModal, "SHOW MODAL")
        window.addEventListener("focus", handlingAudioUnmute)

        return () => {
            window.addEventListener("focus", handlingAudioUnmute)
        }
    }, [])



    useEffect(() => {
        const changeMediaSource = async () => {
            if (selectedVideoInput != null && selectedVideoInput.trim() != '') {
                const video = await navigator.mediaDevices.getUserMedia({ audio: selectedAudioInput != null ? selectedAudioInput : true, video: selectedVideoInput })
                console.log(video);
                if (videoSourceRef.current) {
                    const videoTrack = video.getVideoTracks();
                    videoSourceRef.current.srcObject = video;
                    changeLocalVideoTrack(videoTrack[0])
                }
                localStorage.setItem('video_setting_input', selectedVideoInput);
            }


            if (selectedAudioOutput != null && selectedAudioOutput != undefined) {

                if (audioOutputRef.current) {
                    audioOutputRef.current.setSinkId(selectedAudioOutput);

                    // get remote track
                    const listAudioRemote = listRemoteAudioStream();
                    listAudioRemote.map((r) => r.setPlaybackDevice(selectedAudioOutput));
                }

                handlingOutputVolume(outputVolume.current);

                localStorage.setItem('audio_setting_output', selectedAudioOutput);
            }
        }

        changeMediaSource();
    }, [selectedAudioOutput, selectedVideoInput])

    useEffect(() => {

        const changeMediaSource = async () => {
            localStorage.setItem('audio_setting_input', selectedAudioInput);
            let mic = null;
            // if (rtcRef.current && rtcRef.current.DetectRTC.browser.name === 'Firefox') {

            mic = await navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: selectedAudioInput
                }
            })

            if (!mic) {
                mic = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        mandatory: {},
                        optional: [{
                            sourceId: selectedAudioInput
                        }]
                    }
                })
            }

            const audioContext = new AudioContext();
            const analyser = audioContext.createAnalyser();
            const microphone = audioContext.createMediaStreamSource(mic);
            const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

            analyser.smoothingTimeConstant = 0.8;
            analyser.fftSize = 1024;

            microphone.connect(analyser);
            analyser.connect(scriptProcessor);
            scriptProcessor.connect(audioContext.destination);
            scriptProcessor.onaudioprocess = function () {
                const array = new Uint8Array(analyser.frequencyBinCount);
                analyser.getByteFrequencyData(array);
                const arraySum = array.reduce((a, value) => a + value, 0);
                const average = arraySum / array.length;
                // console.log(Math.round(average));
                // audioGainNode.current = Math.round(average);
                setAudioGainNode(Math.round(average));
            };

            const audioTrack = mic.getAudioTracks();
            // changeLocalAudioTrack(audioTrack[0])

            if (rtcRef.current) {
                // rtcRef.current.addStream(mic)
                // ResetAudioConnection(mic, rtcRef.current);
                // rtcRef.current.multiPeersHandler.replaceTrack(mic.getAudioTracks()[0], rtcRef.current.userid, false);
                // rtcRef.current.renegotiate();
            }
            handlingOutputVolume(outputVolume.current);
        }
        if (rtcRef.current != null) {
            changeMediaSource();
        }
    }, [selectedAudioInput])

    const handlingOutputVolume = (vol) => {
        if (audioOutputRef.current) {
            outputVolume.current = vol;
            audioOutputRef.current.volume = outputVolume.current;

            localStorage.setItem('audio_setting_output_volume', outputVolume.current);

            setVolume(outputVolume.current);
            // const listAudioElement = document.getElementsByTagName("audio");
            // for (const r of listAudioElement) {
            //     if (!r.muted) {
            //         r.volume = audioOutputRef.current.volume;
            //     }
            // }

            // const listVideoElement = document.getElementsByTagName("video");
            // for (const r of listVideoElement) {
            //     if (!r.muted) {
            //         r.volume = audioOutputRef.current.volume;
            //     }
            // }
        }
    }

    const handlingBGMVolume = (vol) => {
        bgmVolume.current = vol / 100;
        localStorage.setItem('audio_setting_bgm_volume', bgmVolume.current);
        setBGMVolume(bgmVolume.current);
    }

    return <>
        <Row>
            <audio ref={audioOutputRef} autoPlay={true} muted />
            <Col md={8}>
                <Row>
                    <Col sm={12}>
                        <Form.Group className="mb-2 text-left">
                            <Form.Label>Audio Input</Form.Label>
                            <Form.Select className={"audio-setting-input"}
                                value={selectedAudioInput}
                                onChange={(e) => {
                                    setSelectedAudioInput(e.target.value)
                                }}>
                                {listOptionAudioInput}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Form.Group className="mb-2 text-left">
                            <Form.Label>Audio Output</Form.Label>
                            <Form.Select
                                value={selectedAudioOutput}
                                className={"audio-setting-input"} onChange={(e) => {
                                    // console.log(e.target.value);
                                    setSelectedAudioOutput(e.target.value)
                                }}>
                                {listOptionAudioOutput}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Form.Group className="mb-2 text-left">
                            <Form.Label>Video Input</Form.Label>
                            <Form.Select
                                value={selectedVideoInput}
                                className={"audio-setting-input"} onChange={(e) => {
                                    // console.log(e.target.value);
                                    setSelectedVideoInput(e.target.value)
                                }}>
                                {listOptionVideoInput}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col sm={4} className="text-left">
                        Output Volume
                    </Col>
                    <Col sm={8} className="mb-2">
                        {/* <Form.Range onChange={(e) => {
                            handlingOutputVolume(e.target.value);
                            // console.log(e.target.value);
                            // setSelectedVideoInput(e.target.value)
                        }} className="slider2"/> */}
                        <Form.Range
                            className={"slider2"}
                            value={outputVolume.current * 100} onChange={(e) => {
                                handlingOutputVolume(e.target.value > 0 ? e.target.value / 100 : 0);
                                // console.log(e.target.value);
                                // setSelectedVideoInput(e.target.value)
                            }} />
                    </Col>

                    <Col sm={4} className="text-left">
                        BGM Volume
                    </Col>
                    <Col sm={8} className="mb-2">
                        {/* <Form.Range onChange={(e) => {
                            handlingMicVolume(e.target.value);
                            // console.log(e.target.value);
                            // setSelectedVideoInput(e.target.value)
                        }} className="slider2" /> */}

                        <Form.Range
                            className={"slider2"}
                            value={bgmVolume.current * 100}
                            onChange={(e) => {
                                handlingBGMVolume(e.target.value);
                                // console.log(e.target.value);
                                // setSelectedVideoInput(e.target.value)
                            }} />
                    </Col>
                    <Col md={9}>
                        <div style={{
                            position: "relative",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -20%)"
                        }}>
                            <progress ref={micPreviewRef} value={audioGainNode} max="100" className="slider2"
                                style={{
                                    width: "100%"
                                }}
                            ></progress>
                        </div>
                    </Col>
                    <Col md={3} >
                        <Button className="audio-setting-test-mic mt-2"
                            style={{
                                paddingTop: "10px",
                                width: "100%",
                                height: "30px",
                                paddingTop: "10px",
                                borderRadius: "15px"
                            }}
                        ><div style={{
                            position: "relative",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -55%)",
                            fontSize: "auto"
                        }}>Test Mic</div></Button>
                    </Col>
                </Row>
            </Col>
            <Col md={4} className="mb-3 mt-3">
                <Row>
                    <video ref={videoSourceRef} autoPlay={true} muted className="mb-2 w-100 full-height audio-setting-video-preview" />
                </Row>
            </Col>
        </Row>
    </>
}