
import { useThree } from '@react-three/fiber';
import React, { useEffect, useState } from "react";

export default function VideoControls() {
  const keys = {
    KeyC: 'openCamera',
  }

  const moveFieldByKey = (key) => keys[key]

  const [videoControls, setVideoControls] = useState({
    openMic: false,
  })

  let videoControlsKey = {
    openMic: false,
  }

  // console.log(videoControlsKey,  "KEY PRESSS");
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (moveFieldByKey(e.code)) {
        videoControlsKey[moveFieldByKey(e.code)] = !videoControlsKey[moveFieldByKey(e.code)];
        // console.log(movement, movementKey, "WIK WIK");
        setVideoControls((m) => ({ ...m, [moveFieldByKey(e.code)]: videoControlsKey[moveFieldByKey(e.code)] }))
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
  return videoControls
}