import { useState, useRef, useEffect } from 'react';
import { Button, Offcanvas, Form } from "react-bootstrap";
import { createStreamAgora } from '../../../agora/agoraService';
import { GamePlayerContext, RtcLocalContext } from '../../context/game-context';

export default function ShareScreen({ name, rtcRef, ...props }) {

    let [isPresenter, setPresenter] = useState(GamePlayerContext((state) => state.isPresenter));

    const videoElem = useRef();
    const [stateShare, setStateShare] = useState(false);
    const [currentShare, setCurrentShare] = useState(null);
    var displayMediaOptions = {
        video: {
            cursor: "always"
        },
        audio: false
    };


    const setShareScreen = RtcLocalContext((state) => state.setShareScreen);
    // console.log(networkManager);
    const toggleShare = async (evt) => {
        // start stream
        if (!stateShare) {
            setStateShare(true);
            try {

                const onEndedStream = () => {

                    rtcRef.current.unpublish(data);
                }
                let generateStreamScreen = await createStreamAgora({ onEndedStream })
                // Play the stream.
                generateStreamScreen.play('Screen');
                // Publish the stream.
                generateStreamScreen.isScreen = true;
                generateStreamScreen.onended = generateStreamScreen.onmute = generateStreamScreen.oninactive = function () {
                    onEndedStream();
                }

                rtcRef.current.publish(generateStreamScreen)
                setCurrentShare(generateStreamScreen);

                const stream = new MediaStream();
                stream.addTrack(generateStreamScreen._mediaStreamTrack);
                videoElem.current.srcObject = stream;
                setShareScreen(generateStreamScreen);
                // console.log(videoElem.current.srcObject);
                // if (navigator.mediaDevices.getDisplayMedia) {
                //     navigator.mediaDevices.getDisplayMedia(displayMediaOptions).then(stream => {
                //         console.log(stream);
                //         const generateStreamScreen = createStreamAgora()
                //         console.log(generateStreamScreen, "GENERATE STREAM SCREEN")
                //         setShareScreen(generateStreamScreen);
                //         videoElem.current.srcObject = stream;
                //     }, error => {
                //     });
                // }
                // else if (navigator.getDisplayMedia) {
                //     navigator.getDisplayMedia(displayMediaOptions).then(stream => {
                //         setShareScreen(stream);
                //         videoElem.current.srcObject = stream;
                //     }, error => {
                //     });
                // }
                // else {
                //     alert('getDisplayMedia API is not available in this browser.');
                // }
                // dumpOptionsInfo();
            } catch (err) {
                console.error("Error: " + err);
            }
        } else {
            setStateShare(false);
            rtcRef.current.unpublish(currentShare)
            videoElem.current.srcObject.getTracks().forEach(track => track.stop())
        }
    }

    useEffect(() => {
        const shareScreenListen = RtcLocalContext.subscribe(state => state.screen,
            (data) => {
                if (data) {
                    
                    setStateShare(true);
                } else {

                    setStateShare(false);
                }
            });


        const isPresenterContext = GamePlayerContext.subscribe((state) => state.isPresenter, (data) => {

            if (stateShare && !data) toggleShare();
            setPresenter(data);
        });

        return () => {
            shareScreenListen();
            isPresenterContext();
        }
    }, [])

    return (
        <>
            <button variant="primary" onClick={toggleShare} className="me-2 button-share-screen">
                {stateShare ? "Stop Share" : "Share Screen"}
            </button>
            <div className='video-share-screen'>
                <video
                    ref={videoElem}
                    autoPlay={true}
                    id={'video-share-screen-elem'}
                ></video>
            </div>
        </>
    );
}
