import { AdaptiveDpr } from "@react-three/drei";
import { Canvas } from "@react-three/fiber"
import { useEffect, useRef } from "react";
import { Suspense } from "react";
import { useState } from "react";
import { GameConfigContext, GamePlayerContext, LocalPlayerContext, PlayerSelectionContext, SceneContext, VisitorTrackerContext } from "../../context/game-context";
import { PlayerSelection } from "../../player-selection"
import { CanvasProvider } from "../player-component/context/canvas-provider";
import { PlayerState } from "../player-component/context/player-state"
import { SocketState } from "../player-component/context/socket-state";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Nav from 'react-bootstrap/Nav';
import { Col, Collapse, Container, Fade, Form, Image, Row } from "react-bootstrap";
import { useContext } from "react";
import { PlayerContext } from "../player-component/context/player-context";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateUserProfile } from "../../../store/user.store";
import "../../../css/player-selection.css"
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import "external-svg-loader";
import { useWeb3Service } from "../../../blockhain/provider/web3.provider";

function capitalizeFirstLetter(str) {
    return str[0].toUpperCase() + str.slice(1);
}
export const PlayerSelectionUI = ({ onClose }) => {

    const playerContext = useContext(PlayerContext);
    const avatarList = playerContext.avatarList;
    const initiateLocalPlayerSetting = LocalPlayerContext((state) => state);
    // get first skin and base
    const checkAvailDefaultSkin = avatarList.find(r => r.skin == initiateLocalPlayerSetting.skin)

    // set first setting
    initiateLocalPlayerSetting.skin = checkAvailDefaultSkin != null ? initiateLocalPlayerSetting.skin : avatarList[0].skin;

    if (initiateLocalPlayerSetting.userDefaultBase[initiateLocalPlayerSetting.skin]) {
        initiateLocalPlayerSetting.base = initiateLocalPlayerSetting.userDefaultBase[initiateLocalPlayerSetting.skin];
    }
    // check first if base is in skin
    const checkBaseOnSkin = initiateLocalPlayerSetting.base != null && checkAvailDefaultSkin ? checkAvailDefaultSkin.bases.find((r) => r.basesID == initiateLocalPlayerSetting.base) != null : false;

    initiateLocalPlayerSetting.base = checkBaseOnSkin ? initiateLocalPlayerSetting.base : (checkAvailDefaultSkin ? checkAvailDefaultSkin.bases[0].basesID : avatarList[0].bases[0].basesID);

    //check is any preset
    const checkPreset = initiateLocalPlayerSetting.preset && initiateLocalPlayerSetting.preset[initiateLocalPlayerSetting.base] !== null;

    // if any preset set default to setting
    if (checkPreset) {
        const getPreset = initiateLocalPlayerSetting.preset[initiateLocalPlayerSetting.base];
        if (getPreset) {
            Object.entries(getPreset).map((r, idx) => {

                initiateLocalPlayerSetting[r[0]] = r[1];
            })
        }
    }

    let skinSelected = avatarList.find(r => r.skin == initiateLocalPlayerSetting.skin);
    let findBase = skinSelected.bases.find(r => r.basesID == initiateLocalPlayerSetting.base);

    // get skin color
    const canSkinColorDefault = true;//GamePlayerContext((state) => state.isSkinColorAvailable);
    const canHairColorDefault = true;//GamePlayerContext((state) => state.isHairColorAvailable);
    const setLoading = GameConfigContext((state) => state.setLoading);

    const [playerSettings, setPlayerSettings] = useState(initiateLocalPlayerSetting);
    const [baseSelected, setBaseSelected] = useState(findBase);
    const [gender, setGender] = useState(playerSettings.gender);
    const [showSubPart, setShowSubPart] = useState(canSkinColorDefault || canHairColorDefault ? true : false);
    const [subPart, setSubPart] = useState("body");
    const [secondPart, setSecondPart] = useState(null);


    // console.log(baseSelected, skinSelected, avatarList);
    const [canSelectHair, setCanSelectHair] = useState(false);
    const [canSelectProps, setCanSelectProps] = useState(false);
    const [canSelectHead, setCanSelectHead] = useState(false);
    const [canSelectEyebrow, setCanSelectEyebrow] = useState(false);
    const [canSelectEyes, setCanSelectEyes] = useState(false);
    const [canSelectMouth, setCanSelectMouth] = useState(false);
    const [canSelectFeet, setCanSelectFeet] = useState(false);
    const [canSelectLowerBody, setCanSelectLowerBody] = useState(false);
    const [canSelectUpperBody, setCanSelectUpperBody] = useState(false);
    const [canSelectSkinColor, setCanSelectSkinColor] = useState(true);//useState(canSkinColorDefault);
    const [canSelectHairColor, setCanSelectHairColor] = useState(true);//useState(canHairColorDefault);
    const defaultCanSelectGender = skinSelected.baseSkin && (skinSelected.baseSkin.find((r) => r.gender == 'male' || r.gender == 'female') != null);
    // console.log(defaultCanSelectGender, skinSelected.baseSkin, "DEFAULT GENDER")
    const [canSelectGender, setCanSelectGender] = useState(defaultCanSelectGender)
    const [canSelectSkin, setCanSelectSkin] = useState(skinSelected.additionalSkin != null);

    const playerName = useRef(playerSettings.displayName)
    const dayNightValueChanged = useRef();
    const [hairColor, setHairColor] = useColor("hex", "#121212");
    const [defaultHairColor1, setDefaultHairColor1] = useColor("hex", "#1C1818");
    const [defaultHairColor2, setDefaultHairColor2] = useColor("hex", "#4F352D");
    const [defaultHairColor3, setDefaultHairColor3] = useColor("hex", "#D27424");
    const [defaultHairColor4, setDefaultHairColor4] = useColor("hex", "#FFA62E");
    const [defaultHairColor5, setDefaultHairColor5] = useColor("hex", "#82BF33");
    const [defaultHairColor6, setDefaultHairColor6] = useColor("hex", "#228E2B");
    const [defaultHairColor7, setDefaultHairColor7] = useColor("hex", "#44AED5");
    const [defaultHairColor8, setDefaultHairColor8] = useColor("hex", "#5723B8");
    const [defaultHairColor9, setDefaultHairColor9] = useColor("hex", "#B534A5");
    const [defaultHairColor10, setDefaultHairColor10] = useColor("hex", "#D12926");

    const setItem = PlayerSelectionContext((state) => state.setItem);
    const scene = useRef(SceneContext(state => state.scene));
    const setSelectPlayerCompleted = PlayerSelectionContext((state) => state.setSelectPlayerCompleted);
    const updateLocalPlayerSetting = LocalPlayerContext((state) => state.updateLocalPlayerSetting);
    const setLocalPlayer = GamePlayerContext((state) => state.setLocalPlayer);

    const setVisitorTrackerData = VisitorTrackerContext((state) => state.setVisitorTrackerData);
    const inputNameRef = useRef();

    const refreshSelectionList = () => {
        let skinID = avatarList.findIndex(x => x.skin == playerSettings.skin);
        let baseID = avatarList[skinID].bases.findIndex(x => x.basesID == playerSettings.base);
        if (avatarList[skinID].bases[baseID] && avatarList[skinID].bases[baseID].head) setCanSelectHead(avatarList[skinID].bases[baseID].head.length > 1);
        if (avatarList[skinID].bases[baseID] && avatarList[skinID].bases[baseID].eyebrow) setCanSelectEyebrow(avatarList[skinID].bases[baseID].eyebrow.length > 1);
        if (avatarList[skinID].bases[baseID] && avatarList[skinID].bases[baseID].eyes) setCanSelectEyes(avatarList[skinID].bases[baseID].eyes.length > 1);
        if (avatarList[skinID].bases[baseID] && avatarList[skinID].bases[baseID].mouth) setCanSelectMouth(avatarList[skinID].bases[baseID].mouth.length > 1);
        if (avatarList[skinID].bases[baseID] && avatarList[skinID].bases[baseID].feet) setCanSelectFeet(avatarList[skinID].bases[baseID].feet.length > 1);
        if (avatarList[skinID].bases[baseID] && avatarList[skinID].bases[baseID].props) setCanSelectProps(avatarList[skinID].bases[baseID].props.length > 1);
        if (avatarList[skinID].bases[baseID] && avatarList[skinID].bases[baseID].hair) setCanSelectHair(avatarList[skinID].bases[baseID].hair.length > 1);
        if (avatarList[skinID].bases[baseID] && avatarList[skinID].bases[baseID].lowerBody) setCanSelectLowerBody(avatarList[skinID].bases[baseID].lowerBody.length > 1);
        if (avatarList[skinID].bases[baseID] && avatarList[skinID].bases[baseID].upperBody) setCanSelectUpperBody(avatarList[skinID].bases[baseID].upperBody.length > 1);

        if (avatarList[skinID].bases[baseID] && avatarList[skinID].bases[baseID].hat) setCanSelectFeet(avatarList[skinID].bases[baseID].hat.length > 1);
        if (avatarList[skinID].bases[baseID] && avatarList[skinID].bases[baseID].helmet) setCanSelectProps(avatarList[skinID].bases[baseID].helmet.length > 1);
        if (avatarList[skinID].bases[baseID] && avatarList[skinID].bases[baseID].topHead) setCanSelectHair(avatarList[skinID].bases[baseID].topHead.length > 1);
        if (avatarList[skinID].bases[baseID] && avatarList[skinID].bases[baseID].mask) setCanSelectLowerBody(avatarList[skinID].bases[baseID].mask.length > 1);
        if (avatarList[skinID].bases[baseID] && avatarList[skinID].bases[baseID].tiara) setCanSelectUpperBody(avatarList[skinID].bases[baseID].tiara.length > 1);
        if (avatarList[skinID].bases[baseID] && avatarList[skinID].bases[baseID].earing) setCanSelectUpperBody(avatarList[skinID].bases[baseID].earing.length > 1);
        if (avatarList[skinID].bases[baseID] && avatarList[skinID].bases[baseID].facialHair) setCanSelectUpperBody(avatarList[skinID].bases[baseID].facialHair.length > 1);
    }
    const setSelection = (item, index) => {
        const keySetting = [];
        keySetting['_hair'] = 'hair';
        keySetting['_props'] = 'props';
        keySetting['_feet'] = 'feet';
        keySetting['_lowerBody'] = 'lowerBody';
        keySetting['_upperBody'] = 'upperBody';
        keySetting['_gender'] = 'gender';
        keySetting['_skin'] = 'skin';
        keySetting['_base'] = 'base';
        keySetting['_head'] = 'head';
        keySetting['_eyebrow'] = 'eyebrow';
        keySetting['_eyes'] = 'eyes';
        keySetting['_mouth'] = 'mouth';
        keySetting['_skinColor'] = 'skinColor';
        keySetting['_hairColor'] = 'hairColor';

        keySetting['_hat'] = 'hat';
        keySetting['_helmet'] = 'helmet';
        keySetting['_topHead'] = 'topHead';
        keySetting['_mask'] = 'mask';
        keySetting['_tiara'] = 'tiara';
        keySetting['_earing'] = 'earing';
        keySetting['_facialHair'] = 'facialHair';

        if (item == "_base" || item == "_skin") {
            let keys = Object.keys(keySetting);
            keys.forEach(key => {
                if (key != "_base" && key != "_skin")
                    playerSettings[keySetting[key]] = 0;
            });
        }

        if (item == "_base") {
            let findBase = avatarList.find(r => r.skin == playerSettings.skin);
            findBase = findBase.bases.find(r => r.basesID == index);

            setBaseSelected(findBase);
        }

        playerSettings[keySetting[item]] = index;

        setItem(item, index);

        refreshSelectionList();
        // if (nextSelection.current) nextSelection.current(id);
    }

    useEffect(() => {
        let sceneSubs = SceneContext.subscribe((state) => state.scene, (currentScene) => {
            scene.current = sceneSubs;
        });

        let skinColorSub = GamePlayerContext.subscribe((state) => state.isSkinColorAvailable, (data) => {
            //setCanSelectSkinColor(data)
            if (data && subPart == "body") {
                setShowSubPart(true)
            }
        });

        let hairColorSub = GamePlayerContext.subscribe((state) => state.isHairColorAvailable, (data) => {
            //setCanSelectHairColor(data)
            if (data && subPart == "body") {
                setShowSubPart(true)
            }
        });
        // setLoading(false, null)
        // set preset to default
        const checkPresetExist = localStorage.getItem("local_player_setting");
        if (!checkPresetExist) {
            //     savePreset();
            localStorage.setItem("local_player_setting", JSON.stringify(playerSettings));
        }

        refreshSelectionList();
        return () => {
            sceneSubs();
            skinColorSub();
            hairColorSub();
        }
    }, []);


    const {
        isAuthenticated,
        user,
    } = useWeb3Service();

    useEffect(() => {
        // GET BASEif (checkPreset) {
        if (isAuthenticated) {
            const baseDefault = user.userDefaultBase[playerSettings.skin] ? user.userDefaultBase[playerSettings.skin] : playerSettings.base;

            const getPreset = user.preset[baseDefault] ? user.preset[baseDefault] : null;
            if (getPreset) {
                // set base
                const getBase = Object.entries(getPreset).find((r) => r[0] == 'base');
                setSelection('_base', getBase[1]);
                playerSettings['base'] = getBase[1];
                Object.entries(getPreset).map((r, idx) => {
                    if (r[0] != 'base' && r[0] != 'skin') {
                        playerSettings[r[0]] = r[1];
                        setSelection(`_${r[0]}`, r[1]);
                    }
                    // initiateLocalPlayerSetting[r[0]] = r[1];
                })
            }
            setPlayerSettings({ ...playerSettings, id: user.id, displayName: user.displayName, name: user.name })
            updateLocalPlayerSetting({ ...playerSettings, id: user.id, displayName: user.displayName, name: user.name })
            inputNameRef.current.value = user.displayName;
            playerName.current = user.displayName;
            refreshSelectionList();
        }
    }, [isAuthenticated])

    useEffect(() => {
        const event = new Event('resize');
        window.dispatchEvent(event);
    }, [showSubPart])


    const randomizeAvatar = () => {

        if (canSelectHair) {
            const totalDataLength = baseSelected.hair.length;
            const mathRandom = Math.floor(Math.random() * totalDataLength);
            setSelection('_hair', mathRandom);
        }

        if (canSelectProps) {
            const totalDataLength = baseSelected.props.length;
            const mathRandom = Math.floor(Math.random() * (totalDataLength + 1) - 1);
            setSelection('_props', mathRandom);
        }

        if (canSelectHead) {
            const totalDataLength = baseSelected.head.length;
            const mathRandom = Math.floor(Math.random() * totalDataLength);
            setSelection('_head', mathRandom);
        }

        if (canSelectEyebrow) {
            const totalDataLength = baseSelected.eyebrow.length;
            const mathRandom = Math.floor(Math.random() * totalDataLength);
            setSelection('_eyebrow', mathRandom);

        }

        if (canSelectEyes) {

            const totalDataLength = baseSelected.eyes.length;
            const mathRandom = Math.floor(Math.random() * totalDataLength);
            setSelection('_eyes', mathRandom);
        }

        if (canSelectMouth) {

            const totalDataLength = baseSelected.mouth.length;
            const mathRandom = Math.floor(Math.random() * totalDataLength);
            setSelection('_mouth', mathRandom);
        }

        if (canSelectFeet) {

            const totalDataLength = baseSelected.feet.length;
            const mathRandom = Math.floor(Math.random() * totalDataLength);
            setSelection('_feet', mathRandom);
        }

        if (canSelectLowerBody) {

            const totalDataLength = baseSelected.lowerBody.length;
            const mathRandom = Math.floor(Math.random() * totalDataLength);
            setSelection('_lowerBody', mathRandom);
        }

        if (canSelectUpperBody) {

            const totalDataLength = baseSelected.upperBody.length;
            const mathRandom = Math.floor(Math.random() * totalDataLength);
            setSelection('_upperBody', mathRandom);
        }

        // skin and hair color not implemented first
        if (canSelectSkinColor) {

        }

        if (canSelectHairColor) {

        }
    }

    const getLocalPlayerSetting = GamePlayerContext((state) => state.getLocalPlayerSetting);
    // const savePreset = () => {

    //     const localPlayerSettingUpdated = getLocalPlayerSetting();
    //     // save preset
    //     let getSkinLocale = JSON.parse(localStorage.getItem('skin_locale'));
    //     if (!getSkinLocale) {
    //         getSkinLocale = {};
    //     }
    //     const newSkinArr = [];
    //     newSkinArr[baseSelected.basesID] = {
    //         name: localPlayerSettingUpdated.displayName,
    //         gender: playerSettings.gender,
    //         upperBody: playerSettings.upperBody,
    //         lowerBody: playerSettings.lowerBody,
    //         hair: playerSettings.hair,
    //         props: playerSettings.props,
    //         skin: playerSettings.skin,
    //         base: playerSettings.base,
    //         head: playerSettings.head,
    //         eyebrow: playerSettings.eyebrow,
    //         eyes: playerSettings.eyes,
    //         mouth: playerSettings.mouth,
    //         feet: playerSettings.feet,
    //         skinColor: playerSettings.skinColor,
    //         hairColor: playerSettings.hairColor
    //     };
    //     const skinObject = Object.assign({}, newSkinArr);
    //     localStorage.setItem("skin_locale", JSON.stringify({ ...getSkinLocale, ...skinObject }));
    // }
    const completeAvatar = () => {
        const localPlayerSettingUpdated = getLocalPlayerSetting();

        let data = { ...playerSettings, displayName: playerName.current };
        data.userDefaultBase[playerSettings.skin] = playerSettings.base;

        if (data.preset && playerSettings.base) {
            data.preset[playerSettings.base] = {
                name: playerName.current,
                gender: playerSettings.gender,
                upperBody: playerSettings.upperBody,
                lowerBody: playerSettings.lowerBody,
                hair: playerSettings.hair,
                props: playerSettings.props,
                skin: playerSettings.skin,
                base: playerSettings.base,
                head: playerSettings.head,
                eyebrow: playerSettings.eyebrow,
                eyes: playerSettings.eyes,
                mouth: playerSettings.mouth,
                feet: playerSettings.feet,
                skinColor: playerSettings.skinColor,
                hairColor: playerSettings.hairColor
            };
        }
        updateLocalPlayerSetting({ ...data })
        setPlayerSettings({ ...data });

        const bearer = localStorage.getItem(`BEARER`);

        if (bearer) {
            updateUserProfile({
                name: playerName.current,
                gender: playerSettings.gender,
                upperBody: playerSettings.upperBody,
                lowerBody: playerSettings.lowerBody,
                hair: playerSettings.hair,
                props: playerSettings.props,
                skin: playerSettings.skin,
                base: playerSettings.base,
                head: playerSettings.head,
                eyebrow: playerSettings.eyebrow,
                eyes: playerSettings.eyes,
                mouth: playerSettings.mouth,
                feet: playerSettings.feet,
                skinColor: playerSettings.skinColor,
                hairColor: playerSettings.hairColor,
                userDefaultBase: playerSettings.userDefaultBase
            })
        }
        setVisitorTrackerData("change_avatar", {
            name: playerName.current,
            gender: playerSettings.gender,
            upperBody: playerSettings.upperBody,
            lowerBody: playerSettings.lowerBody,
            hair: playerSettings.hair,
            props: playerSettings.props,
            skin: playerSettings.skin,
            base: playerSettings.base,
            head: playerSettings.head,
            eyebrow: playerSettings.eyebrow,
            eyes: playerSettings.eyes,
            mouth: playerSettings.mouth,
            feet: playerSettings.feet,
            skinColor: playerSettings.skinColor,
            hairColor: playerSettings.hairColor,
            userDefaultBase: playerSettings.userDefaultBase
        })
        // savePreset();
        onClose();
    }

    const canvasRef = useRef();

    const handleResize = () => {
        // console.log("REZ")
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");

            const avatarMenuDom = document.getElementsByClassName("avatar-menu")[0];
            const avatarSection = document.getElementsByClassName("save-avatar-section")[0];
            const avatarPartRow = document.getElementById("container-avatar-part-row");
            const navTabsSetting = document.getElementsByClassName("nav-tabs-setting")[0];
            // console.log(avatarPartRow.clientHeight, "AVATAR PART ROW");
            // canvas.width = window.innerWidth; //change here to the size of the modal, you can calculate it or get it with a reference
            const height = window.innerHeight - navTabsSetting.clientHeight - avatarSection.clientHeight - avatarPartRow.clientHeight;

            if (height < 500) height = 500;
            canvas.height = height - 100;//change here to the size of the modal, you can calculate it or get it with a reference
            // console.log(height, window.innerHeight, navTabsSetting.clientHeight, avatarSection.clientHeight, avatarPartRow.clientHeight);
            canvas.style.height = '100%';
        }
    }


    const baseGenderHandler = (skin, basesID) => {
        setSelection('_skin', skin);
        setSelection('_base', basesID);
    }


    return (
        <>
            <div className='avatar-menu'>
                <div className="">
                    <Container fluid className="flex-grow-1" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Row className={"save-avatar-section"}>
                            <Col sm={12} className="text-left">
                                <h5 className="">Player Name</h5>
                            </Col>
                            <Col sm={4} className="text-left">
                                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                    <Form.Control type="text"
                                        ref={inputNameRef}
                                        placeholder="Player Name"
                                        defaultValue={playerName.current}
                                        onKeyUp={(e) => {
                                            playerName.current = e.target.value
                                        }} />
                                </Form.Group>
                            </Col>
                            <Col sm={12} className="text-left">
                                {/* <ButtonGroup className="mb-2 pull-left">
                            <Button onClick={() => {
                                let gender = scene.current == "ut" ? 2 : 0;

                                setGender(gender);
                                setSelection('_gender', gender)
                            }}><FontAwesomeIcon icon="fas fa-mars" /></Button>
                            <Button onClick={() => {
                                let gender = scene.current == "ut" ? 3 : 1;

                                setGender(gender);
                                setSelection('_gender', gender)
                            }}><FontAwesomeIcon icon="fas fa-venus" /></Button>
                        </ButtonGroup> */}
                                <h5 className="">Choose Your Outfit</h5>
                                {/* <Button variant="primary" className={"mb-3 pull-right"}
                            onClick={e => completeAvatar()}>
                            Save
                        </Button> */}
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Row className={"flex-grow-1 avatar-part-row"}>
                    <Col sm={12} className="text-left flex-grow-1  no-padding" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Tab.Container
                            defaultActiveKey="skincolor"
                            id="list-tab-avatar"
                            className="mb-3"
                            fill
                        >
                            <div id={"container-avatar-part-row"} className="">
                                <Container fluid className="flex-grow-1 " style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Nav className="flex-row body-parts-nav" variant={"tabs"} fill>


                                        <div className="col-3 col-sm-1" onClick={() => {
                                            setSubPart("body");
                                            setShowSubPart(canSelectGender ? true : false);
                                            setSecondPart(null);
                                            handleResize();
                                        }}>
                                            <Nav.Item key={0}>
                                                <Nav.Link eventKey="skincolor" href="#">
                                                    <svg
                                                        data-src="./icon/btn-body.svg"
                                                        fill="currentColor"
                                                    />
                                                    <span>Body</span>

                                                </Nav.Link>
                                            </Nav.Item>
                                        </div>

                                        {
                                            (canSelectHead || canSelectEyebrow || canSelectEyes || canSelectMouth || canSelectHair) &&
                                            <div className={`col-3 col-sm-1 ${subPart == 'head' && 'active'}`} onClick={() => {
                                                setSubPart("head");
                                                setShowSubPart(true);
                                                setSecondPart(null);
                                                handleResize();
                                            }} onTransitionEnd={() => {

                                                handleResize();
                                            }}>
                                                <Nav.Item key={1}>
                                                    <Nav.Link eventKey="" className={subPart == 'head' && 'active'} href="#">
                                                        <svg
                                                            data-src="./icon/btn-wardrobe-head.svg"
                                                            fill="currentColor"
                                                        />
                                                        <span>Head</span>

                                                    </Nav.Link>
                                                </Nav.Item>
                                            </div>
                                        }

                                        {
                                            canSelectUpperBody &&
                                            <div className="col-3 col-sm-1" onClick={() => {
                                                setSubPart(null);
                                                setShowSubPart(false);
                                                setSecondPart(null);
                                                handleResize();
                                            }}>
                                                <Nav.Item key={2}>
                                                    <Nav.Link eventKey="body" href="#">
                                                        <svg
                                                            data-src="./icon/btn-wardrobe-tshirt.svg"
                                                            fill="currentColor"
                                                        />
                                                        <span>Top</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </div>
                                        }
                                        {
                                            canSelectLowerBody &&
                                            <div className="col-3 col-sm-1" onClick={() => {
                                                setSubPart(null);
                                                setShowSubPart(false);
                                                setSecondPart(null);
                                                handleResize();
                                            }}>
                                                <Nav.Item key={3}>
                                                    <Nav.Link eventKey="pant" href="#">
                                                        <svg
                                                            data-src="./icon/btn-wardrobe-pants.svg"
                                                            fill="currentColor"
                                                        />
                                                        <span>Bottom</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </div>
                                        }
                                        {
                                            canSelectFeet &&
                                            <div className="col-3 col-sm-1" onClick={() => {
                                                setSubPart(null);
                                                setShowSubPart(false);
                                                setSecondPart(null);
                                                handleResize();
                                            }}>
                                                <Nav.Item key={4}>
                                                    <Nav.Link eventKey="shoes" href="#">
                                                        <svg
                                                            data-src="./icon/btn-wardrobe-shoes.svg"
                                                            fill="currentColor"
                                                        />
                                                        <span>Shoes</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </div>
                                        }

                                        {canSelectHead &&
                                            <div className="col-3 col-sm-1" onClick={() => {
                                                setSubPart("accessories");
                                                setShowSubPart(true);
                                                setSecondPart(null);
                                                handleResize();
                                            }}>
                                                <Nav.Item key={5}>
                                                    <Nav.Link eventKey="accessories" className={subPart == 'accessories' && 'active'} href="#">
                                                        <svg
                                                            data-src="./icon/btn-accessories.svg"
                                                            fill="currentColor"
                                                        />
                                                        <span>Accessories</span>

                                                    </Nav.Link>
                                                </Nav.Item>
                                            </div>
                                        }

                                        {canSelectSkin &&
                                            <div className="col-3 col-sm-1" onClick={() => {
                                                setSubPart(null);
                                                setShowSubPart(false);
                                                setSecondPart(null);
                                                handleResize();
                                            }}>
                                                <Nav.Item key={0}>
                                                    <Nav.Link eventKey="base" href="#">
                                                        <svg
                                                            data-src="./icon/btn-wardrobe-skin.svg"
                                                            fill="currentColor"
                                                        />
                                                        <span>Skin</span>

                                                    </Nav.Link>
                                                </Nav.Item>
                                            </div>
                                        }
                                    </Nav>
                                    <div style={{ opacity: showSubPart ? 1 : 0 }} className={"sub-part-section"} onTransitionEndCapture={() => {
                                        handleResize();
                                    }}>
                                        <Nav className="flex-row body-parts-nav my-2" variant={"tabs"} fill>
                                            {canSelectGender && subPart == 'body' && <>
                                                {skinSelected.baseSkin && skinSelected.baseSkin.map((r, idx) => {
                                                    const genderCheck = { "male": "btn-male", "female": "btn-female" };
                                                    return <div className="col-3 col-sm-1" key={`gender_${idx}`} onClick={() => setSecondPart(null)}>
                                                        <Nav.Item key={`${r.gender}-nav`}>
                                                            <a
                                                                href="#"
                                                                className={baseSelected.basesID == r.basesID ? 'nav-link active' : 'nav-link '}
                                                                onClick={() => {
                                                                    baseGenderHandler(r.skin, r.basesID)
                                                                }}
                                                            >
                                                                <svg
                                                                    data-src={`./icon/${genderCheck[r.gender]}.svg`}
                                                                    fill="currentColor"
                                                                />
                                                                <span>{capitalizeFirstLetter(r.gender)}</span>

                                                            </a>
                                                        </Nav.Item>
                                                    </div>
                                                })}
                                            </>
                                            }
                                            {/* {canSelectSkinColor && subPart == 'body' &&
                                                <div className="col-3 col-sm-1" onClick={() => setSecondPart(null)}>
                                                    <Nav.Item key={10}>
                                                        <Nav.Link eventKey="skincolor" href="#">
                                                            <svg
                                                                data-src="./icon/btn-wardrobe-skincolor.svg"
                                                                fill="currentColor"
                                                            />
                                                            <span>Skin Color</span>

                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </div>
                                            } */}
                                            {
                                                canSelectHead && subPart == 'accessories' &&
                                                <div className="col-3 col-sm-1" onClick={() => setSecondPart(null)}>
                                                    <Nav.Item key={6}>
                                                        <Nav.Link eventKey="head" href="#">
                                                            <svg
                                                                data-src="./icon/btn-mask.svg"
                                                                fill="currentColor"
                                                            />
                                                            <span>Mask</span>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </div>
                                            }
                                            {
                                                canSelectEyebrow && subPart == 'head' &&
                                                <div className="col-3 col-sm-1" onClick={() => setSecondPart(null)}>
                                                    <Nav.Item key={7}>
                                                        <Nav.Link eventKey="eyebrow" href="#">
                                                            <svg
                                                                data-src="./icon/btn-wardrobe-eyebrow.svg"
                                                                fill="currentColor"
                                                            />
                                                            <span>Eyebrow</span>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </div>
                                            }
                                            {
                                                canSelectEyes && subPart == 'head' &&
                                                <div className="col-3 col-sm-1" onClick={() => setSecondPart(null)}>
                                                    <Nav.Item key={8}>
                                                        <Nav.Link eventKey="eyes" href="#">
                                                            <svg
                                                                data-src="./icon/btn-wardrobe-eyes.svg"
                                                                fill="currentColor"
                                                            />
                                                            <span>Eyes</span>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </div>
                                            }
                                            {
                                                canSelectMouth && subPart == 'head' &&
                                                <div className="col-3 col-sm-1" onClick={() => setSecondPart(null)}>
                                                    <Nav.Item key={9}>
                                                        <Nav.Link eventKey="mouth" href="#">
                                                            <svg
                                                                data-src="./icon/btn-wardrobe-mouth.svg"
                                                                fill="currentColor"
                                                            />
                                                            <span>Mouth</span>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </div>
                                            }
                                            {
                                                canSelectHair && subPart == 'head' &&
                                                <div className="col-3 col-sm-1" onClick={() => {
                                                    setSecondPart('hair');
                                                    handleResize();
                                                }}>
                                                    <Nav.Item key={1}>
                                                        <Nav.Link eventKey="hair" href="#">
                                                            <svg
                                                                data-src="./icon/btn-hair.svg"
                                                                fill="currentColor"
                                                            />
                                                            <span>Hair</span>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </div>
                                            }
                                            {
                                                canSelectProps && subPart == 'accessories' &&
                                                <div className="col-3 col-sm-1" onClick={() => setSecondPart(null)}>
                                                    <Nav.Item key={2}>
                                                        <Nav.Link eventKey="props" href="#">
                                                            <svg
                                                                data-src="./icon/btn-wardrobe-props.svg"
                                                                fill="currentColor"
                                                            />
                                                            <span>Props</span>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </div>
                                            }
                                        </Nav>
                                    </div>

                                    <div style={{ opacity: secondPart == 'hair' ? 1 : 0 }} className={"sub-part-section third-part-section"}>

                                        <Nav className="flex-row body-parts-nav" variant={"tabs"} fill>
                                            {canSelectHairColor && subPart == 'head' && <div className="hair-color-scroll">
                                                <Button className="m-1" style={{ color: "#000", backgroundColor: "#000", width: "50px", height: "100%" }} onClick={e => setSelection('_hairColor', 'null')}></Button>
                                                <Button className="m-1" style={{ backgroundColor: "#1C1818", width: "50px", height: "100%" }} onClick={e => { setHairColor(defaultHairColor1); setSelection('_hairColor', '#1C1818') }} />
                                                <Button className="m-1" style={{ backgroundColor: "#4F352D", width: "50px", height: "100%" }} onClick={e => { setHairColor(defaultHairColor2); setSelection('_hairColor', '#4F352D') }} />
                                                <Button className="m-1" style={{ backgroundColor: "#D27424", width: "50px", height: "100%" }} onClick={e => { setHairColor(defaultHairColor3); setSelection('_hairColor', '#D27424') }} />
                                                <Button className="m-1" style={{ backgroundColor: "#FFA62E", width: "50px", height: "100%" }} onClick={e => { setHairColor(defaultHairColor4); setSelection('_hairColor', '#FFA62E') }} />
                                                <Button className="m-1" style={{ backgroundColor: "#82BF33", width: "50px", height: "100%" }} onClick={e => { setHairColor(defaultHairColor5); setSelection('_hairColor', '#82BF33') }} />
                                                <Button className="m-1" style={{ backgroundColor: "#228E2B", width: "50px", height: "100%" }} onClick={e => { setHairColor(defaultHairColor6); setSelection('_hairColor', '#228E2B') }} />
                                                <Button className="m-1" style={{ backgroundColor: "#44AED5", width: "50px", height: "100%" }} onClick={e => { setHairColor(defaultHairColor7); setSelection('_hairColor', '#44AED5') }} />
                                                <Button className="m-1" style={{ backgroundColor: "#5723B8", width: "50px", height: "100%" }} onClick={e => { setHairColor(defaultHairColor8); setSelection('_hairColor', '#5723B8') }} />
                                                <Button className="m-1" style={{ backgroundColor: "#B534A5", width: "50px", height: "100%" }} onClick={e => { setHairColor(defaultHairColor9); setSelection('_hairColor', '#B534A5') }} />
                                                <Button className="m-1" style={{ backgroundColor: "#D12926", width: "50px", height: "100%" }} onClick={e => { setHairColor(defaultHairColor10); setSelection('_hairColor', '#D12926') }} />
                                            </div>
                                            }
                                        </Nav>
                                    </div>
                                </Container>
                            </div>

                            <div id="sub-part-nav" className="flex-grow-1" style={{ display: 'flex', flexDirection: 'column' }}>
                                <Container fluid className="flex-grow-1" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Row className={"flex-grow-1 py-3"}>
                                        <Col xs={6} className={"flex-grow-1 no-padding"}>
                                            <Tab.Content >
                                                <Tab.Pane eventKey="base" title="Base" key={0}>
                                                    <Row className={"pb-4"}>
                                                        {skinSelected.additionalSkin && skinSelected.additionalSkin.map((r, idx) => {
                                                            if (r.basesID != "animaverse-telkomsel-1" && r.basesID != "animaverse-telkomsel-2")
                                                                return (
                                                                    <Col key={`col-additional-skin-${r.basesID}`} xs={6} sm={3} className={"cover-button-avatar-selection"}>
                                                                        <Button size="lg" key={`additional-skin-${r.basesID}`} className={"w-100 button-thumbnail"}
                                                                            onClick={e => {
                                                                                setSelection('_skin', r.skin);
                                                                                setSelection('_base', r.basesID);
                                                                                console.log(r);
                                                                            }}>
                                                                            {r.label}
                                                                        </Button>
                                                                    </Col>)
                                                        })}
                                                    </Row>
                                                </Tab.Pane >


                                                <Tab.Pane eventKey="skincolor" title="SkinColor" key={11}>
                                                    {canSelectSkinColor &&
                                                        <div className="container py-4 text-left">
                                                            {/* <div className="row h5">Skin Color</div> */}
                                                            <div className="row">
                                                                <Button className="m-1" style={{ backgroundColor: "#fbc098", width: "50px", height: "50px" }} onClick={e => setSelection('_skinColor', '#fbc098')} />
                                                                <Button className="m-1" style={{ backgroundColor: "#e4a981", width: "50px", height: "50px" }} onClick={e => setSelection('_skinColor', '#e4a981')} />
                                                                <Button className="m-1" style={{ backgroundColor: "#ab7d5f", width: "50px", height: "50px" }} onClick={e => setSelection('_skinColor', '#ab7d5f')} />
                                                                <Button className="m-1" style={{ backgroundColor: "#795741", width: "50px", height: "50px" }} onClick={e => setSelection('_skinColor', '#795741')} />
                                                            </div>
                                                        </div>
                                                    }
                                                </Tab.Pane >

                                                <Tab.Pane eventKey="haircolor" title="HairColor" key={12}>
                                                    <div className="container py-4 text-left">
                                                        <div className="row h5">Hair Color</div>
                                                        <div className="row m-1">Preset</div>
                                                        <div className="row">
                                                            <Button className="m-1" style={{ color: "#000", backgroundColor: "#FFFFFF", width: "100px", height: "50px" }} onClick={e => setSelection('_hairColor', 'null')}>Default</Button>
                                                            <Button className="m-1" style={{ backgroundColor: "#151515", width: "50px", height: "50px" }} onClick={e => { setHairColor(defaultHairColor1); setSelection('_hairColor', '#151515') }} />
                                                            <Button className="m-1" style={{ backgroundColor: "#211904", width: "50px", height: "50px" }} onClick={e => { setHairColor(defaultHairColor2); setSelection('_hairColor', '#211904') }} />
                                                            <Button className="m-1" style={{ backgroundColor: "#7585ff", width: "50px", height: "50px" }} onClick={e => { setHairColor(defaultHairColor3); setSelection('_hairColor', '#7585ff') }} />
                                                            <Button className="m-1" style={{ backgroundColor: "#e8ff99", width: "50px", height: "50px" }} onClick={e => { setHairColor(defaultHairColor4); setSelection('_hairColor', '#e8ff99') }} />
                                                        </div>
                                                        <div className="row m-1">Color Pallete</div>
                                                        <div className="row">
                                                            <ColorPicker className="no-padding" width={250} height={180} color={hairColor} onChange={setHairColor} onChangeComplete={(color) => { setSelection('_hairColor', color.hex); }} hideHSV hideHEX hideRGB />
                                                        </div>
                                                    </div>
                                                </Tab.Pane >

                                                <Tab.Pane eventKey="head" title="Head" key={7}>
                                                    <Row className={"pb-4"}>
                                                        {baseSelected?.head.map((r, idx) =>
                                                            <Col key={idx} xs={6} sm={3} className={"cover-button-avatar-selection"}>
                                                                <Button size="lg" key={`head-${idx}`} className={"w-100 button-thumbnail"}
                                                                    onClick={e => setSelection('_head', idx)}>

                                                                    {r.thumb.length > 0 ? <Image src={`${process.env.RESOURCE_URL}/avatars/${playerSettings.skin}/${baseSelected.basesID}/Head/Thumbnail/${r.thumb}`} className={"full-width"} />
                                                                        : <FontAwesomeIcon icon={`fas fa-ban`} />}
                                                                </Button>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Tab.Pane >

                                                <Tab.Pane eventKey="eyebrow" title="Eyebrow" key={8}>
                                                    <Row className={"pb-4"}>
                                                        {baseSelected?.eyebrow.map((r, idx) =>
                                                            <Col key={idx} xs={6} sm={3} className={"cover-button-avatar-selection"}>
                                                                <Button size="lg" key={`eyebrow-${idx}`} className={"w-100 button-thumbnail"}
                                                                    onClick={e => setSelection('_eyebrow', idx)}>

                                                                    {r.thumb.length > 0 ? <Image src={`${process.env.RESOURCE_URL}/avatars/${playerSettings.skin}/${baseSelected.basesID}/Eyebrow/Thumbnail/${r.thumb}`} className={"full-width"} />
                                                                        : <FontAwesomeIcon icon={`fas fa-ban`} />}
                                                                </Button>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Tab.Pane >


                                                <Tab.Pane eventKey="eyes" title="Eyes" key={9}>
                                                    <Row className={"pb-4"}>
                                                        {baseSelected?.eyes.map((r, idx) =>
                                                            <Col key={idx} xs={6} sm={3} className={"cover-button-avatar-selection"}>
                                                                <Button size="lg" key={`eyes-${idx}`} className={"w-100 button-thumbnail"}
                                                                    onClick={e => setSelection('_eyes', idx)}>

                                                                    {r.thumb.length > 0 ? <Image src={`${process.env.RESOURCE_URL}/avatars/${playerSettings.skin}/${baseSelected.basesID}/Eyes/Thumbnail/${r.thumb}`} className={"full-width"} />
                                                                        : <FontAwesomeIcon icon={`fas fa-ban`} />}
                                                                </Button>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Tab.Pane >

                                                <Tab.Pane eventKey="mouth" title="Mouth" key={10}>
                                                    <Row className={"pb-4"}>
                                                        {baseSelected?.mouth.map((r, idx) =>
                                                            <Col key={idx} xs={6} sm={3} className={"cover-button-avatar-selection"}>
                                                                <Button size="lg" key={`mouth-${idx}`} className={"w-100 button-thumbnail"}
                                                                    onClick={e => setSelection('_mouth', idx)}>

                                                                    {r.thumb.length > 0 ? <Image src={`${process.env.RESOURCE_URL}/avatars/${playerSettings.skin}/${baseSelected.basesID}/Mouth/Thumbnail/${r.thumb}`} className={"full-width"} />
                                                                        : <FontAwesomeIcon icon={`fas fa-ban`} />}
                                                                </Button>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Tab.Pane >

                                                <Tab.Pane eventKey="hair" title="Hair" key={1}>
                                                    <Row className={"pb-4"}>
                                                        {baseSelected?.hair.map((r, idx) =>
                                                            <Col key={idx} xs={6} sm={3} className={"cover-button-avatar-selection"}>
                                                                <Button size="lg" key={`hair-${idx}`} className={"w-100 button-thumbnail"}
                                                                    onClick={e => setSelection('_hair', idx)}>

                                                                    {r.thumb.length > 0 ? <Image src={`${process.env.RESOURCE_URL}/avatars/${playerSettings.skin}/${baseSelected.basesID}/Hair/Thumbnail/${r.thumb}`} className={"full-width"} />
                                                                        : <FontAwesomeIcon icon={`fas fa-ban`} />}
                                                                </Button>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Tab.Pane >
                                                <Tab.Pane eventKey="props" title="Props" key={2}>
                                                    <Row className={"pb-4"}>
                                                        <Col sm={3} xs={6} className={"cover-button-avatar-selection"}>
                                                            <Button size="lg" key={`props-${-1}`} className={"w-100 button-thumbnail"}
                                                                onClick={e => setSelection('_props', -1)}>
                                                                None
                                                            </Button>
                                                        </Col>
                                                        {baseSelected?.props.map((r, idx) =>
                                                            <Col key={idx} xs={6} sm={3} className={"cover-button-avatar-selection"}>
                                                                <Button size="lg" key={`props-${idx}`} className={"w-100"}
                                                                    onClick={e => setSelection('_props', idx)}>

                                                                    {r.thumb.length > 0 ? <Image src={`${process.env.RESOURCE_URL}/avatars/${playerSettings.skin}/${baseSelected.basesID}/Props/Thumbnail/${r.thumb}`} className={"full-width"} />
                                                                        : <FontAwesomeIcon icon={`fas fa-ban`} />}
                                                                </Button>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Tab.Pane >
                                                <Tab.Pane eventKey="body" title="Body" key={3}>
                                                    <Row className={"pb-4"}>
                                                        {baseSelected?.upperBody.map((r, idx) =>
                                                            <Col key={idx} xs={6} sm={3} className={"cover-button-avatar-selection"}>
                                                                <Button size="lg" key={`body-${idx}`} className={"w-100 button-thumbnail"}
                                                                    onClick={e => setSelection('_upperBody', idx)}>

                                                                    {r.thumb.length > 0 ? <Image src={`${process.env.RESOURCE_URL}/avatars/${playerSettings.skin}/${baseSelected.basesID}/UpperBody/Thumbnail/${r.thumb}`} className={"full-width"} />
                                                                        : <FontAwesomeIcon icon={`fas fa-ban`} />}
                                                                </Button>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Tab.Pane >
                                                <Tab.Pane eventKey="pant" title="Pant" key={4}>
                                                    <Row className={"pb-4"}>
                                                        {baseSelected?.lowerBody.map((r, idx) =>
                                                            <Col key={idx} xs={6} sm={3} className={"cover-button-avatar-selection"}>
                                                                <Button size="lg" key={`pant-${idx}`} className={"w-100 button-thumbnail"}
                                                                    onClick={e => setSelection('_lowerBody', idx)}>
                                                                    {r.thumb.length > 0 ? <Image src={`${process.env.RESOURCE_URL}/avatars/${playerSettings.skin}/${baseSelected.basesID}/LowerBody/Thumbnail/${r.thumb}`} className={"full-width"} />
                                                                        : <FontAwesomeIcon icon={`fas fa-ban`} />}
                                                                </Button>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Tab.Pane >
                                                <Tab.Pane eventKey="shoes" title="Shoes" key={5}>
                                                    <Row className={"pb-4"}>
                                                        {baseSelected?.feet.map((r, idx) =>
                                                            <Col key={idx} xs={6} sm={3} className={"cover-button-avatar-selection"}>
                                                                <Button size="lg" key={`shoes-${idx}`} className={"w-100 button-thumbnail"}
                                                                    onClick={e => setSelection('_feet', idx)}>

                                                                    {r.thumb.length > 0 ? <Image src={`${process.env.RESOURCE_URL}/avatars/${playerSettings.skin}/${baseSelected.basesID}/Feet/Thumbnail/${r.thumb}`} className={"full-width"} />
                                                                        : <FontAwesomeIcon icon={`fas fa-ban`} />}
                                                                </Button>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Tab.Pane >
                                            </Tab.Content>
                                        </Col>
                                        <Col xs={6} className="cover-preview-avatar">
                                            <Canvas
                                                flat={true}
                                                ref={canvasRef}
                                                frameloop="always"
                                                linear={true}
                                                dpr={[1, 2]}
                                                shadows={true}
                                                // shadowMap
                                                performance={{ min: 0.4 }}
                                                gl={{
                                                    alpha: true,
                                                    autoClearColor: 0x000000,
                                                }}
                                                onCreated={
                                                    state => {
                                                        state.gl.setClearColor(0x000000, 0);
                                                    }
                                                }
                                            >
                                                <ambientLight intensity={0.5} />
                                                <hemisphereLight color={"#292928"} groundColor={"080820"} intensity={0.5} />
                                                <directionalLight color={0xFFFFFF} intensity={1} />
                                                <PlayerContext.Provider value={playerContext}>
                                                    <PlayerSelection playerSettings={playerSettings} />
                                                </PlayerContext.Provider>

                                                <AdaptiveDpr pixelated />
                                            </Canvas>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Tab.Container>
                    </Col>


                    <Col sm={12} className="text-left " style={{ height: '50px' }}>
                        <Container className="flex-grow-1" style={{ display: 'flex', flexDirection: 'column' }}>
                            <Row>
                                <Col sm={6}></Col>
                                <Col xs={12} sm={6} className={"no-padding"}>
                                    <Row>
                                        <Col xs={6} style={{ paddingLeft: 0 }}>
                                            <Button variant="default-dark-outline" className={"w-100"}
                                                onClick={() => randomizeAvatar()}
                                            >
                                                Randomize
                                            </Button>
                                        </Col>
                                        <Col xs={6} style={{ paddingRight: 0 }}>
                                            <Button variant="default-dark" className={"w-100"}
                                                onClick={e => completeAvatar()}>
                                                Done
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </div >
        </>

    )
}