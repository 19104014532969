import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { GameConfigContext, GamePlayerContext } from "../../../context/game-context";
import { NetworkManager } from "../../../utils/network/network-manager";
import { UtilTools } from "../../../utils/UtilTool";
import { BlockAccountLogged } from "../../html-component/block-account-logged";
import { SceneNotAvailable } from "../../html-component/scene-not-available";
import { SocketContext } from "./socket-context";

export const SocketState = ({ children, sceneID, setRequiredUserData }) => {
    const [networkManager, setNetworkManager] = useState(null);
    const [sceneConfig, setSceneconfig] = useState(null);
    const [subscene, setSubscene] = useState(null);
    const [sceneAvailable, setSceneAvailable] = useState(true);
    const [openedOnOtherTabs, setOpenedOnOtherTabs] = useState(false);
    const sceneConfigRef = useRef(null);
    const currentListPlayer = useRef([]);
    //UNTUK DIHAPUS
    // const math = useRef(new UtilTools());
    //UNTUK DIHAPUS

    const setLoadingScene = GameConfigContext((state) => state.setLoadingScene)
    const setLoadingTextServer = GameConfigContext((state) => state.setLoadingTextServer)
    const setLoadingColorClass = GameConfigContext((state) => state.setLoadingColorClass)
    const setHideAnimoji = GameConfigContext((state) => state.setHideAnimoji)
    const setLoading = GameConfigContext((state) => state.setLoading);
    const setMinimapLogo = GameConfigContext((state) => state.setMinimapLogo);
    const setChatServer = GameConfigContext((state) => state.setChatServer);
    const setAppId = GameConfigContext((state) => state.setAppId);
    const setListPlayer = GamePlayerContext((state) => state.setListPlayer)
    const setPositionRemotePlayer = GamePlayerContext((state) => state.setPositionRemotePlayer);
    const setUpdateParameterRemotePlayer = GamePlayerContext((state) => state.setUpdateParameterRemotePlayer)
    // fetch a user from a fake backend API
    useEffect(() => {

        if (networkManager == null) {

            //UNTUK DIHAPUS
            // initializeNetworkManager.on("OnConnect", ()=>{
            //     initializeNetworkManager.SetMe({
            //         id: math.current.makeid(16),
            //         name: math.current.makeid(6),
            //         displayName: math.current.makeid(6),
            //         gender: math.current.randomInt(2),
            //         body: math.current.randomInt(4),
            //         hair: math.current.randomInt(4),
            //         hand: math.current.randomInt(4),
            //         pants: math.current.randomInt(4),
            //         props: math.current.randomInt(7)
            //     })
            //     initializeNetworkManager.JoinRoom(0);
            // });
            //UNTUK DIHAPUS

            const getSceneData = async () => {

                let host = "https://avmmo1.virtu.ninja";

                if (!sceneConfig) {
                    try {
                        //TEST PURPOSE
                        const getScene = await axios.get(`${process.env.API_URL}/scene/get_scene/${sceneID.current}`, {
                            crossDomain: true
                        });

                        // let getScene = {
                        //     data:{
                        //         data:{
                        //             scene:"development",
                        //             allowedAvatars:[],
                        //             subscenes:[],
                        //             autoIngame:true,
                        //             loading:{
                        //                 text:null,
                        //                 background:"/img/loading/dg/bg.jpg",
                        //                 logo:"/img/loading/dg/dg.png",
                        //                 color:null
                        //             },
                        //             requiredUserData:false,
                        //             minimapLogo:"/img/logo/dg/dg-con-small.png",
                        //             hideAnimoji:[],
                        //             host:"https://avmmo2.virtu.ninja",
                        //             chatServer: "https://dm.virtu.ninja",
                        //             appId : "development"
                        //         }
                        //     }
                        // }

                        setSubscene(getScene.data.data.subscenes);
                        setSceneconfig(getScene.data.data);
                        sceneConfigRef.current = getScene.data.data;
                        const loading = getScene.data.data.loading;
                        setLoadingScene(loading.text, loading.logo, loading.background)
                        setLoadingTextServer(loading.text);
                        setLoadingTextServer(loading.text);
                        setMinimapLogo(getScene.data.data?.minimapLogo)
                        setLoadingColorClass(loading.colorClass);
                        setRequiredUserData(getScene.data.data.requiredUserData)
                        setHideAnimoji(getScene.data.data.hideAnimoji)
                        // setChatServer('http://localhost:4000')

                        // if (getScene.data.data.chatServer) setChatServer(getScene.data.data.chatServer);
                        // if (getScene.data.data.appId) setAppId(getScene.data.data.appId);
                        setChatServer(process.env.CHAT_SERVER_URL)
                        setAppId(process.env.CHAT_SERVER_APP_ID)
                        if (getScene.data.data.host) host = getScene.data.data.host;

                    } catch (err) {
                        setSubscene([])
                        setSceneconfig({ autoIngame: false })
                        setSceneAvailable(false);
                        // setLoading(false, null)
                        // throw new Error("Scene Unavailable");
                    }
                }


                // console.log("HOST", host);
                // host = "https://avmmo3.virtu.ninja";
                // host = "http://localhost:3050";

                console.log(host);
                const initializeNetworkManager = new NetworkManager(host);
                initializeNetworkManager.connect();
                initializeNetworkManager.on("OnConnect", () => {
                    // console.log("CONNECTED SOCKET STATE", initializeNetworkManager)
                    setNetworkManager(initializeNetworkManager);
                });

                initializeNetworkManager.on("OnUpdateParameterObject", (dataRaw) => {
                    // (dataRaw);
                    let listPlayerRaw = Object.entries(dataRaw);
                    const listPlayer = listPlayerRaw.map((r) => r[1]).filter((data) => {
                        return data.g != undefined
                    }).map((r) => ({
                        id: r.u.i,
                        name: r.u.n,
                        displayName: r.u.a,
                        gender: r.u.g,
                        upperBody: r.u.b,
                        hair: r.u.h,
                        shoes: r.u.d,
                        lowerBody: r.u.l,
                        props: r.u.sh,
                        uid: r.u.uid ? r.u.uid : null,
                        skin: "animaverse"
                    }));

                    const listPlayerSorted = listPlayer.sort(function (a, b) {
                        return a.displayName ? a.displayName.localeCompare(b.displayName) : false;
                    });
                    if (JSON.stringify(currentListPlayer.current) != JSON.stringify(listPlayerSorted)) {
                       
                        // console.log(listPlayerSorted);
                        setListPlayer(listPlayerSorted)
                        currentListPlayer.current = listPlayerSorted;
                    }
                    // setPositionRemotePlayer(dataRaw)
                    setUpdateParameterRemotePlayer(dataRaw)
                });

                initializeNetworkManager.on("OnJoinRoomError", (jsonData) => {
                    let data = JSON.parse(jsonData);
                    console.log(data, "ON JOIN ROOM ERROR")

                    setOpenedOnOtherTabs(true);
                    setLoading(false);
                });
            }
            getSceneData();

        }


        return () => {
            if (networkManager != null) {
                networkManager.off("OnConnect");
                networkManager.off("OnUpdateParameterObject");
                networkManager.off("OnJoinRoomError");
            }

            setNetworkManager(null)
            setSceneconfig(null);
            setSubscene(null);
            setSceneAvailable(true);
            setOpenedOnOtherTabs(false);

        }

    }, []);

    // console.log("NETWORK MANAGER STATE CALLED", networkManager)
    return sceneAvailable ? (openedOnOtherTabs ? <BlockAccountLogged /> : <SocketContext.Provider
        value={{ networkManager, sceneConfig, subscene }}
    >
        {networkManager && sceneConfig && children}
    </SocketContext.Provider>) : <>
        <SceneNotAvailable />
    </>

};