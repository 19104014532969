import { useTrimesh } from "@react-three/cannon";
import { useAnimations } from "@react-three/drei"
import { RigidBody } from "@react-three/rapier";
import { useEffect, useState } from "react";
import { LoopRepeat } from "three";
import { GamePlayerContext, TreasureHuntContext } from "../../context/game-context";

export const StaticCollectableItems = ({ object, clip, id, total, sceneName, detectionGeometry, geometry }) => {

    const [collectedItem, setCollectedItem] = useState(GamePlayerContext((state) => state.collectedStaticItem));
    const addCollectedStaticItemList = GamePlayerContext((state) => state.addCollectedStaticItemList);
    const addPoint = TreasureHuntContext((state) => state.addPoint);
    const getCollectedStaticItemList = GamePlayerContext((state) => state.getCollectedStaticItemList);

    let [visible, setVisible] = useState(true);
    let { actions } = useAnimations([clip], object);

    useEffect(() => {
        let collectedItemSubs = GamePlayerContext.subscribe(state => state.collectedStaticItem, (data) => {
            setCollectedItem(data);
        });

        return () => {
            collectedItemSubs();
            detectionGeometry.dispose();
        }
    });

    useEffect(() => {
        // console.log("COLLECTED", collectedItem);

        if (collectedItem.includes(id)) {
            setVisible(false);

            object.visible = false;
        }
    }, [collectedItem]);

    const onCollideBegin = (collider) => {
        if (visible) {

            const listCollected = getCollectedStaticItemList();
            if (listCollected.indexOf(id) == -1) {
                addPoint(id, "find_dg_icon");
            }
        }
    }
    const onCollideEnd = (collider) => {
    }

    // const [ref] = useTrimesh(() => ({
    //     mass: 0,
    //     type: "Static",
    //     args: [detectionObjectVertics, detectionObjectIndices],
    //     collisionFilterMask: 2,
    //     collisionFilterGroup: 2,
    //     isTrigger: true,
    //     onCollideBegin: (collider) => {
    //         if (visible) {

    //             const listCollected = getCollectedStaticItemList();
    //             if (listCollected.indexOf(id) == -1) {
    //                 addPoint(id, "find_dg_icon");
    //             }
    //         }
    //     },
    //     onCollideEnd: (collider) => {
    //     }
    // }));

    useEffect(() => {
        let keys = Object.keys(actions);

        keys.forEach(key => {
            // console.log(actions[key]);
            actions[key].loop = LoopRepeat;
            actions[key]._mixer.addEventListener('finished', (e) => {
                currentID.current++;

                let objectKeys = Object.keys(actions);
                if (currentID.current >= objectKeys.length) {
                    currentID.current = 0;
                }

                actions[objectKeys[currentID.current]].play();
            }
            );
            actions[key].play();
        });
    }, []);

    return <group>
        <RigidBody
            colliders={"trimesh"}
            type={"kinematicPosition"}
            sensor
            onIntersectionEnter={() => {
                onCollideBegin();
            }}
            onIntersectionExit={() => {
                onCollideEnd();
            }}
            includeInvisible
        >
            <mesh geometry={detectionGeometry} visible={false}></mesh>
        </RigidBody>
        <primitive object={object} dispose={null} />
    </group>

}