import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import CloseButton from 'react-bootstrap/CloseButton';
import Fade from 'react-bootstrap/Fade';
import { AudioToggle } from './audio-toggle';
import PlayerListTable from './player-list-table';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useRef } from 'react';
import { EmojiAnimationCircular } from './emoji-circular';
import { PlayerContext } from '../player-component/context/player-context';
import { AccountContext, FunctionVariableContext, GamePlayerContext, SceneContext, VisitorTrackerContext } from '../../context/game-context';
import { useWeb3Service } from '../../../blockhain/provider/web3.provider';

export default function PlayerInteraction({ toggleHoverBoard, sendShoutoutChat, onSelectEmoji, onShoutoutChat }) {

    const [openChat, setOpenChat] = useState(false);
    const [openPlayer, setOpenPlayer] = useState(false);
    const [openEmoji, setOpenEmoji] = useState(false);
    const [listChat, setListChat] = useState([]);
    const [isHoverBoard, setHoverBoard] = useState(false);
    const shoutoutChatInput = useRef();
    const hoverboardButton = useRef();
    const muteAudioRef = useRef();
    const [muteAudio, setMuteAudio] = useState(SceneContext(state => state.muteAmbientSound));
    const [hasAudio, setHasAudio] = useState(SceneContext(state => state.ambientSound));
    const setCapturePhoto = GamePlayerContext(state => state.setCapturePhoto);
    const toggleAmbientSound = SceneContext(state => state.toggleAmbientSound);
    const [capturePhoto, setCapturePhotoState] = useState(GamePlayerContext(state => state.capturePhoto));
    const setOpenDirectMessage = GamePlayerContext(state => state.setOpenChat);

    const setOpenLoginPopup = FunctionVariableContext((state) => state.setOpenLoginPopup);
    const setShowLogin = AccountContext((state) => state.setShowLogin);
    
    const { isAuthenticated, user } = useWeb3Service();
    const userId = useRef();
    const setVisitorTrackerData = VisitorTrackerContext((state) => state.setVisitorTrackerData);

    useEffect(() => {
        userId.current = user.id;
    }, [isAuthenticated]);


    useEffect(() => {
        let muteAudioSubs = SceneContext.subscribe(state => state.muteAmbientSound, (data) => {
            setMuteAudio(data);
        });

        let ambientSoundSubs = SceneContext.subscribe(state => state.ambientSound, (data) => {
            setHasAudio(data);
        });

        const capturePhotoSubs = GamePlayerContext.subscribe(state => state.capturePhoto, (data) => {
            setCapturePhotoState(data);
        });

        return () => {
            muteAudioSubs();
            ambientSoundSubs();
            capturePhotoSubs();
        };
    }, []);

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
      }

    const setControlToggle = GamePlayerContext((state) => state.setControlToggle);

    const sendShoutout = () => {

        setVisitorTrackerData("shoutout", shoutoutChatInput.current.value)
        if (sendShoutoutChat.current !== undefined) sendShoutoutChat.current(shoutoutChatInput.current.value);
        shoutoutChatInput.current.value = "";
    }
    const enableControlToggle = (toggle) => {
        setControlToggle(toggle);
        // if (controlToggle.current !== undefined) controlToggle.current = toggle;
    }

    const onInputKeyPress = (e) => {
        if (e.key === "Enter") {
            setVisitorTrackerData("shoutout", shoutoutChatInput.current.value)
            if (sendShoutoutChat.current !== undefined) sendShoutoutChat.current(shoutoutChatInput.current.value);
            shoutoutChatInput.current.value = "";
        }
    }

    onShoutoutChat.current = (user, chat) => {
        const newChat = [...listChat, { user, chat }];
        setListChat(newChat);
    }

    const hoverboardHandler = () => {
        if (toggleHoverBoard && toggleHoverBoard.current) toggleHoverBoard.current(!isHoverBoard);
        setHoverBoard(!isHoverBoard);
    }

    useEffect(()=>{
        scrollToBottom();
    },[listChat]);

    return (
        <>
            <Fade in={openChat}>
                <div className={"chat-box"}>
                    <Card>
                        <Card.Header>
                            Chat
                            <CloseButton
                                variant="white"
                                onClick={() => setOpenChat(false)}
                            />
                        </Card.Header>
                        <Card.Body className={"chat-card-body"}>
                            <ul className="chat-list">
                                {listChat.map((r, idx) =>
                                    <li className="in" key={idx}>
                                        {/* <div className="chat-img">
                                            <Image src="./img/avatar.png" rounded={true} />
                                        </div> */}
                                        <div className="chat-body">
                                            <h5>{r.user.a}</h5>
                                            <div className="chat-message">
                                                <p>{r.chat}</p>
                                            </div>
                                        </div>
                                    </li>
                                )}
                            </ul>
                            <div ref={messagesEndRef} />
                        </Card.Body>

                        <Card.Footer>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    style={{ background: 'rgba(255, 255, 255, 0.66)', borderRadius: '3px' }}
                                    placeholder="Enter Your Message"
                                    aria-label="Enter Your Message"
                                    aria-describedby="basic-shoutout"
                                    autoComplete="off" maxLength="30"
                                    onKeyPress={e => onInputKeyPress(e)}
                                    onFocus={() => enableControlToggle(false)}
                                    onBlur={() => enableControlToggle(true)}
                                    ref={shoutoutChatInput}
                                />
                                <Button variant="outline-secondary"
                                    style={{ display: 'none' }}
                                    onClick={sendShoutout} id="button-shoutout">
                                    Send
                                </Button>
                            </InputGroup>
                        </Card.Footer>
                    </Card>
                </div>
            </Fade>

            <Fade in={openPlayer}>
                <div className={"chat-box"}>
                    <Card>
                        <Button size="sm" className='set-presenter-button open-chat' onClick={() => {
                            setOpenDirectMessage(true);
                        }}><FontAwesomeIcon icon="fas fa-comments" /></Button>
                        <Card.Header>
                            Player List
                            <CloseButton
                                variant="white"
                                onClick={() => setOpenPlayer(false)}
                            />
                        </Card.Header>
                        <Card.Body className={"chat-card-body"}>
                            <PlayerListTable />
                        </Card.Body>
                    </Card>
                </div>
            </Fade>


            <Fade in={openEmoji} style={{ left: 'auto', right: '15px' }}>
                <div className={"chat-box"}>
                    <Card>
                        <Card.Header>
                            Emoji List
                            <CloseButton
                                variant="white"
                                onClick={() => setOpenEmoji(false)}
                            />
                        </Card.Header>
                        <Card.Body className={"emoji-card-body"}>
                            <EmojiAnimationCircular onSelectEmoji={onSelectEmoji} setOpenWindowEmoji={setOpenEmoji} />
                        </Card.Body>
                    </Card>
                </div>
            </Fade>
            <div className={"btn-group-interact"}>
                <ButtonGroup className="mx-1">

                    <AudioToggle />
                </ButtonGroup>
                {!capturePhoto && <ButtonGroup>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            if (userId.current) {
                                setOpenPlayer(true);
                                setOpenChat(false);
                                setOpenEmoji(false);
                            }
                            else {
                                setShowLogin(true);
                            }
                        }}
                    >
                        <svg
                            data-src="./icon/btn-playerlist.svg"
                            fill="currentColor"
                            className="w-100 full-height"
                        />
                        {/* <FontAwesomeIcon icon="fa-users" /> */}

                    </Button>
                    <Button
                        className="chat"
                        variant="secondary"
                        onClick={() => {
                            setOpenChat(true);
                            setOpenEmoji(false);
                            setOpenPlayer(false);
                        }}
                    >
                        <span>Chat</span> <FontAwesomeIcon icon="far fa-comment" />
                    </Button>
                </ButtonGroup>}
            </div>

            <div className={"btn-group-interact right"}>
                <ButtonGroup>
                    {
                        hasAudio &&
                        <Button variant="secondary"
                            ref={muteAudioRef}
                            onClick={() => {
                                muteAudioRef.current.blur();
                                toggleAmbientSound();
                            }}
                        >
                            {muteAudio && <FontAwesomeIcon icon="fa fa-volume-up" />}
                            {!muteAudio && <FontAwesomeIcon icon="fa fa-volume-mute" />}
                        </Button>
                    }

                    <Button variant="secondary"
                        onClick={() => {
                            setOpenEmoji(true);
                            setOpenChat(false);
                            setOpenPlayer(false);
                        }}
                    >

                        <svg
                            data-src="./icon/btn-pose.svg"
                            fill="currentColor"
                            className="w-100 full-height"
                        />
                    </Button>


                    <Button variant="secondary"
                        ref={hoverboardButton}
                        onClick={(e) => {
                            hoverboardButton.current.blur();
                            hoverboardHandler()
                            setOpenEmoji(false);
                            setOpenChat(false);
                            setOpenPlayer(false);
                        }}
                    >
                        {isHoverBoard ?
                            <svg
                                data-src="./icon/btn-walk.svg"
                                fill="currentColor"
                                className="w-100 full-height"
                            />
                            :
                            <svg
                                data-src="./icon/btn-fly.svg"
                                fill="currentColor"
                                className="w-100 full-height"
                            />
                        }
                    </Button>


                    <Button variant="secondary"
                        onClick={() => {
                            setCapturePhoto(true);
                        }}
                    >

                        <svg
                            data-src="./icon/btn-capture-image.svg"
                            fill="currentColor"
                            className="w-100 full-height"
                        />
                    </Button>
                </ButtonGroup>
            </div>
        </>
    )
}