import React from "react";
// import { useTrimesh } from "@react-three/cannon"
// import { useEffect } from "react";
import { RigidBody } from "@react-three/rapier";


const MeshCollider = (props) => {
    
    return (
        <RigidBody colliders="trimesh" type="kinematicPosition" position={props.geometries.position} includeInvisible>
            <mesh geometry={props.geometries} visible={false}/>
        </RigidBody>
    )
}

export default MeshCollider;
