import Alert from 'react-bootstrap/Alert'
import { forwardRef, useEffect, useState, useCallback, useRef } from 'react';
import { GamePlayerContext, NotificationContext } from '../../context/game-context';
import { LocalPlayerAudio, ResetAudioConnection, ToggleVideo } from '../../rtc-manager';
import AudioControls from '../player-component/audio-controls';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { changeLocalAudioTrack, muteAudio, unmuteAudio } from '../../../agora/agoraService';
let state = 'mute';

export const AudioToggle = (props) => {
    let listLabel = {
        // "push_to_talk": "Push To Talk \n (Press V to talk)",
        "mute": "Muted",
        "open_mic": "Your Mic Currently Active"
    }

    const ref = useRef("mute");

    let [isPlayerHost, setPlayerHost] = useState(GamePlayerContext((state) => state.isPlayerHost));
    let [isHostControlledRoom, setHostControlledRoom] = useState(GamePlayerContext((state) => state.isHostControlledRoom));
    let [isPresenter, setPresenter] = useState(GamePlayerContext((state) => state.isPresenter));
    const [icon, setIcon] = useState('fa-microphone-slash')
    let lasState = useRef("mute");

    useEffect(() => {

        const isPlayerHostContext = GamePlayerContext.subscribe(state => state.isPlayerHost, (data) => {
            setPlayerHost(data);
        });

        const hostControllRoom = GamePlayerContext.subscribe((state) => state.isHostControlledRoom, (data) => {
            setHostControlledRoom(data);
        });

        const isPresenterRoom = GamePlayerContext.subscribe((state) => state.isPresenter, (data) => {
            setPresenter(data);
        });

        return () => {
            hostControllRoom();
            isPresenterRoom();
            isPlayerHostContext();
        }
    }, []);


    const [isAudioPermitted, setAudioPermitted] = useState(false);
    // if (navigator.permissions) {
    //     navigator.permissions.query({ name: 'microphone' }).then(function (result) {
    //         if (result.state == 'granted') {
    //             // alert("MIC PERMISSION GRANTED");
    //             setAudioPermitted(true);
    //         } else if (result.state == 'prompt') {
    //             // alert("SHOULD SHOW PROMPT");
    //         } else {
    //             // alert("MIC PERMISSION DENIED");
    //             setAudioPermitted(false);
    //         }
    //         // Don't do anything if the permission was denied.
    //     });
    // }

    const [label, setLabel] = useState(listLabel[state]);
    const changeStateAudio = useCallback((ref) => {
        if (ref.current) {
            // change state audio
            // if (state == 'push_to_talk') {
            //     state = 'open_mic';
            //     setIcon('fa-microphone');
            // } else 
            if (state == 'open_mic') {
                state = 'mute';
                setIcon('fa-microphone-slash');
                muteAudio();
            } else if (state == 'mute') {
                state = 'open_mic';
                setIcon('fa-microphone');
                unmuteAudio();
            }
        }

        ref.current = state;

        return state;
    }, [])

    const addToast = NotificationContext((state) => state.addToast);
    const promptPermission = async () => {
        // const status = await navigator.permissions.query({ name: "microphone" });
        // console.log(status);
        const permission = await navigator.mediaDevices.getUserMedia({ audio: true })
            .then((res) => {
                const audioTrack = res.getAudioTracks();
                changeLocalAudioTrack(audioTrack[0])
                setAudioPermitted(true);
                onAudioToggleClick(true)
            }).catch((err) => {
                console.log(err);
            })

        if (navigator.permissions) {
            navigator.permissions.query({ name: 'microphone' })
                .then((permissionObj) => {
                    if (permissionObj.state == 'denied') {
                        // give toast to player that mic is denied
                        addToast({ title: "Microphone Permission Denied", message: "You need to reset microphone permission", type: "danger" });
                    } else if (permissionObj.state == 'granted') {
                    }
                })
                .catch((error) => {
                })
        }

    }

    const onAudioToggleClick = () => {
        if (isHostControlledRoom) {
            if (!isPresenter) return;
        }

        const newState = changeStateAudio(ref);
        lasState.current = newState;
        setLabel(listLabel[newState]);
    }

    useEffect(() => {
        if (isHostControlledRoom && !isPresenter && !isPlayerHost) {
            state = 'mute';
            setLabel(listLabel[state]);
        }

        if (isPresenter) {
            state = 'push_to_talk'
            setLabel(listLabel[state]);
        }

        if (!isHostControlledRoom) {
            state = lasState.current;
            setLabel(listLabel[state]);
        }

    }, [isHostControlledRoom, isPresenter]);

    ref.current = state;
    return (
        <>
            <LocalPlayerAudio state={state} />
            <ToggleVideo />

            <Button
                variant="secondary"
                onClick={() => isAudioPermitted ? onAudioToggleClick(true) : promptPermission()}
            >
                {
                    icon == "fa-microphone-slash" ?
                        <svg
                            data-src="./icon/btn-micdisable.svg"
                            fill="currentColor"
                            className="w-100 full-height"
                        /> :
                        <svg
                            data-src="./icon/btn-micenable.svg"
                            fill="currentColor"
                            className="w-100 full-height"
                        />
                }
                {/* <FontAwesomeIcon icon={`fas ${isAudioPermitted ? icon : "fa-microphone-slash"}`} /> */}
                {/* <span className='divider'>|</span> */}
                {/* {!isAudioPermitted ? "Audio Microphone is Denied" : label} */}
            </Button>
        </>
        //!isHostControlledRoom || (isHostControlledRoom && isPresenter) && 
    )
    // console.log(ref.current);
}
