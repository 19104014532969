import { useTrimesh } from "@react-three/cannon";
import { RigidBody } from "@react-three/rapier";
import { useEffect } from "react";
import { AudioContext } from "../../context/game-context";

export const ConversationSpace = ({ detectionGeometry, theme }) => {

    const setConversationSpace = AudioContext(state => state.setConversationSpace);

    const onCollide = (e) => {
        setConversationSpace(theme, false);
    }

    const onCollideEnd = (e) => {
        setConversationSpace(theme, true);
    }

    return <RigidBody
        colliders={"trimesh"}
        type={"kinematicPosition"}
        sensor
        onIntersectionEnter={() => {
            onCollide();
        }}
        onIntersectionExit={() => {
            onCollideEnd();
        }}
        includeInvisible
    >
        <mesh geometry={detectionGeometry} visible={false}></mesh>
    </RigidBody>
}