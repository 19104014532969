import { Box, Html, Plane } from "@react-three/drei"
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef } from "react"
import { DoubleSide, Matrix4, NoBlending, Quaternion, Raycaster, Vector2, Vector3 } from "three";

export const HTMLVideo = ({ link, width, height, position, direction }) => {

    const groupref = useRef();
    const htmlRef1 = useRef();
    const meshRef = useRef();
    const lastParent = useRef();
    const { camera, gl } = useThree();

    const raycaster = useRef(new Raycaster());

    const defaultWidth = 600;
    const defaultScale = 1.48;

    let h = ((height/width) * defaultWidth);
    useEffect(() => {
        if (htmlRef1.current) {
            var css3d = document.getElementById("css3d");
            
            lastParent.current = htmlRef1.current.parentElement.parentElement.parentElement.parentElement;
            htmlRef1.current.parentElement.parentElement.parentElement.key = "video";
            css3d.appendChild(htmlRef1.current.parentElement.parentElement.parentElement);
        }

        return()=>{
            if(lastParent.current)lastParent.current.appendChild(htmlRef1.current.parentElement.parentElement.parentElement);
        }
    }, [htmlRef1.current]);

    useEffect(() => {
        var mx = new Matrix4().lookAt(new Vector3(direction[0], direction[1], direction[2]), new Vector3(0, 0, 0), new Vector3(0, 1, 0));
        var qt = new Quaternion().setFromRotationMatrix(mx);
        groupref.current.quaternion.set(qt.x, qt.y, qt.z, qt.w);
    }, []);

    const onMouseMove = (event) => {

        let mouse = new Vector2(
            (event.clientX / window.innerWidth) * 2 - 1,
            -(event.clientY / window.innerHeight) * 2 + 1
        );

        raycaster.current.setFromCamera(mouse, camera);
        let intersects = raycaster.current.intersectObject(meshRef.current, true);


        let webgl = document.getElementById("css3d");
        if (intersects.length > 0) {
            webgl.style.zIndex = 2;
        }
        else {
            webgl.style.zIndex = 0;
        }
    }

    useEffect(() => {
        document.addEventListener("mousemove", onMouseMove);

        return ()=>{
            document.removeEventListener("mousemove", onMouseMove);
            if(meshRef.current)meshRef.current.geometry.dispose();
        }
    });


    return <group ref={groupref} position={position} dispose={null}>
        <mesh ref={meshRef} position={[0, 0, 0]}>
            <planeBufferGeometry args={[width, height]} />
            <meshPhongMaterial opacity={0.15} color={0x111111} blending={NoBlending} side={DoubleSide} />
            <Html
                ref={htmlRef1}
                distanceFactor={1}
                transform
                fullscreen
                prepend
                zIndexRange={[0, 0]}
                scale={width / defaultScale}
            >
                <iframe src={link} width={`${defaultWidth}px`} height={`${h}px`} />
            </Html>
        </mesh>
    </group>
}

// 1.485