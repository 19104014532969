import * as THREE from 'three';
import { useFrame } from "@react-three/fiber";
import UsePersonControls from '../player-controls';
import { CanvasContext } from './canvas-context';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Vector3 } from 'three';
import { Quaternion } from 'three';
import { Euler } from 'three';
import { UpdateParameter } from './player-update-parameter';
import { PlayerContext } from './player-context';
import { GamePlayerContext } from '../../../context/game-context';
import { useAfterPhysicsStep } from '@react-three/rapier';

export const HoverBoard = (props) => {

    const { fbxLoaded, textureLoaded } = useContext(PlayerContext);
    const { forward, backward, left, right, jump, hoverDown, run } = UsePersonControls();
    const { networkManager, setDirectionalLightTarget } = useContext(CanvasContext);
    const updatePlayer = useRef();
    const isGrounded = useRef(false);
    const playerModel = useRef(props.refPlayerModel);
    const forwardDirection = useRef(new THREE.Vector3(0, 0, 0));
    const currentPosition = useRef([0, 0, 0]);
    const currentVelocity = useRef([0, 0, 0]);
    const currentTargetVelocity = useRef(new Vector3());
    const lastGroundPosition = useRef(new Vector3(0, 0, 0));
    const currentRaycastHitPoint = useRef([0, 0, 0]);
    const currentYRotation = useRef(0);
    const currentSeat = useRef("");
    const isMove = useRef(-1);
    const hoverBoard = useRef();
    const [hoverBoardGeometry, setHoverBoardGeometry] = useState();
    const [hoverBoardMaterial, setHoverBoardMaterial] = useState();
    const colliders = useRef();
    let groundRaycastSafeDistance = 2;
    let raycastDir = new THREE.Vector3(0, -1, 0);
    let raycastHitPoint = new THREE.Vector3(0, 0, 0);
    let raycastHitNormal = new THREE.Vector3(0, 0, 0);
    let targetDirection = new THREE.Vector3(0, 0, 0);
    const setCameraTarget = props.setCameraTarget.current;
    const axis = useRef({ x: 0, y: 0, j: 0 });
    let acceleration = 10;
    let maxGravitySpeed = 5;
    let moveSpeed = 15;
    let rotateSpeed = 4;
    let maxRotationX = 30;
    let upSpeed = useRef(0);
    let floatY = 1;
    let currentMoveSpeed = useRef(0);
    let currentRotationX = useRef(0);
    let _isLastGround = useRef(false);
    let _isJump = useRef(false);
    const isFPS = useRef(GamePlayerContext((state) => state.isFirstPersonCamera));

    const controlToggle = useRef(GamePlayerContext((state) => state.controlToggle));

    colliders.current = props.colliders;

    useEffect(() => {
        let gamePlayerSubs = GamePlayerContext.subscribe((state) => state.controlToggle, (data) => {
            controlToggle.current = data;
        });

        return () => {
            gamePlayerSubs();
        }
    }, []);

    useEffect(() => {

        const hoverBoardObject = fbxLoaded.find(x => x.name === "hoverboard_board.fbx").clone();
        let material = null;

        let geometry = null;
        hoverBoardObject.traverse((children => {
            if (children.isMesh) {
                geometry = children.geometry;
                material = children.material;
            }
        }));

        material.transparent = true;
        setHoverBoardMaterial(material);
        setHoverBoardGeometry(geometry);

    }, []);

    useEffect(() => {
        currentYRotation.current = radToDeg(props.refPlayerModel.current.rotation.y);
    }, []);


    useEffect(() => {
        let firstPersonSubs = GamePlayerContext.subscribe((state) => state.isFirstPersonCamera, (data) => {
            isFPS.current = data;

            props.refPlayerModel.current.traverse((child) => {
                if (child.isMesh) {
                    child.visible = !isFPS.current;
                }
            });
        });

        return () => {
            firstPersonSubs();
        };
    }, []);

    const updatePlayerPosition = () => {
        let position = props.playerObject.current.translation();
        raycastDir.set(0, -1, 0);
        let raycasterGround = new THREE.Raycaster(position, raycastDir);
        let intersectGround = raycasterGround.intersectObjects(colliders.current);

        if (intersectGround.length > 0) {
            isGrounded.current = (intersectGround[0].distance < (props.colliderRadius * 2) + groundRaycastSafeDistance);

            raycastHitPoint.set(intersectGround[0].point.x, intersectGround[0].point.y, intersectGround[0].point.z);
            currentRaycastHitPoint.current = [intersectGround[0].point.x, intersectGround[0].point.y, intersectGround[0].point.z];
            raycastHitNormal.set(intersectGround[0].face.normal.x, intersectGround[0].face.normal.y, intersectGround[0].face.normal.z);
        }
        else {
            isGrounded.current = false;
        }

        let minY = lastGroundPosition.current.y + (props.colliderRadius * 2) + floatY;
        let current = lerp(position.y, minY, 0.16);
        position.y = position.y < minY ? current : position.y;

        if (parseFloat(position.y.toFixed(3)) < parseFloat(minY.toFixed(3))) {
            props.playerObject.current.setTranslation(position);
        }

        currentPosition.current = position;
    }

    const radToDeg = (radians) => {
        let pi = Math.PI;
        return radians * (180 / pi);
    }

    const inputHandler = (delta) => {
        axis.current.x = 0;
        axis.current.y = 0;
        axis.current.j = 0;

        if (left) {
            axis.current.x = -1;
        }
        else if (right) {
            axis.current.x = 1;
        }

        if (forward) {
            axis.current.y = 1;
        }
        else if (backward) {
            axis.current.y = -1;
        }

        if (jump) {
            axis.current.j = 1;
        }
        else if (run) {
            axis.current.j = -1;
        }

        if (props.jumpInput.current) {
            axis.current.j = 1;
        }

        if (props.downInput.current) {
            axis.current.j = -1;
        }

        if (props.analogInput && props.analogInput.current) {
            axis.current.x = Math.abs(props.analogInput.current.x) > 0.5 ? Math.round(props.analogInput.current.x) : 0;
            axis.current.y = Math.abs(props.analogInput.current.y) > 0.5 ? Math.round(props.analogInput.current.y) : 0;

        }

        if (_isJump.current !== axis.current.j) {
            _isJump.current = axis.current.j;
            upSpeed.current = 0;
        }


    }

    const rotationControl = (delta) => {
        let currentRotateSpeed = 0;
        if (axis.current.x < 0) {
            currentRotateSpeed = rotateSpeed;
            currentRotationX.current = lerp(currentRotationX.current, -maxRotationX, rotateSpeed * delta);
        }
        else if (axis.current.x > 0) {
            currentRotateSpeed = -rotateSpeed;
            currentRotationX.current = lerp(currentRotationX.current, maxRotationX, rotateSpeed * delta);
        }
        else {
            currentRotationX.current = lerp(currentRotationX.current, 0, rotateSpeed * delta * 2);
        }

        upSpeed.current = axis.current.j * maxGravitySpeed;

        currentYRotation.current += currentRotateSpeed;
        let yEuler = new Euler(0, deg2rad(currentYRotation.current), 0);
        let xEuler = new Euler(0, 0, deg2rad(currentRotationX.current));
        let yQuaternion = new Quaternion().setFromEuler(yEuler);
        let xQuaternion = new Quaternion().setFromEuler(xEuler);
        let playerQuaternion = new Quaternion().multiplyQuaternions(yQuaternion, xQuaternion);

        if (controlToggle.current == true) props.refPlayerModel.current.quaternion.slerp(playerQuaternion, delta * 3);

        if(hoverBoard.current)hoverBoard.current.quaternion.set(props.refPlayerModel.current.quaternion.x, props.refPlayerModel.current.quaternion.y, props.refPlayerModel.current.quaternion.z, props.refPlayerModel.current.quaternion.w);
    }

    const groundHandler = () => {
        if (axis.current.x !== 0 || axis.current.y !== 0 || axis.current.z !== 0) {
            lastGroundPosition.current.set(currentRaycastHitPoint.current[0], currentRaycastHitPoint.current[1], currentRaycastHitPoint.current[2]);
        }
        else {
            if (isGrounded.current !== _isLastGround.current) {
                lastGroundPosition.current.set(currentRaycastHitPoint.current[0], currentRaycastHitPoint.current[1], currentRaycastHitPoint.current[2]);
            }
            _isLastGround.current = isGrounded.current;
        }

        isGrounded.current = axis.current.j === 1 ? false : isGrounded.current;
    }

    const velocityControl = (delta)=>{
        if (axis.current.y > 0) {
            if (isMove.current !== 1) {
                currentMoveSpeed.current = 0;
                isMove.current = 1;
            }
        }
        else if (axis.current.y < 0) {
            if (isMove.current !== -1) {
                currentMoveSpeed.current = 0;
                isMove.current = -1;
            }
        }
        else {
            currentMoveSpeed.current = moveTowards(currentMoveSpeed.current, 0, delta * acceleration);
            isMove.current = 0;
        }

        props.refPlayerModel.current.getWorldDirection(targetDirection);
        currentMoveSpeed.current += (acceleration * isMove.current) * delta;
        currentMoveSpeed.current = clamp(currentMoveSpeed.current, -moveSpeed, moveSpeed);
        targetDirection.multiplyScalar(currentMoveSpeed.current);
        currentTargetVelocity.current.set(targetDirection.x, upSpeed.current, targetDirection.z);

        props.playerObject.current.setLinvel(currentTargetVelocity.current);
    }

    const cameraMovementControl = () => {

        if (props.refPlayerModel.current && props.refPlayerModel.current.topHead) {
            let position = props.playerObject.current.translation();
            let topHeadPosition = new Vector3();
            props.refPlayerModel.current.getWorldPosition(topHeadPosition);
            topHeadPosition.y += 1.5;
            setCameraTarget(position.x, position.y, position.z, colliders.current, props.refPlayerModel.current, topHeadPosition, props.isFPS);
        }
    }

    useFrame((src, delta) => {
        props.refPlayerModel.current.setAnimation("hoverboard");

        inputHandler(delta);

        rotationControl(delta);

        groundHandler();

        updatePlayerPosition();

        velocityControl(delta);

    });

    useAfterPhysicsStep(()=>{
        cameraMovementControl();
    });

    const clamp = (value, min, max) => {
        if (value < min)
            return min;
        else if (value > max)
            return max;
        else
            return value;
    }


    const deg2rad = (degrees) => {
        let pi = Math.PI;
        return degrees * (pi / 180);
    }

    const moveTowards = (current, target, maxDelta) => {
        if (Math.abs(target - current) <= maxDelta) {
            return target;
        }

        return current + Math.sign(target - current) * maxDelta;
    }

    const lerp = (v0, v1, t) => {
        return v0 + t * (v1 - v0);
    }

    return (
        <group>
            {
                hoverBoardGeometry && hoverBoardMaterial &&
                <mesh
                    ref={hoverBoard}
                    scale={[0.0035, 0.0035, 0.0035]}
                    position={[0, -props.colliderRadius * 2 + 0.05, 0]}
                    geometry={hoverBoardGeometry}
                    material={hoverBoardMaterial}
                />
            }
            <UpdateParameter
                updatePlayer={updatePlayer}
                currentPosition={currentPosition}
                networkManager={networkManager}
                setDirectionalLightTarget={setDirectionalLightTarget}
                forwardDirection={forwardDirection}
                sit={currentSeat}
                {...props}
            />
        </group>
    )

}