import React, { useRef, useState, useEffect } from "react";
import { GameManager } from './game/game-manager';
import PrivateAuthState from "./game/PrivateAuthState";
import { SocketState } from "./game/component/player-component/context/socket-state";
import { InputPassword } from "./game/component/html-component/password-input";
import { CanvasProvider } from "./game/component/player-component/context/canvas-provider";
import { NFTPopup } from "./game/component/html-component/nft-popup";
import { DevelopmentTools } from "./game/component/html-component/development-tools";
import { GameConfigContext, GamePlayerContext, LocalPlayerContext, PlayerSelectionContext, SceneContext } from "./game/context/game-context";
// import Grass from "./game/shader/grass";
import './plugin/fontawesome';

//  css load
import "./css/style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/joystick.css";


import { PlayerDuetAnimation } from "./game/component/html-component/player-duet-animation";
import ShareScreen from "./game/component/html-component/share-screen";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PlayerState } from "./game/component/player-component/context/player-state";
import JoyStick from "./game/component/html-component/joystick";
import LandingPage from "./game/component/html-component/landing-page";
import PlayerInteraction from "./game/component/html-component/player-interaction";
import ToastNotification from "./game/component/html-component/toast-notification";
import { Fade } from "react-bootstrap";
import SettingsMenu from "./game/component/html-component/settings-menu";
import { Crosshair } from "./game/component/html-component/crosshair";
import MenuCircular from "./game/component/html-component/menu-circular";
import { Popup } from "./game/component/html-component/popup";
import { BackToMainScene } from "./game/component/html-component/back-to-main-scene";
import { SceneHandler } from "./game/scene-handler";
import { RequiredUserDataModal } from "./game/component/html-component/required-user-data-modal";
import Tutorial from "./game/component/html-component/tutorial";
import MinimapModal from "./game/component/html-component/minimap-modal";
import { NPCPopup } from "./game/component/html-component/npc-popup";
import { ChangeSceneCounter } from "./game/component/html-component/change-scene-counter";
import PlayerEndlessrunInteraction from "./game/component/html-component/player-endlessrun-interaction";
import TreasureHuntEvent from "./game/component/html-component/treasure-hunt-event";
import { CountDown } from "./game/component/html-component/count-down";
import { DropItemPopup } from "./game/component/html-component/drop-item-popup";
import { AmbienSound } from "./game/component/html-component/ambien-sound";
import { Area } from "./game/component/html-component/area";
import { CapturePhoto } from "./game/component/html-component/capture-photo";
import { AnimationControl } from "./game/component/html-component/animation-control";
import { VisitorTracker } from "./game/component/visitor-tracker";
import { LandscapeWarning } from "./game/component/html-component/landscape-warning";
import { InstructionPopup } from "./game/component/html-component/instruction-popup";
import { SocialMediaPopup } from "./game/component/html-component/social-media-popup";
import { ChangeServerRoom } from "./game/component/html-component/change-server-room";
import { PrivateMessage } from "./game/private-message";
import { EndlesRunButton } from "./game/component/html-component/endlesrun-button";
import { DropNPCPopup } from "./game/component/html-component/dropped-npc";
import { RhythmGameIframe } from "./game/component/html-component/rhythm-game-iframe";
import { TriviaPopup } from "./game/component/html-component/trivia-popup";
import { HardwareDevicesSetting } from "./game/component/html-component/hardware-devices-setting";
import { ListAudioNear } from "./game/component/html-component/list-audio-near";
import { ConversationSpaceUI } from "./game/component/html-component/conversation-space-ui";
import { BlockAccountLogged } from "./game/component/html-component/block-account-logged";
import Participant from "./game/component/html-component/participant";

function App() {

    const getFirstPlayerName = LocalPlayerContext((state) => state.displayName);
    const getIsAccountBinded = GamePlayerContext((state) => state.isAccountBinded);
    const getIsProfileUpdated = GamePlayerContext((state) => state.isProfileUpdated);
    const setSelectPlayerCompleted = PlayerSelectionContext((state) => state.setSelectPlayerCompleted);
    const setLocalPlayerLastPosition = GamePlayerContext((state) => state.setLocalPlayerLastPosition);
    const setShowSettingMenu = GameConfigContext((state) => state.setShowSettingMenu);
    const [capturePhoto, setCapturePhoto] = useState(GamePlayerContext(state => state.capturePhoto));
    const [isOrbitOnly, setIsOrbitOnly] = useState(SceneContext(state => state.orbitOnly));
    const [isEndlesRun, setIsEndlessRun] = useState(SceneContext(state => state.isEndlesRun));
    const [animatedCamera, setAnimatedCamera] = useState(SceneContext(state => state.animatedCamera));

    const sendShoutoutChat = useRef();
    const sendSelectEmoji = useRef();
    const onChangeCameraMode = useRef();
    const toggleHoverBoard = useRef();
    const dayNightValueChanged = useRef();
    const setMonitorValue = useRef();
    const onOpenNFT = useRef();
    const onChangeScene = useRef();
    const onPlayerSit = useRef();
    const prevSelection = useRef();
    const nextSelection = useRef();
    const setAvatarSelection = useRef();
    const onRemoteClicked = useRef();
    const onRequestDuetAnimation = useRef();
    const onGetRequestDuetAnimation = useRef();
    const onReplyDuetAnimation = useRef();
    const joinHostedRoom = useRef();
    const createHostedRoom = useRef();
    const sceneID = useRef();
    const onShoutoutChat = useRef();
    const audioToggleRef = useRef();
    const controlToggle = useRef(true);
    const progress = useRef(0);
    const activeTabSettingMenu = useRef(null);
    const rtcRef = useRef(null);

    // treasure hunt modal ref
    const THAchievementModalRef = useRef();
    const THRedeemModalRef = useRef();

    const [isMobile, setIsMobile] = useState(/Android|webOS|iPhone|IPad|iPadOS|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    const isMustPortrait = useRef(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    const [lanscapeWarning, setLandscapeWarning] = useState(false);
    // const [canEnter, setCanEnter] = useState(PrivateAuthState.getCanEnter());
    const [canEnter, setCanEnter] = useState(true);
    const [playerName, setPlayerName] = useState(getFirstPlayerName);
    const [isNameInit, setIsNameInit] = useState(false);
    const [isSelectPlayer, setSelectPlayer] = useState(false);
    const [openSetting, setOpenSetting] = useState(false);
    const [openDevelopmentSetting, setOpenDevelopmentSetting] = useState(false);
    const [requiredUserData, setRequiredUserData] = useState(false);
    const isTutorialDone = localStorage.getItem('tutorialDone');
    // const [showTutorial, setShowTutorial] = useState(isTutorialDone ? false : true);
    const [showTutorial, setShowTutorial] = useState(true);

    const setLoading = GameConfigContext((state) => state.setLoading);


    const onPasswordInput = (password) => {
        if (password === 'animaverseforever') {
            setCanEnter(true);
            PrivateAuthState.setCanEnter(true);
        } else {
            alert('Wrong password!');
        }
    }

    const onSelectEmoji = (id) => {
        if (sendSelectEmoji.current !== undefined) sendSelectEmoji.current(id);
    }

    const loading = (e) => {
    }

    const onClick = (command, parameter, mesh, e) => {
        if (command === "nft") {
            onOpenNFT.current(command, parameter);
        }
        if (command === "sit") {
            onPlayerSit.current(command, parameter, mesh);
        }

        if (command == "award") {
            // showVoteModal(parameter);
        }
    }

    const onPointerEnter = (mesh, e) => {
    }

    const onPointerOut = (mesh, e) => {
    }

    const openAvatarSelection = () => {
        setSelectPlayerCompleted(false);
        setLocalPlayerLastPosition();
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)


        return () => {
            window.addEventListener("resize", handleResize)
        }
    })
    // disabled contextMenu
    document.addEventListener('contextmenu', event => event.preventDefault());

    // handling  resize window
    const handleResize = () => {
        // if (window.innerWidth <= 1024) {
        //     setIsMobile(true)
        // } else {
        //     setIsMobile(false)
        // }
    }

    useEffect(() => {

        const capturePhotoSubs = GamePlayerContext.subscribe(state => state.capturePhoto, (data) => {
            setCapturePhoto(data);
        });

        const isOrbitOnlySubs = SceneContext.subscribe(state => state.orbitOnly, (data) => {
            setIsOrbitOnly(data);
        });

        const isEndlesRunSubs = SceneContext.subscribe(state => state.isEndlesRun, (data) => {
            setIsEndlessRun(data);
        });

        const animatedCameraSubs = SceneContext.subscribe(state => state.animatedCamera, (data) => {
            setAnimatedCamera(data);
        });

        // const checkIsNameInit = getIsAccountBinded ? true : false;
        const checkIsNameInit = getIsProfileUpdated ? true : false;
        const playerName = LocalPlayerContext.subscribe(state => state.displayName, (data) => {
            if (data) {
                setIsNameInit(true);
                setPlayerName(data);
            }
        });

        const playerLoggedIn = GamePlayerContext.subscribe(state => state.isProfileUpdated, (data) => {
            if (data) {
                setSelectPlayerCompleted(data);
                setSelectPlayer(!data)
            }
        });

        const playAsGuest = GamePlayerContext.subscribe(state => state.isPlayAsGuest, (data) => {
            if (data) {
                setIsNameInit(true);
            }
        });

        if (checkIsNameInit && !isNameInit) {
            setIsNameInit(true);
            setPlayerName(getFirstPlayerName);
            setSelectPlayerCompleted(true);
            setSelectPlayer(false)
        } else {
            setSelectPlayer(true)
            setSelectPlayerCompleted(false);
            // setLoading(false);
        }

        const selectPlayerSub = PlayerSelectionContext.subscribe(state => state.selectPlayerCompleted, (data) => {
            setSelectPlayer(!data);
        });

        const settingsMenuShow = GameConfigContext.subscribe(state => state.showSettingMenu, (data) => {


            // if (data[1]) {
            //     activeTabSettingMenu.current = data[1];
            // }
            setOpenSetting(data);

        });


        const settingsMenuTabActive = GameConfigContext.subscribe(state => state.activeTab, (data) => {
            if (data) {
                activeTabSettingMenu.current = data;
            }

        });

        if (!canEnter) setLoading(false, null)

        return () => {
            animatedCameraSubs();
            isOrbitOnlySubs();
            selectPlayerSub();
            playerName();
            playerLoggedIn();
            playAsGuest();
            settingsMenuShow();
            settingsMenuTabActive();
            capturePhotoSubs();
            isEndlesRunSubs();
        }
    }, [])

    useEffect(() => {
        if (isSelectPlayer) {
            setOpenSetting(true);
        }
    }, [isSelectPlayer])

    const playerModelRef = useRef();
    const onClicked = useRef();
    const onFBXUpdated = useRef();
    const [listAvatar, setListAvatar] = useState([]);

    return (<>

        <BrowserRouter>
            <div className="App">
                <AnimationControl />
                <Routes>

                    <Route path="/:sceneid" element={<>
                        <SceneHandler sceneID={sceneID} setListAvatar={setListAvatar} />
                    </>
                    } />
                    {/* <Route path="/animaverse/:sceneid" element={<Child sceneID={sceneID} />} /> */}
                </Routes>

                {/* <Suspense fallback={loading}> */}
                <SocketState sceneID={sceneID} setRequiredUserData={setRequiredUserData}>

                    <Routes>

                        <Route path="/:sceneid" element={<>

                            {isNameInit && !isSelectPlayer && !requiredUserData && !isOrbitOnly && !isEndlesRun && !animatedCamera && <PlayerInteraction
                                // controlToggle={controlToggle}
                                sendShoutoutChat={sendShoutoutChat}
                                onShoutoutChat={onShoutoutChat}
                                onSelectEmoji={onSelectEmoji}
                                toggleHoverBoard={toggleHoverBoard}
                            />}
                        </>
                        } />
                        <Route path="/endlessrun" element={<>
                            <SceneHandler sceneID={sceneID} setListAvatar={setListAvatar} />
                            {isNameInit && !isSelectPlayer && <PlayerEndlessrunInteraction />}
                        </>
                        } />
                        <Route path="/dgplaza" element={<>
                            {isNameInit && !isSelectPlayer && <TreasureHuntEvent eventCode={'dgcon2022'} currentScene={"dgplaza"} showTutorial={showTutorial} showAchievementModalRef={THAchievementModalRef} showRedeemModalRef={THRedeemModalRef} />}
                            {isNameInit && !isSelectPlayer && !requiredUserData && !isOrbitOnly && <PlayerInteraction
                                // controlToggle={controlToggle}
                                sendShoutoutChat={sendShoutoutChat}
                                onShoutoutChat={onShoutoutChat}
                                onSelectEmoji={onSelectEmoji}
                                toggleHoverBoard={toggleHoverBoard}
                            />}
                        </>} />
                        <Route path="/dgaward" element={<>
                            {isNameInit && !isSelectPlayer && <TreasureHuntEvent eventCode={'dgcon2022'} showAchievementModalRef={THAchievementModalRef} showRedeemModalRef={THRedeemModalRef} />}
                            {isNameInit && !isSelectPlayer && !requiredUserData && !isOrbitOnly && <PlayerInteraction
                                // controlToggle={controlToggle}
                                sendShoutoutChat={sendShoutoutChat}
                                onShoutoutChat={onShoutoutChat}
                                onSelectEmoji={onSelectEmoji}
                                toggleHoverBoard={toggleHoverBoard}
                            />}
                        </>
                        } />
                        <Route path="/dgcon" element={<>
                            {isNameInit && !isSelectPlayer && <TreasureHuntEvent eventCode={'dgcon2022'} showAchievementModalRef={THAchievementModalRef} showRedeemModalRef={THRedeemModalRef} />}
                            {isNameInit && !isSelectPlayer && !requiredUserData && !isOrbitOnly && <PlayerInteraction
                                // controlToggle={controlToggle}
                                sendShoutoutChat={sendShoutoutChat}
                                onShoutoutChat={onShoutoutChat}
                                onSelectEmoji={onSelectEmoji}
                                toggleHoverBoard={toggleHoverBoard}
                            />}
                        </>
                        } />
                        <Route path="/dgmainstage" element={<>
                            {isNameInit && !isSelectPlayer && <TreasureHuntEvent eventCode={'dgcon2022'} showAchievementModalRef={THAchievementModalRef} showRedeemModalRef={THRedeemModalRef} />}
                            {isNameInit && !isSelectPlayer && !requiredUserData && !isOrbitOnly && <PlayerInteraction
                                // controlToggle={controlToggle}
                                sendShoutoutChat={sendShoutoutChat}
                                onShoutoutChat={onShoutoutChat}
                                onSelectEmoji={onSelectEmoji}
                                toggleHoverBoard={toggleHoverBoard}
                            />}
                        </>
                        } />

                        <Route path="/dgmall" element={<>
                            {isNameInit && !isSelectPlayer && <TreasureHuntEvent eventCode={'dgcon2022'} showAchievementModalRef={THAchievementModalRef} showRedeemModalRef={THRedeemModalRef} />}
                            {isNameInit && !isSelectPlayer && !requiredUserData && !isOrbitOnly && <PlayerInteraction
                                // controlToggle={controlToggle}
                                sendShoutoutChat={sendShoutoutChat}
                                onShoutoutChat={onShoutoutChat}
                                onSelectEmoji={onSelectEmoji}
                                toggleHoverBoard={toggleHoverBoard}
                            />}
                        </>
                        } />
                        <Route path="/dgexhibition" element={<>
                            {isNameInit && !isSelectPlayer && <TreasureHuntEvent eventCode={'dgcon2022'} showAchievementModalRef={THAchievementModalRef} showRedeemModalRef={THRedeemModalRef} />}
                            {isNameInit && !isSelectPlayer && !requiredUserData && !isOrbitOnly && <PlayerInteraction
                                // controlToggle={controlToggle}
                                sendShoutoutChat={sendShoutoutChat}
                                onShoutoutChat={onShoutoutChat}
                                onSelectEmoji={onSelectEmoji}
                                toggleHoverBoard={toggleHoverBoard}
                            />}
                        </>
                        } />
                        {/* <Route path="/animaverse/:sceneid" element={<Child sceneID={sceneID} />} /> */}
                    </Routes>
                    <div id="css3d"></div>
                    <div id="webgl"></div>
                    <PlayerState listAvatar={listAvatar} sceneID={sceneID}>
                        {!requiredUserData && isNameInit &&
                            <CanvasProvider
                                dayNightValueChanged={dayNightValueChanged}
                                isMobile={isMobile}
                            >
                                <GameManager
                                    playerName={playerName}
                                    //TODO
                                    //Callback nya kayaknya salah ya, mungkin ada cara yang lebih estetik?
                                    onSendShoutout={sendShoutoutChat}
                                    onSelectEmoji={sendSelectEmoji}
                                    controlToggle={controlToggle}
                                    onChangeCameraMode={onChangeCameraMode}
                                    setHoverBoard={toggleHoverBoard}
                                    setMonitorValue={setMonitorValue}
                                    onChangeScene={onChangeScene}
                                    onClick={onClick}
                                    onPointerEnter={onPointerEnter}
                                    onPointerOut={onPointerOut}
                                    audioToggle={audioToggleRef}
                                    onPlayerSit={onPlayerSit}
                                    onRemoteClicked={onRemoteClicked}
                                    onGetRequestDuetAnimation={onGetRequestDuetAnimation}
                                    prevSelection={prevSelection}
                                    nextSelection={nextSelection}
                                    setAvatarSelection={setAvatarSelection}
                                    isSelectPlayer={isSelectPlayer}
                                    onRequestDuetAnimation={onRequestDuetAnimation}
                                    onReplyDuetAnimation={onReplyDuetAnimation}
                                    joinHostedRoom={joinHostedRoom}
                                    createHostedRoom={createHostedRoom}
                                    sceneID={sceneID}
                                    onShoutoutChat={onShoutoutChat}
                                    showTutorial={showTutorial}
                                    rtcRef={rtcRef}
                                />
                            </CanvasProvider>


                        }

                        {
                            !requiredUserData && isNameInit &&
                            <Fade in={openSetting}>
                                <div id='settings-menu-cover' style={{ display: !openSetting ? "none" : "block" }}>
                                    <SettingsMenu
                                        rtcRef={rtcRef}
                                        listAvatar={listAvatar}
                                        activeTab={activeTabSettingMenu}
                                        openSetting={openSetting}
                                        onClose={() => {
                                            setSelectPlayerCompleted(true);
                                            setShowSettingMenu(false, null);
                                            setOpenSetting(false)
                                        }} />
                                </div>

                            </Fade>
                        }

                        {isNameInit && !isSelectPlayer && !requiredUserData && !capturePhoto && <MenuCircular
                            toggleHoverBoard={toggleHoverBoard}
                            onChangeScene={onChangeScene}
                            sceneID={sceneID}
                            setOpenDevelopmentSetting={setOpenDevelopmentSetting}
                            setShowTutorial={setShowTutorial}
                            THAchievementModalRef={THAchievementModalRef}
                            THRedeemModalRef={THRedeemModalRef}
                        />
                        }
                        {/* <MiniMap /> */}
                        <ListAudioNear rtcRef={rtcRef} />
                        {/* <Participant rtcRef={rtcRef}  /> */}
                    </PlayerState>
                    {!requiredUserData && canEnter && !isNameInit && <LandingPage sceneID={sceneID} />}
                    {canEnter && requiredUserData && <RequiredUserDataModal
                        setRequiredUserData={setRequiredUserData}
                        requiredUserData={requiredUserData} />}

                    {isNameInit && !isSelectPlayer && !capturePhoto && <MinimapModal />}
                    {isNameInit && !isSelectPlayer && !openSetting && !requiredUserData && <Tutorial isMobile={isMobile} showTutorial={showTutorial} setShowTutorial={setShowTutorial} sceneID={sceneID} />}

                </SocketState>
                {/* </Suspense> */}
                {/* <div className={`loading ${canEnter && isNameInit && !isLoadedAll ? "" : "hide"}`}>{isLoadedAll ? "ALL DONE!" : "LOADING ANIMAVERSE"}</div> */}

                {!canEnter && <div className="input-password"><InputPassword onPasswordInput={onPasswordInput} /></div>}


                {/* {
                    isNameInit && !isSelectPlayer && <input ref={shoutoutChatInput} type="text" id="shoutout-input" autoComplete="off" maxLength="30" onKeyPress={e => onInputKeyPress(e)} onFocus={() => enableControlToggle(false)} onBlur={() => enableControlToggle(true)} />
                }
                {
                    isNameInit && !isSelectPlayer && <button onClick={sendShoutout} id="shoutout-button">SEND</button>
                } */}
                {/* {
                    isNameInit && !isSelectPlayer && <EmojiAnimation onSelectEmoji={onSelectEmoji} />
                } */}

                {
                    isNameInit && !isSelectPlayer && openDevelopmentSetting &&
                    <DevelopmentTools
                        onChangeCameraMode={onChangeCameraMode}
                        toggleHoverBoard={toggleHoverBoard}
                        dayNightValueChanged={dayNightValueChanged}
                        setMonitorValue={setMonitorValue}
                        onChangeScene={onChangeScene}
                        sceneID={sceneID}
                        audioToggleRef={audioToggleRef}
                        joinHostedRoom={joinHostedRoom}
                        createHostedRoom={createHostedRoom}
                        openAvatarSelection={openAvatarSelection}
                    />
                }
                {
                    isNameInit && !isSelectPlayer &&
                    <PlayerDuetAnimation
                        onRemoteClicked={onRemoteClicked}
                        onRequestDuetAnimation={onRequestDuetAnimation}
                        onGetRequestDuetAnimation={onGetRequestDuetAnimation}
                        onReplyDuetAnimation={onReplyDuetAnimation}
                    />
                }
                <NFTPopup onOpenNFT={onOpenNFT} />
                {isNameInit && !isSelectPlayer && isMobile && !openSetting && <JoyStick />}
                <Popup />
                <NPCPopup />
                <ChangeSceneCounter />
                <TriviaPopup />
                <ConversationSpaceUI />
                <div className="audio-list" style={{ display: 'none' }}></div>

                <ShareScreen rtcRef={rtcRef}/>
                {/* {isNameInit && !isSelectPlayer && <LoginButton />} */}
                {
                    // isNameInit && !isSelectPlayer && <PlayerPoint />
                }
                {/* {isNameInit && !isSelectPlayer && <MiniProfile />} */}
                {/* {isNameInit && !isSelectPlayer && <ProfileCircular />} */}

                <ToastNotification />
                {/* <Idle /> */}
                <Crosshair />
                {
                    isNameInit && !isSelectPlayer && <BackToMainScene></BackToMainScene>
                }
                {isNameInit && !isSelectPlayer && capturePhoto && <CapturePhoto />}
                {isNameInit && !isSelectPlayer && !openSetting && <CountDown showTutorial={showTutorial} />}
                {isNameInit && !isSelectPlayer && !openSetting && !isEndlesRun && <DropItemPopup />}
                {isNameInit && !isSelectPlayer && !openSetting && !isEndlesRun && <DropNPCPopup />}
                {isNameInit && !isSelectPlayer && !openSetting && <Area />}
                {isNameInit && !isSelectPlayer && !openSetting && <SocialMediaPopup />}
                {isNameInit && !isSelectPlayer && !openSetting && animatedCamera && <RhythmGameIframe />}

                {isNameInit && !isSelectPlayer && !openSetting && <ChangeServerRoom />}
                <AmbienSound />
                <VisitorTracker sceneID={sceneID} />
                <PrivateMessage />
                {isMustPortrait.current && isSelectPlayer && <LandscapeWarning />}
                <InstructionPopup showTutorial={showTutorial} />
                {isEndlesRun && <EndlesRunButton />}

                {/* <HardwareDevicesSetting rtcRef={rtcRef} /> */}
            </div>
        </BrowserRouter>
    </>
    )
}
export default App;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
