import axios from "axios";
import { forwardRef, useEffect, useRef, useState } from "react";
import LoadMaterial from "./scene-component/load-material";
import LoadObject from "./scene-component/load-static-object";
import * as THREE from 'three';
import { CloneGeometryAndSetMatrix, CreateMatrix, LoadClonedGeometry } from "./scene-component/utils";
import StaticObject from "./scene-component/static-object";
import MeshCollider from "./scene-component/mesh-collider";
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';
import { ClickableObject } from "./scene-component/clickable-object";
import { AnimationClip, CubeTextureLoader, DoubleSide, Group, Matrix4, Mesh, MeshBasicMaterial, MeshPhysicalMaterial, Object3D, Quaternion, TextureLoader, Vector3 } from "three";
import { VideoBanner } from "./scene-component/video-banner";
import { AnimationContext, GameConfigContext, GamePlayerContext, PlayerSelectionContext, SceneContext } from "../context/game-context";
import { TeleportDetection } from "./scene-component/teleport-detection";
import { Text, Billboard } from "@react-three/drei";
import { PopupObject } from "./scene-component/PopupObject";
import { AnimatedPhysics } from "./scene-component/animated-physics";
import { ChangeSceneDetection } from "./scene-component/change-scene-detection";
import { AnimationObject } from "./scene-component/animation-object";
import { DroppedItem } from "./scene-component/dropped-item";
import { mergeBufferGeometries } from "three/examples/jsm/utils/BufferGeometryUtils";
import { StaticCollectableItems } from "./scene-component/static-collectable-item";
import { Trivia } from "./scene-component/trivia";
import { data } from "jquery";
import { AreaDetection } from "./scene-component/area-detection";
import { useFiatBuy } from "react-moralis";
import { SocialMediaButton } from "./scene-component/social-media-button";
import { HTMLVideo } from "./scene-component/html-video";
import { ActionButton } from "./scene-component/action-button";
import { PhotoSpot } from "./scene-component/photo-spot";
import { TaskQueue } from 'cwait';
import { AnimatedCamera } from "./scene-component/animated-camera";
import { useThree } from "@react-three/fiber";
import { ConversationSpace } from "./scene-component/conversation-space";

const Scene = forwardRef(({ onSceneLoadFinish, children, onClick, onPointerEnter, onPointerOut, setRemoteAction, assetRef, playerAssetRef, isLoadSceneFinish, networkManager }, ref) => {
    const sceneRef = ref;

    const MAX_SIMULTANEOUS_DOWNLOADS = 6;
    THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
    THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
    THREE.Mesh.prototype.raycast = acceleratedRaycast;

    const setSceneAssets = SceneContext((state) => state.setSceneAssets);
    const setSceneProperties = SceneContext((state) => state.setSceneProperties);
    const setScenePanoramics = SceneContext((state) => state.setScenePanoramics);
    const setSceneSpawnPoint = SceneContext((state) => state.setSceneSpawnPoint);
    const setPhotoIframe = SceneContext((state) => state.setPhotoIframe);
    const setSceneMinimap = SceneContext((state) => state.setSceneMinimap);
    const set360Cubemap = SceneContext((state) => state.set360Cubemap);
    const setControllableAnimation = GamePlayerContext((state) => state.setControllableAnimation);
    const setEndlessRunTrack = SceneContext((state) => state.setEndlessRunTrack);
    const setEndlessRunPlayer = SceneContext((state) => state.setEndlessRunPlayer);
    const setIsEndlessRun = SceneContext((state) => state.setIsEndlessRun);
    const setIsCustomSkybox = SceneContext((state) => state.setIsCustomSkybox);
    const setCustomSkybox = SceneContext((state) => state.setCustomSkybox);
    const setDynamicGroundColliders = SceneContext((state) => state.setDynamicGroundColliders);
    const selectPlayerCompleted = useRef(PlayerSelectionContext((state) => state.selectPlayerCompleted));
    const setLoading = GameConfigContext((state) => state.setLoading);
    const setLoadSceneFinish = GameConfigContext((state) => state.setLoadSceneFinish);
    const setLoadProgress = SceneContext((state) => state.setLoadProgress);
    const setAnimationSource = AnimationContext((state) => state.animationSource);
    const initAnimation = AnimationContext((state) => state.initAnimation);
    const animatedCameraPlayerClip = useRef();

    const isMobile = /Android|webOS|iPhone|iPad|iPadOS|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const [animatedCamera, setAnimatedCamera] = useState([]);
    const [animatedPhysics, setAnimatedPhysics] = useState([]);
    const [animationObjects, setAnimationObjects] = useState([]);
    const [droppedItems, setDroppedItems] = useState([]);
    const [conversationSpaces, setConversationSpaces] = useState([]);
    const [staticCollectableItems, setStaticCollectableItems] = useState([]);
    const [meshCollider, setMeshCollider] = useState([]);
    const [textMesh, setTextMesh] = useState([]);
    const [popups, setPopups] = useState([]);
    const [socialMediaButtons, setSocialMediaButtons] = useState([]);
    const [actionButtons, setActionButtons] = useState([]);
    const [photoSpot, setPhotoSpot] = useState([]);
    const [asset, setAsset] = useState({ staticObject: [], clcikableObject: [], banners: [] });
    const [isAllLoaded, setIsAllLoaded] = useState(false);
    const [teleportDetection, setTeleportDetection] = useState([]);
    const [changeSceneDetection, setChangeSceneDetection] = useState([]);
    const loadedGeometry = useRef([]);
    const loadedMaterial = useRef([]);
    const spawnPoint = useRef([]);
    const [areaDetections, setAreaDetections] = useState([]);
    const [trivia, setTrivia] = useState();
    const clickableActions = useRef({});
    const clickableObjectsRef = useRef({});
    const [sceneName, setSceneName] = useState(SceneContext((state) => state.scene));
    const [loadedScene, setLoadedScene] = useState();
    const setArea = SceneContext(state => state.setArea);
    const setAmbientSound = SceneContext((state) => state.setAmbientSound);
    let refColliders = [];
    let refTeleportDetection = [];
    let refChangeSceneDetection = [];
    let dynamicGroundCollider = useRef([]);

    let colliders = [];
    let teleportColliders = [];
    let changeSceneColliders = [];
    let staticObjectGroups = [];
    let npcs = [];
    let clickableObjects = [];
    let banners = [];

    const totalData = useRef(0);
    const loadProgress = useRef(0);

    let colliderMaterial = useRef(new THREE.MeshBasicMaterial({
        color: 0xFFFFFF
    }));
    let popupMaterial = useRef(new THREE.MeshBasicMaterial({
        color: 0xfbd801,
        transparent: false,
        opacity: 1,
        side: DoubleSide,
    }));
    let popupReadedMaterial = useRef(new THREE.MeshBasicMaterial({
        color: 0xd4d4d4,
        transparent: false,
        opacity: 1,
        side: DoubleSide,
    }));
    let npcPopupMaterial = useRef(new MeshBasicMaterial({
        color: 0xFFFFFF,
        opacity: 0,
        transparent: true
    }));


    setRemoteAction.current = (playerAction) => {
        let playerID = Object.keys(playerAction);
        let actions = playerID.map((id) => { return playerAction[id] });


        let actionKeys = Object.keys(clickableActions.current);
        actionKeys.forEach(actionKey => {
            clickableActions.current[actionKey](!actions.includes(actionKey), actions.includes(actionKey));
        });
    }

    const isPlayerCompleted = PlayerSelectionContext((state) => state.selectPlayerCompleted);
    const { gl } = useThree();
    useEffect(() => {
        console.log("HERE");
        const loadSceneHandler = () => {
            if (loadedScene != sceneName) {
                gl.info.reset()
                // console.log('CHANGE SCENE', name)
                setAsset({ staticObject: [], clcikableObject: [], banners: [] });
                setIsAllLoaded(false);

                LoadData(sceneName);
            }
            else {
                // console.log('SCENE UPDATE', sceneName)
            }
        }

        // load scene if player completed (this fix login not load scene)
        if (isPlayerCompleted) {
            loadSceneHandler();
        }
        let playerSelectionSubs = PlayerSelectionContext.subscribe((state) => state.selectPlayerCompleted, (data) => {
            selectPlayerCompleted.current = (data);
            loadSceneHandler();

        });

        let sceneSub = SceneContext.subscribe((state) => state.scene, (data) => {

            if (data != loadedScene && selectPlayerCompleted.current) {

                setAsset({ staticObject: [], clcikableObject: [] });
                setIsAllLoaded(false);
                LoadData(data);
            }
            else {
                setSceneName(data);
            }
        });

        return () => {
            playerSelectionSubs();
            sceneSub();
        }
    }, []);

    const getTotalData = (res) => {
        let total = 0;

        let keys = Object.keys(res);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];

            if (Array.isArray(res[key])) {
                total += res[key].length;

                // console.log(key, res[key].length);
            }
        }


        return total;
    }

    const addProgress = () => {
        loadProgress.current++;
        totalData.current;

        setLoadProgress(10 + Math.floor((loadProgress.current / totalData.current) * 90));
    }

    const LoadData = async (sceneName) => {

        console.log("START LOAD DATA");

        isLoadSceneFinish.current = false;
        if (assetRef) assetRef.current = {};
        setLoading(true, null);
        loadedGeometry.current = [];
        loadedMaterial.current = [];
        spawnPoint.current = [];
        colliders = [];
        dynamicGroundCollider.current = [];
        setMeshCollider([]);
        setTextMesh([]);
        setTeleportDetection([]);
        setPopups([]);
        setAnimationObjects([]);
        setAnimatedPhysics([]);
        setChangeSceneDetection([]);
        setDroppedItems([]);
        setStaticCollectableItems([]);
        setAreaDetections([]);
        setControllableAnimation([]);
        setTrivia();
        setArea();
        setSocialMediaButtons([]);
        setPhotoSpot([]);
        setEndlessRunPlayer([]);
        setEndlessRunTrack([]);
        setConversationSpaces([]);
        setIsEndlessRun(false);
        setAsset({ staticObject: [], clcikableObject: [], banners: [] });

        setLoadProgress(0);
        staticObjectGroups = [];
        npcs = [];
        clickableObjects = [];
        clickableObjectsRef.current = {};
        banners = [];
        clickableActions.current = {};
        teleportColliders = [];
        // fetch scenes

        // console.log("START LOAD");
        setLoadSceneFinish(true);

        setLoading(true, "Downloading world's data...");

        axios.get(`${process.env.RESOURCE_URL}/${sceneName}/scenes/${sceneName}.json`, {
            crossDomain: true,
            onDownloadProgress: (progressEvent) => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                setLoadProgress(percentCompleted / 10);
            },
        })
            .then((res) => res.data)
            .then((res) => {


                let mainScene = res.mainScene ? res.mainScene : "";
                let orbitOnly = res.orbitOnly;
                setSceneProperties(orbitOnly, mainScene);
                setSceneSpawnPoint(res.spawnPoint);


                if (res.photoIframe && res.photoIframe.length > 0) {
                    setPhotoIframe(res.photoIframe);
                }

                if (res.ambientSound && res.ambientSound.length > 0) {
                    let src = `${process.env.RESOURCE_URL}/${sceneName}/sounds/${res.ambientSound}`;
                    setAmbientSound(src);
                }
                else {
                    setAmbientSound("");
                }


                if (res.minimap) setSceneMinimap(res.minimap, sceneName);

                totalData.current = getTotalData(res);
                loadProgress.current = 0;

                return res;
            })
            .then(async (res) => {

                setLoading(true, "Loading the material...");

                await loadMaterial(res, sceneName, assetRef);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading the world...");

                // console.log(assetRef);
                await loadStaticObject(res, sceneName, assetRef);
                return res;
            })
            .then(async (res) => {

                setLoading(true, "Preparing the sky...");

                if (res.isCustomSkybox) {
                    loadSkyboxTexture(res, sceneName);
                }

                setIsCustomSkybox(res.isCustomSkybox);
                return res;
            })
            .then(async (res) => {

                setLoading(true, "Loading the boundary...");

                await loadCollider(res, sceneName);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading interactions...");

                await loadClickable(res, sceneName, assetRef);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading popups...");

                await loadPopup(res, sceneName, assetRef);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading standing point...");

                loadSpawnPoint(res, sceneName);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading banner...");

                await loadBanner(res, sceneName);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading teleport area...");

                await loadTeleportDetection(res, sceneName, assetRef);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading teleport area...");

                await loadChangeSceneDetection(res, sceneName, assetRef);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading npc...");

                await loadNPC(res, sceneName);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading informations...");

                await loadText(res, sceneName);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading physics...");

                await loadAnimatedPhysics(res, sceneName, assetRef);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading world...");

                await loadScene360(res, sceneName);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading animations...");

                await loadAnimation(res, sceneName, assetRef);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading items...");

                await loadDroppedItem(res, sceneName, assetRef);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading items...");
                await loadStaticItem(res, sceneName, assetRef);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading runner...");

                await loadEndlessRun(res, sceneName, assetRef);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading detection area...");

                await loadAreaDetections(res, sceneName, assetRef);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading social media buttons...");

                await loadSocialMediaButton(res, sceneName, assetRef);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading social media buttons...");

                await loadActionButton(res, sceneName, assetRef);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading player animations...");

                await loadPlayerAnimation();
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading photo spot...");

                await loadPhotoSpot(res, assetRef);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading quiz...");

                await loadTrivia(res, assetRef);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Loading camera animation...");

                await loadCameraAnimation(res, sceneName, assetRef);
                return res;
            })
            .then(async (res) => {

                setLoading(true, "Loading conversation space...");

                await loadConversationSpace(res, sceneName, assetRef);
                return res;
            })
            .then(async (res) => {
                setLoading(true, "Getting the world ready...");
                setAsset({ staticObject: staticObjectGroups, clcikableObject: clickableObjects, banners: banners, npc: npcs });
                setSceneAssets({ staticObject: staticObjectGroups, clcikableObject: clickableObjects, banners: banners, npc: npcs });
                setIsAllLoaded(true);
                onSceneLoadFinish(sceneName, spawnPoint, refColliders, res.orbitOnly, res.is360, res.isEndlessRun, res.isUseStartCountdown, res.isUseTimer, res.isAnimatedCamera == true, animatedCameraPlayerClip.current, res.meshes, res.materialDatas);
                setScenePanoramics(res.scene360);
                setSceneName(sceneName);
                setLoadedScene(sceneName);
                setLoadSceneFinish(false);
                setDynamicGroundColliders(dynamicGroundCollider.current);

            });
    };

    async function loadTrivia(res, assetRef) {

        if (res.trivia) {
            if (res.trivia.totalQuestion > 0) {
                // console.log(assetRef, res.meshes);
                setTrivia(<Trivia data={res.trivia} sceneName={sceneName} assetRef={assetRef} meshes={res.meshes} />);
            }
        }

    }

    async function loadPlayerAnimation() {
        await initAnimation();
    }

    async function loadPhotoSpot(res, assetRef) {
        if (!res.photoSpot) return;

        let photoSpots = [];
        const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
        await Promise.all(res.photoSpot.map(queue.wrap(async (ps, i) => {

            let spot = new Object3D();

            for (let j = 0; j < ps.child.length; j++) {
                const child = ps.child[j];

                let geometry = loadedGeometry.current.find(lso => lso.name === child.meshid);

                if (geometry === undefined) {
                    geometry = await LoadObject(child.meshid, sceneName, null, assetRef, res.meshes);
                    loadedGeometry.current.push({ name: child.meshid, geometry: geometry })
                } else {
                    geometry = geometry.geometry;
                }


                const bufferGeometry = CloneGeometryAndSetMatrix(geometry, child.position, child.rotation, child.scale);
                bufferGeometry.name = child.name;

                let material = loadedMaterial.current.find(x => x.name === child.material);
                if (material == undefined || material.material == undefined) {
                    let m = await LoadMaterial(child.material, sceneName, null, assetRef);
                    material.material = m.material;
                }

                let mesh = new Mesh(bufferGeometry, material.material);
                mesh.name = child.name;
                mesh.position.set(child.position.x / 10000, child.position.y / 10000, child.position.z / 10000);
                mesh.quaternion.set(child.rotation.x / 10000, child.rotation.y / 10000, child.rotation.z / 10000, child.rotation.w / 10000);
                mesh.scale.set(child.scale.x / 10000, child.scale.y / 10000, child.scale.z / 10000);

                spot.add(mesh);
            }

            photoSpots.push(<PhotoSpot
                obj={spot}
                id={ps.id}
                position={[ps.obj.position.x / 10000, ps.obj.position.y / 10000, ps.obj.position.z / 10000]}
                scale={[ps.obj.scale.x / 10000, ps.obj.scale.y / 10000, ps.obj.scale.z / 10000]} />);
        })))

        setPhotoSpot(photoSpots);
    }

    async function loadCameraAnimation(res, sceneName, assetRef) {

        if (!res.cameraAnimation) return;
        if (res.cameraAnimation.target.name.length == 0) return;

        let animationData = res.cameraAnimation.animation;
        let data = await loadAnimationObject(0, animationData, sceneName, animationData.controllable, assetRef, res.meshes);

        let spawnPoint = {
            position: new Vector3(res.cameraAnimation.spawnPoint.position.x / 100000, res.cameraAnimation.spawnPoint.position.y / 100000, res.cameraAnimation.spawnPoint.position.z / 100000),
            rotation: new Quaternion(res.cameraAnimation.spawnPoint.rotation.x / 100000, res.cameraAnimation.spawnPoint.rotation.y / 100000, res.cameraAnimation.spawnPoint.rotation.z / 100000, res.cameraAnimation.spawnPoint.rotation.w / 100000),
            scale: new Vector3(res.cameraAnimation.spawnPoint.scale.x / 100000, res.cameraAnimation.spawnPoint.scale.y / 100000, res.cameraAnimation.spawnPoint.scale.z / 100000),
            forward: new Vector3(res.cameraAnimation.spawnPoint.forward.x / 100000, res.cameraAnimation.spawnPoint.forward.y / 100000, res.cameraAnimation.spawnPoint.forward.z / 100000)
        }

        let target = res.cameraAnimation.target.name;
        let clipName = res.cameraAnimation.clipName;

        animatedCameraPlayerClip.current = clipName;

        let animatedCamera = <AnimatedCamera
            animation={data}
            spawnPoin={spawnPoint}
            target={target}
            clipName={clipName}
        />;

        setAnimatedCamera(animatedCamera);
    }

    async function loadAreaDetections(res, sceneName, assetRef) {
        if (!res.areaDetections) return;

        let ads = [];
        const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
        await Promise.all(res.areaDetections.map(queue.wrap(async (ad, i) => {

            let geometry = loadedGeometry.current.find(lso => lso.name === ad.collider.meshid);

            if (geometry === undefined) {
                geometry = await LoadObject(ad.collider.meshid, sceneName, null, assetRef, res.meshes);
                loadedGeometry.current.push({ name: ad.collider.meshid, geometry: geometry })
            } else {
                geometry = geometry.geometry;
            }


            const bufferGeometry = CloneGeometryAndSetMatrix(geometry, ad.collider.position, ad.collider.rotation, ad.collider.scale);
            bufferGeometry.name = ad.collider.name;

            ads.push(
                <AreaDetection
                    key={i}
                    geometry={bufferGeometry}
                    area={ad.area}
                />);

            setAreaDetections(ads);
        })))
    }

    async function loadSkyboxTexture(res, sceneName) {

        let skyboxCubemap = new CubeTextureLoader()
            .setPath(`${process.env.RESOURCE_URL}/${sceneName}/cubemap/`)
            .load([
                'px.jpg',
                'nx.jpg',
                'py.jpg',
                'ny.jpg',
                'pz.jpg',
                'nz.jpg'
            ], (e) => {
                // console.log(e);
            }, (e) => {
                // console.log(e);
            }, (e) => {
                // console.log(e);
            });

        setCustomSkybox(skyboxCubemap);
    }

    async function loadEndlessRun(res, sceneName, assetRef) {
        if (!res.endlessRunData) return;

        if (res.endlessRunData.tracks.length == 0) return;

        let tracks = [];

        // const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
        // for (let i = 0; i < res.endlessRunData.tracks.length; i++) {
        //     const trackData = res.endlessRunData.tracks[i];

        // }
        for (let i = 0; i < res.endlessRunData.tracks.length; i++) {

            const trackData = res.endlessRunData.tracks[i];

            let track = new Object3D();


            for (let j = 0; j < trackData.objs.length; j++) {
                let obj = trackData.objs[j];

                let geometry = loadedGeometry.current.find(lso => lso.name === obj.meshid);
                let material = loadedMaterial.current.find(x => x.name === obj.material);

                if (geometry === undefined) {
                    geometry = await LoadObject(obj.meshid, sceneName, null, assetRef, res.meshes);
                    loadedGeometry.current.push({ name: obj.meshid, geometry: geometry })
                } else {
                    geometry = geometry.geometry;
                }

                if (material == undefined || material.material == undefined) {
                    let m = await LoadMaterial(obj.material, sceneName, null, assetRef);
                    material.material = m.material;
                }

                const bufferGeometry = CloneGeometryAndSetMatrix(geometry, obj.position, obj.rotation, obj.scale, true);
                bufferGeometry.name = obj.name;

                let mesh = new Mesh(bufferGeometry, material.material);
                mesh.name = obj.name;
                track.add(mesh);
                mesh.position.set(obj.position.x / 10000, obj.position.y / 10000, obj.position.z / 10000);
                mesh.quaternion.set(obj.rotation.x / 10000, obj.rotation.y / 10000, obj.rotation.z / 10000, obj.rotation.w / 10000);
                mesh.scale.set(obj.scale.x / 10000, obj.scale.y / 10000, obj.scale.z / 10000);
            }

            let splines = [];
            for (let k = 0; k < trackData.spline.length; k++) {
                let splineData = trackData.spline[k];

                let spline = new Object3D();
                spline.position.set(splineData.position.x / 10000, splineData.position.y / 10000, splineData.position.z / 10000);
                spline.quaternion.set(splineData.rotation.x / 10000, splineData.rotation.y / 10000, splineData.rotation.z / 10000, splineData.rotation.w / 10000);
                spline.scale.set(splineData.scale.x / 10000, splineData.scale.y / 10000, splineData.scale.z / 10000);

                splines.push(spline);
            }

            let obstacles = [];
            for (let j = 0; j < res.endlessRunData.tracks[i].obstacle.length; j++) {
                const obstacle = res.endlessRunData.tracks[i].obstacle[j];

                let obstacleObject = new Object3D();

                let geometryCollider = loadedGeometry.current.find(lso => lso.name === obstacle.collider.meshid);

                if (geometryCollider === undefined) {
                    geometryCollider = await LoadObject(obstacle.collider.meshid, sceneName, null, assetRef, res.meshes);
                    loadedGeometry.current.push({ name: obstacle.collider.meshid, geometry: geometryCollider })
                } else {
                    geometryCollider = geometryCollider.geometry;
                }

                const colliderGeometry = CloneGeometryAndSetMatrix(geometryCollider, obstacle.collider.position, obstacle.collider.rotation, obstacle.collider.scale, true);
                colliderGeometry.name = obstacle.collider.name;
                let colliderPostiion = new Vector3(obstacle.collider.position.x / 10000, obstacle.collider.position.y / 10000, obstacle.collider.position.z / 10000);
                let colliderQuaternion = new Quaternion(obstacle.collider.rotation.x / 10000, obstacle.collider.rotation.y / 10000, obstacle.collider.rotation.z / 10000, obstacle.collider.rotation.w / 10000);
                let colliderScale = new Vector3(obstacle.collider.scale.x / 10000, obstacle.collider.scale.y / 10000, obstacle.collider.scale.z / 10000);

                let matrix = new Matrix4().compose(new Vector3(0, 0, 0), new Quaternion(0, 0, 0, 0), colliderScale);
                colliderGeometry.applyMatrix4(matrix);

                for (let k = 0; k < obstacle.objs.length; k++) {
                    const obj = obstacle.objs[k];

                    let geometry = loadedGeometry.current.find(lso => lso.name === obj.meshid);
                    let material = loadedMaterial.current.find(x => x.name === obj.material);

                    if (geometry === undefined) {
                        geometry = await LoadObject(obj.meshid, sceneName, null, assetRef, res.meshes);
                        loadedGeometry.current.push({ name: obj.meshid, geometry: geometry })
                    } else {
                        geometry = geometry.geometry;
                    }

                    if (material == undefined || material.material == undefined) {
                        let m = await LoadMaterial(obj.material, sceneName, null, assetRef);
                        material.material = m.material;
                    }

                    const bufferGeometry = CloneGeometryAndSetMatrix(geometry, obj.position, obj.rotation, obj.scale, true);
                    bufferGeometry.name = obj.name;

                    let mesh = new Mesh(bufferGeometry, material.material);
                    mesh.name = obj.name;
                    obstacleObject.add(mesh);
                    mesh.position.set(obj.position.x / 10000, obj.position.y / 10000, obj.position.z / 10000);
                    mesh.quaternion.set(obj.rotation.x / 10000, obj.rotation.y / 10000, obj.rotation.z / 10000, obj.rotation.w / 10000);
                    mesh.scale.set(obj.scale.x / 10000, obj.scale.y / 10000, obj.scale.z / 10000);
                }

                let clip = null;
                if (obstacle.clip.uuid) AnimationClip.parse(obstacle.clip);

                obstacles.push({
                    object: obstacleObject,
                    collider: colliderGeometry,
                    colliderPostiion: colliderPostiion,
                    colliderQuaternion: colliderQuaternion,
                    colliderScale: colliderScale,
                    clip: clip,
                    position: new Vector3(obstacle.obj.position.x / 10000, obstacle.obj.position.y / 10000, obstacle.obj.position.z / 10000),
                    rotation: new Quaternion(obstacle.obj.rotation.x / 10000, obstacle.obj.rotation.y / 10000, obstacle.obj.rotation.z / 10000, obstacle.obj.rotation.w / 10000),
                    scale: new Vector3(obstacle.obj.scale.x / 10000, obstacle.obj.scale.y / 10000, obstacle.obj.scale.z / 10000),
                });
            }

            let rewards = [];
            for (let j = 0; j < res.endlessRunData.tracks[i].reward.length; j++) {
                const reward = res.endlessRunData.tracks[i].reward[j];

                let rewardObject = new Object3D();

                let geometryCollider = loadedGeometry.current.find(lso => lso.name === reward.collider.meshid);

                if (geometryCollider === undefined) {
                    geometryCollider = await LoadObject(reward.collider.meshid, sceneName, null, assetRef, res.meshes);
                    loadedGeometry.current.push({ name: reward.collider.meshid, geometry: geometryCollider })
                } else {
                    geometryCollider = geometryCollider.geometry;
                }

                const colliderGeometry = CloneGeometryAndSetMatrix(geometryCollider, reward.collider.position, reward.collider.rotation, reward.collider.scale, true);
                colliderGeometry.name = reward.collider.name;
                let colliderPostiion = new Vector3(reward.collider.position.x / 10000, reward.collider.position.y / 10000, reward.collider.position.z / 10000);
                let colliderQuaternion = new Quaternion(reward.collider.rotation.x / 10000, reward.collider.rotation.y / 10000, reward.collider.rotation.z / 10000, reward.collider.rotation.w / 10000);
                let colliderScale = new Vector3(reward.collider.scale.x / 10000, reward.collider.scale.y / 10000, reward.collider.scale.z / 10000);

                let matrix = new Matrix4().compose(new Vector3(0, 0, 0), new Quaternion(0, 0, 0, 0), colliderScale);
                colliderGeometry.applyMatrix4(matrix);

                for (let k = 0; k < reward.objs.length; k++) {
                    const obj = reward.objs[k];

                    let geometry = loadedGeometry.current.find(lso => lso.name === obj.meshid);
                    let material = loadedMaterial.current.find(x => x.name === obj.material);

                    if (geometry === undefined) {
                        geometry = await LoadObject(obj.meshid, sceneName, null, assetRef, res.meshes);
                        loadedGeometry.current.push({ name: obj.meshid, geometry: geometry })
                    } else {
                        geometry = geometry.geometry;
                    }

                    if (material == undefined || material.material == undefined) {
                        let m = await LoadMaterial(obj.material, sceneName, null, assetRef);
                        material.material = m.material;
                    }

                    const bufferGeometry = CloneGeometryAndSetMatrix(geometry, obj.position, obj.rotation, obj.scale, true);
                    bufferGeometry.name = obj.name;

                    let mesh = new Mesh(bufferGeometry, material.material);
                    mesh.name = obj.name;
                    rewardObject.add(mesh);
                    mesh.position.set(obj.position.x / 10000, obj.position.y / 10000, obj.position.z / 10000);
                    mesh.quaternion.set(obj.rotation.x / 10000, obj.rotation.y / 10000, obj.rotation.z / 10000, obj.rotation.w / 10000);
                    mesh.scale.set(obj.scale.x / 10000, obj.scale.y / 10000, obj.scale.z / 10000);
                }

                let clip = null;
                if (reward.clip.uuid) clip = AnimationClip.parse(reward.clip);

                rewards.push({
                    object: rewardObject,
                    collider: colliderGeometry,
                    colliderPostiion: colliderPostiion,
                    colliderQuaternion: colliderQuaternion,
                    colliderScale: colliderScale,
                    clip: clip,
                    position: new Vector3(reward.obj.position.x / 10000, reward.obj.position.y / 10000, reward.obj.position.z / 10000),
                    rotation: new Quaternion(reward.obj.rotation.x / 10000, reward.obj.rotation.y / 10000, reward.obj.rotation.z / 10000, reward.obj.rotation.w / 10000),
                    scale: new Vector3(reward.obj.scale.x / 10000, reward.obj.scale.y / 10000, reward.obj.scale.z / 10000),
                });
            }

            tracks.push({ track: track, splines: splines, obstacles: obstacles, rewards: rewards });
        }

        setEndlessRunTrack(tracks);

        let player = new Object3D();
        // console.log(res.endlessRunData.player);
        const queue2 = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
        await Promise.all(res.endlessRunData.player.objs.map(queue2.wrap(async (obj, i) => {

            let geometry = loadedGeometry.current.find(lso => lso.name === obj.meshid);
            let material = loadedMaterial.current.find(x => x.name === obj.material);

            if (geometry === undefined) {
                geometry = await LoadObject(obj.meshid, sceneName, res.meshes);
                loadedGeometry.current.push({ name: obj.meshid, geometry: geometry })
            } else {
                geometry = geometry.geometry;
            }

            if (material == undefined || material.material == undefined) {
                let m = await LoadMaterial(obj.material, sceneName);
                material.material = m.material;
            }

            const bufferGeometry = CloneGeometryAndSetMatrix(geometry, obj.position, obj.rotation, obj.scale, true);
            bufferGeometry.name = obj.name;

            let mesh = new Mesh(bufferGeometry, material.material);
            mesh.name = obj.name;
            player.add(mesh);
            mesh.position.set(obj.position.x / 10000, obj.position.y / 10000, obj.position.z / 10000);
            mesh.quaternion.set(obj.rotation.x / 10000, obj.rotation.y / 10000, obj.rotation.z / 10000, obj.rotation.w / 10000);
            mesh.scale.set(obj.scale.x / 10000, obj.scale.y / 10000, obj.scale.z / 10000);
        })))

        let playerGeometry = loadedGeometry.current.find(lso => lso.name === res.endlessRunData.player.collider.meshid);

        if (playerGeometry === undefined) {
            playerGeometry = await LoadObject(res.endlessRunData.player.collider.meshid, sceneName, null, res.meshes, res.meshes);
            loadedGeometry.current.push({ name: res.endlessRunData.player.collider.meshid, geometry: playerGeometry })
        } else {
            playerGeometry = playerGeometry.geometry;
        }

        let colliderData = res.endlessRunData.player.collider;
        let playerGeometryMatrix = new Matrix4().compose(
            new Vector3(colliderData.position.x / 10000, colliderData.position.y / 10000, colliderData.position.z / 10000),
            new Quaternion(colliderData.rotation.x / 10000, colliderData.rotation.y / 10000, colliderData.rotation.z / 10000, colliderData.rotation.w / 10000),
            new Vector3(colliderData.scale.x / 10000, colliderData.scale.y / 10000, colliderData.scale.z / 10000),
        );
        playerGeometry.applyMatrix4(playerGeometryMatrix);

        let playerPositions = [];
        for (let i = 0; i < res.endlessRunData.player.playerPositions.length; i++) {
            let playerPositionData = res.endlessRunData.player.playerPositions[i];

            let playerPosition = new Object3D();
            playerPosition.position.set(playerPositionData.position.x / 10000, playerPositionData.position.y / 10000, playerPositionData.position.z / 10000);
            playerPosition.quaternion.set(playerPositionData.rotation.x / 10000, playerPositionData.rotation.y / 10000, playerPositionData.rotation.z / 10000, playerPositionData.rotation.w / 10000);
            playerPosition.scale.set(playerPositionData.scale.x / 10000, playerPositionData.scale.y / 10000, playerPositionData.scale.z / 10000);

            playerPositions.push(playerPosition);
        }
        setEndlessRunPlayer({ object: player, positions: playerPositions, collider: playerGeometry });

        setIsEndlessRun(true);
    }

    async function loadDroppedItem(res, sceneName, assetRef) {

        if (!res.droppedItems) return;

        let items = [];

        const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
        await Promise.all(res.droppedItems.map(queue.wrap(async (item, i) => {

            let animationData = item.clipData;
            let clip = AnimationClip.parse(animationData);

            let geometry = loadedGeometry.current.find(lso => lso.name === item.obj.meshid);
            let material = loadedMaterial.current.find(x => x.name === item.obj.material);

            if (geometry === undefined) {
                geometry = await LoadObject(item.obj.meshid, sceneName, null, assetRef, res.meshes);
                loadedGeometry.current.push({ name: item.obj.meshid, geometry: geometry })
            } else {
                geometry = geometry.geometry;
            }

            if (material == undefined || material.material == undefined) {
                let m = await LoadMaterial(item.obj.material, sceneName, null, assetRef);
                material.material = m.material;
            }

            const bufferGeometry = CloneGeometryAndSetMatrix(geometry, item.obj.position, item.obj.rotation, item.obj.scale, true);
            bufferGeometry.name = item.obj.name;

            let parent = new Object3D();
            parent.position.set(item.parent.position.x / 10000, item.parent.position.y / 10000, item.parent.position.z / 10000);
            parent.quaternion.set(item.parent.rotation.x / 10000, item.parent.rotation.y / 10000, item.parent.rotation.z / 10000, item.parent.rotation.w / 10000);
            parent.scale.set(item.parent.scale.x / 10000, item.parent.scale.y / 10000, item.parent.scale.z / 10000);

            let mesh = new Mesh(bufferGeometry, material.material);
            mesh.name = item.obj.name;
            parent.add(mesh);
            mesh.position.set(item.obj.position.x / 10000, item.obj.position.y / 10000, item.obj.position.z / 10000);
            mesh.quaternion.set(item.obj.rotation.x / 10000, item.obj.rotation.y / 10000, item.obj.rotation.z / 10000, item.obj.rotation.w / 10000);
            mesh.scale.set(item.obj.scale.x / 10000, item.obj.scale.y / 10000, item.obj.scale.z / 10000);

            let detectionGeometry = loadedGeometry.current.find(lso => lso.name === item.collider.meshid);
            let detectionGeometrySource = null;
            if (detectionGeometry === undefined) {
                let geometry = await LoadObject(item.collider.meshid, sceneName, null, assetRef, res.meshes);
                detectionGeometry = { name: item.collider.meshid, geometry: geometry };
                detectionGeometrySource = geometry;
                loadedGeometry.current.push(detectionGeometry)
            } else {
                detectionGeometrySource = geometry.geometry;
            }
            detectionGeometrySource = detectionGeometry.geometry;

            let detectionObjectPosition = new Vector3(item.collider.position.x / 10000, item.collider.position.y / 10000, item.collider.position.z / 10000);
            let detectionObjectQuaternion = new Quaternion(item.collider.rotation.x / 10000, item.collider.rotation.y / 10000, item.collider.rotation.z / 10000, item.collider.rotation.w / 10000);
            let detectionObjectScale = new Vector3(item.collider.scale.x / 10000, item.collider.scale.y / 10000, item.collider.scale.z / 10000);
            let detGeom = CloneGeometryAndSetMatrix(detectionGeometrySource, detectionObjectPosition, detectionObjectQuaternion, detectionObjectScale, true);
            let matrix = new THREE.Matrix4().compose(detectionObjectPosition, detectionObjectQuaternion, detectionObjectScale);
            detGeom.applyMatrix4(matrix);
            items.push(
                <DroppedItem
                    key={i}
                    object={parent}
                    mesh={mesh}
                    clip={clip}
                    id={item.droppedId}
                    total={item.totalDropItem}
                    sceneName={sceneName}
                    detectionGeometry={detGeom}
                />
            );

            addProgress();
        })))

        setDroppedItems(items);
    }

    async function loadStaticItem(res, sceneName, assetRef) {

        if (!res.staticCollectableItem) return;

        let items = [];
        const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
        await Promise.all(res.staticCollectableItem.map(queue.wrap(async (item, i) => {

            let animationData = item.clipData;
            let clip = AnimationClip.parse(animationData);

            let geometry = loadedGeometry.current.find(lso => lso.name === item.obj.meshid);
            let material = loadedMaterial.current.find(x => x.name === item.obj.material);

            if (geometry === undefined) {
                geometry = await LoadObject(item.obj.meshid, sceneName, null, assetRef, res.meshes);
                loadedGeometry.current.push({ name: item.obj.meshid, geometry: geometry })
            } else {
                geometry = geometry.geometry;
            }

            if (material == undefined || material.material == undefined) {
                let m = await LoadMaterial(item.obj.material, sceneName, null, assetRef);
                material.material = m.material;
            }

            const bufferGeometry = CloneGeometryAndSetMatrix(geometry, item.obj.position, item.obj.rotation, item.obj.scale, true);
            bufferGeometry.name = item.obj.name;

            let parent = new Object3D();
            parent.position.set(item.parent.position.x / 10000, item.parent.position.y / 10000, item.parent.position.z / 10000);
            parent.quaternion.set(item.parent.rotation.x / 10000, item.parent.rotation.y / 10000, item.parent.rotation.z / 10000, item.parent.rotation.w / 10000);
            parent.scale.set(item.parent.scale.x / 10000, item.parent.scale.y / 10000, item.parent.scale.z / 10000);

            let mesh = new Mesh(bufferGeometry, material.material);
            mesh.name = item.obj.name;
            parent.add(mesh);
            mesh.position.set(item.obj.position.x / 10000, item.obj.position.y / 10000, item.obj.position.z / 10000);
            mesh.quaternion.set(item.obj.rotation.x / 10000, item.obj.rotation.y / 10000, item.obj.rotation.z / 10000, item.obj.rotation.w / 10000);
            mesh.scale.set(item.obj.scale.x / 10000, item.obj.scale.y / 10000, item.obj.scale.z / 10000);

            let detectionGeometry = loadedGeometry.current.find(lso => lso.name === item.collider.meshid);
            let detectionGeometrySource = null;
            if (detectionGeometry === undefined) {
                let geometry = await LoadObject(item.collider.meshid, sceneName, null, assetRef, res.meshes);
                detectionGeometry = { name: item.collider.meshid, geometry: geometry };
                detectionGeometrySource = geometry;
                loadedGeometry.current.push(detectionGeometry)
            } else {
                detectionGeometrySource = geometry.geometry;
            }
            detectionGeometrySource = detectionGeometry.geometry;

            let detectionObjectPosition = new Vector3(item.collider.position.x / 10000, item.collider.position.y / 10000, item.collider.position.z / 10000);
            let detectionObjectQuaternion = new Quaternion(item.collider.rotation.x / 10000, item.collider.rotation.y / 10000, item.collider.rotation.z / 10000, item.collider.rotation.w / 10000);
            let detectionObjectScale = new Vector3(item.collider.scale.x / 10000, item.collider.scale.y / 10000, item.collider.scale.z / 10000);
            let detGeom = CloneGeometryAndSetMatrix(detectionGeometrySource, detectionObjectPosition, detectionObjectQuaternion, detectionObjectScale, true);
            let matrix = new THREE.Matrix4().compose(detectionObjectPosition, detectionObjectQuaternion, detectionObjectScale);
            detGeom.applyMatrix4(matrix);
            let detectionObjectVertics = detGeom.attributes.position.array;
            let detectionObjectIndices = detGeom.index.array;
            items.push(
                <StaticCollectableItems
                    key={i}
                    object={parent}
                    mesh={mesh}
                    clip={clip}
                    id={item.droppedId}
                    total={item.totalDropItem}
                    detectionGeometry={detGeom}
                    sceneName={sceneName}
                    geometry={detGeom}
                />
            );

            addProgress();
        })))

        setStaticCollectableItems(items);
    }

    async function loadAnimation(res, sceneName, assetRef) {

        if (!res.animations) return;
        let animationObject = [];
        let controllableAnimation = [];

        const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
        await Promise.all(res.animations.map(queue.wrap(async (r, i) => {

            let animationData = res.animations[i];
            let animData = await loadAnimationObject(i, animationData, sceneName, res.animations[i].controllable, assetRef, res.meshes, res.materialDatas);
            animationObject.push(animData.animationObject);
            addProgress();
        })))

        setAnimationObjects(animationObject);
    }

    async function loadAnimationObject(i, animationData, sceneName, controllable, assetRef, meshes, materials) {

        let animationClips = [];
        animationData.clips.forEach(anm => {
            let clip = AnimationClip.parse(anm);
            animationClips.push(clip);
        });

        let objects = [];

        for (let i = 0; i < animationData.objs.length; i++) {
            let obj = animationData.objs[i];

            if (obj.meshid.length > 0) {

                // console.log(obj.name, obj.position, obj.rotation, obj.scale, assetRef, meshes);
                let geometry = await LoadClonedGeometry(loadedGeometry, obj.meshid, obj.position, obj.rotation, obj.scale, sceneName, true, null, assetRef, meshes);
                let material = await LoadMaterial(obj.material, sceneName, null, assetRef, materials);
                // bufferGeometry.name = obj.id;

                const mesh = new Mesh(geometry, material.material);
                if (material.material.map) mesh.offset = material.material.map.offset;
                mesh.name = obj.name;
                objects.push(mesh);
            }
            else {
                let newObject = new Object3D();
                newObject.name = obj.name;
                objects.push(newObject);
            }
        }

        const scaleUnity = 10000;
        let parent = new Group();
        parent.name = animationData.obj.name;
        for (let i = 0; i < objects.length; i++) {
            let obj = animationData.objs[i];

            if (obj.parent == '-1') {
                parent.add(objects[i]);
            }
            else {
                objects[obj.parent].add(objects[i]);
            }

            const positionObj = new THREE.Vector3(obj.position.x / scaleUnity, obj.position.y / scaleUnity, obj.position.z / scaleUnity);
            const rotationObj = new THREE.Quaternion(obj.rotation.x / scaleUnity, obj.rotation.y / scaleUnity, obj.rotation.z / scaleUnity, obj.rotation.w / scaleUnity);
            const scaleObj = new THREE.Vector3(obj.scale.x / scaleUnity, obj.scale.y / scaleUnity, obj.scale.z / scaleUnity);
            objects[i].applyMatrix4(new Matrix4().compose(positionObj, rotationObj, scaleObj));
        }
        const positionObj = new THREE.Vector3(animationData.obj.position.x / scaleUnity, animationData.obj.position.y / scaleUnity, animationData.obj.position.z / scaleUnity);
        const rotationObj = new THREE.Quaternion(animationData.obj.rotation.x / scaleUnity, animationData.obj.rotation.y / scaleUnity, animationData.obj.rotation.z / scaleUnity, animationData.obj.rotation.w / scaleUnity);
        const scaleObj = new THREE.Vector3(animationData.obj.scale.x / scaleUnity, animationData.obj.scale.y / scaleUnity, animationData.obj.scale.z / scaleUnity);
        parent.applyMatrix4(new Matrix4().compose(positionObj, rotationObj, scaleObj));

        return { animationObject: <AnimationObject 
            key={i} 
            clips={animationClips} 
            object={parent} 
            isControllable={controllable} 
            syncedToServer={animationData.syncedToServer}
            clipId={animationData.clipId} 
            npcHoverboard={animationData.npcHoverboardOnClipPlay} 
            loops={animationData.loops} 
            teleportationPosition={animationData.teleportationPosition} 
            forcedAvatarAnimation={animationData.forcedAvatarAnimation}
            npcs={animationData.npcs} 
            playerAssetRef={playerAssetRef}
            networkManager={networkManager}
            />, object: parent, clips: animationClips }
    }

    async function loadScene360(res, sceneName) {
        if (!res.is360) return;

        let scene360Textures = {};

        for (let i = 0; i < res.scene360.length; i++) {
            let panoramicID = res.scene360[i].panoramicID;
            let cubemapDirectory = `${process.env.RESOURCE_URL}/${sceneName}/cubemap/${panoramicID}/`;

            scene360Textures[panoramicID] = new CubeTextureLoader()
                .setPath(cubemapDirectory)
                .load([
                    'px.jpg',
                    'nx.jpg',
                    'py.jpg',
                    'ny.jpg',
                    'pz.jpg',
                    'nz.jpg'
                ], (e) => {
                    // console.log(e);
                }, (e) => {
                    // console.log(e);
                }, (e) => {
                    // console.log(e);
                });

        }

        set360Cubemap(scene360Textures);
    }

    async function loadText(res, sceneName) {
        if (!res.text) return;

        let texts = [];
        let id = 0;
        for (const text of res.text) {
            // console.log(text);
            let rotationData = !text.rotation.z ? {
                rotation: [text.rotation.x / 10000, text.rotation.y / 10000, text.rotation.z / 10000]
            } : {
                quaternion: [text.rotation.x / 10000, text.rotation.y / 10000, text.rotation.z / 10000, text.rotation.w / 10000]
            }

            // console.log(text);
            let textComponent = !text.billboard ?
                <Text
                    key={id}
                    color={"#" + text.color}
                    fontSize={text.size / 10}
                    maxWidth={200}
                    lineHeight={text.lineHeight}
                    letterSpacing={text.letterSpacing}
                    textAlign={text.textAlign}
                    font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
                    anchorX={text.anchorX}
                    anchorY={text.anchorY}
                    outlineWidth={text.outlineWidth / 10}
                    outlineColor={text.outlineColor}
                    position={[text.position.x / 10000, text.position.y / 10000, text.position.z / 10000]}
                    scale={[text.scale.x / 10000, text.scale.y / 10000, text.scale.z / 10000]}
                    {...rotationData}
                >
                    {text.text}
                </Text> :
                <Billboard
                    key={id}
                    follow={true}
                    lockX={text.lockX}
                    lockY={text.lockY}
                    lockZ={text.lockZ}
                    position={[text.position.x / 10000, text.position.y / 10000, text.position.z / 10000]}
                    scale={[text.scale.x / 10000, text.scale.y / 10000, text.scale.z / 10000]}
                    {...rotationData}
                >
                    <Text
                        key={id}
                        color={"#" + text.color}
                        fontSize={text.size / 10}
                        maxWidth={200}
                        lineHeight={text.lineHeight}
                        letterSpacing={text.letterSpacing}
                        textAlign={text.textAlign}
                        font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
                        anchorX={text.anchorX}
                        anchorY={text.anchorY}
                        outlineWidth={text.outlineWidth / 10}
                        outlineColor={text.outlineColor}
                    >
                        {text.text}
                    </Text>
                </Billboard>;

            texts.push(textComponent);
            id++;


            addProgress();
        }

        setTextMesh(texts);
    }

    async function loadNPC(res, sceneName) {
        for (const npc of res.npc) {

            addProgress();

            let n = {
                npcId: npc.id,
                id: npc.name,
                isShowOnServerTrigger: npc.isShowOnServerTrigger ? npc.isShowOnServerTrigger : false,
                animations: npc.animation,
                playerSettings: npc.playerSetting,
                command: npc.command,
                data: npc.data,
                disableDistanceClick: npc.disableDistanceClick,
                position: [npc.obj.position.x / 10000, npc.obj.position.y / 10000, npc.obj.position.z / 10000],
                rotation: [npc.obj.rotation.x / 10000, npc.obj.rotation.y / 10000, npc.obj.rotation.z / 10000],
                scale: [npc.obj.scale.x / 10000, npc.obj.scale.y / 10000, npc.obj.scale.z / 10000],
                targetLookAt: npc.targetLookAt,
                popupMaterial: popupMaterial.current,
                npcPopupMaterial: npcPopupMaterial.current
            }

            if (isMobile && npc.hideInMobile == true) {

            }
            else {
                npcs.push(n);
            }
        }
    }

    async function loadCollider(res, sceneName) {

        const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
        colliders = [];
        await Promise.all(res.colliders.map(queue.wrap(async (r) => {
            let colliderGeometry = loadedGeometry.current.find(lso => lso.name === r.meshid);
            if (colliderGeometry === undefined) {
                colliderGeometry = await LoadObject(r.meshid, sceneName, false, assetRef, res.meshes);
                loadedGeometry.current.push({ name: r.meshid, geometry: colliderGeometry })
            } else {
                colliderGeometry = colliderGeometry.geometry;
            }

            const bufferGeometry = CloneGeometryAndSetMatrix(colliderGeometry, r.position, r.rotation, r.scale);
            bufferGeometry.name = r.name;
            const newColliderMesh = new THREE.Mesh(bufferGeometry, colliderMaterial.current);

            colliders.push(newColliderMesh);


            addProgress();
        })))

        refColliders = colliders;
        setMeshCollider(colliders.map((r, i) => { return <MeshCollider geometries={r.geometry} key={i} /> }));

    }


    async function loadAnimatedPhysics(res, sceneName, assetRef) {

        if (!res.animatedPhysics) return;

        let ap = [];
        let animatedPhysicsCount = res.animatedPhysics.length;

        const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
        await Promise.all(res.animatedPhysics.map(queue.wrap(async (r, i) => {
            const animatedPhysic = res.animatedPhysics[i];

            let collider = animatedPhysic.collider;

            let colliderGeometry = loadedGeometry.current.find(lso => lso.name === collider.meshid);
            if (colliderGeometry === undefined) {
                colliderGeometry = await LoadObject(collider.meshid, sceneName, null, assetRef, res.meshes);
                loadedGeometry.current.push({ name: collider.meshid, geometry: colliderGeometry })
            } else {
                colliderGeometry = colliderGeometry.geometry;
            }

            let colGeom = colliderGeometry.clone();
            let matrix = new Matrix4().compose(
                new Vector3(0, 0, 0),
                new Quaternion(0, 0, 0, 0),
                new Vector3(collider.scale.x / 10000, collider.scale.y / 10000, collider.scale.z / 10000)
            );
            colGeom.applyMatrix4(matrix);

            let animation = await loadAnimationObject(i, animatedPhysic.animationData, sceneName, null, assetRef, res.meshes);

            if (animatedPhysic.isGround) {
                dynamicGroundCollider.current.push(animation.object);
            }

            ap.push(
                <AnimatedPhysics
                    key={i}
                    keyObject={i}
                    data={animatedPhysic}
                    sceneName={sceneName}
                    loadedGeometry={loadedGeometry}
                    object={animatedPhysic.obj}
                    collider={colGeom}
                    animationData={animation}
                />
            );


            addProgress();
        })))

        setAnimatedPhysics(ap);
    }


    async function loadTeleportDetection(res, sceneName, assetRef) {
        if (!res.teleportDetections) return;


        const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
        teleportColliders = [];
        await Promise.all(res.teleportDetections.map(queue.wrap(async (r) => {
            let colliderGeometry = loadedGeometry.current.find(lso => lso.name === r.detectoion.meshid);
            if (colliderGeometry === undefined) {
                colliderGeometry = await LoadObject(r.detectoion.meshid, sceneName, null, assetRef, res.meshes);
                loadedGeometry.current.push({ name: r.detectoion.meshid, geometry: colliderGeometry })
            } else {
                colliderGeometry = colliderGeometry.geometry;
            }

            const bufferGeometry = CloneGeometryAndSetMatrix(colliderGeometry, r.detectoion.position, r.detectoion.rotation, r.detectoion.scale);
            bufferGeometry.name = r.name;
            const newColliderMesh = new THREE.Mesh(bufferGeometry, colliderMaterial.current);

            teleportColliders.push({ geometry: newColliderMesh, data: r });

            addProgress();
        })))
        refTeleportDetection = teleportColliders;
        setTeleportDetection(teleportColliders.map((r, i) => { return <TeleportDetection data={r} key={i} /> }));
    }


    async function loadChangeSceneDetection(res, sceneName, assetRef) {
        if (!res.changeSceneDetections) return;

        const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
        changeSceneColliders = [];
        await Promise.all(res.changeSceneDetections.map(queue.wrap(async (r) => {
            let colliderGeometry = loadedGeometry.current.find(lso => lso.name === r.detectoion.meshid);
            if (colliderGeometry === undefined) {
                colliderGeometry = await LoadObject(r.detectoion.meshid, sceneName, null, assetRef, res.meshes);
                loadedGeometry.current.push({ name: r.detectoion.meshid, geometry: colliderGeometry })
            } else {
                colliderGeometry = colliderGeometry.geometry;
            }

            const bufferGeometry = CloneGeometryAndSetMatrix(colliderGeometry, r.detectoion.position, r.detectoion.rotation, r.detectoion.scale);
            bufferGeometry.name = r.name;
            const newColliderMesh = new THREE.Mesh(bufferGeometry, colliderMaterial.current);

            changeSceneColliders.push({ geometry: newColliderMesh, data: r });

            addProgress();
        })))
        refChangeSceneDetection = changeSceneColliders;
        setChangeSceneDetection(changeSceneColliders.map((r, i) => { return <ChangeSceneDetection data={r} key={i} /> }));
    }

    async function loadMaterial(res, sceneName, assetRef) {
        const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
        let i = 0;
        await Promise.all(
            res.materials.map(queue.wrap(async (r) => {
                if (loadedMaterial.current.find(x => x.name === r) === undefined) {
                    const { material } = await LoadMaterial(r, sceneName, true, assetRef, res.materialDatas);
                    loadedMaterial.current.push({ name: r, material: material.material });

                    addProgress();
                }
            })));
    }

    async function loadStaticObject(res, sceneName, assetRef) {

        const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
        const queue2 = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
        let staticObjectGroup = {};
        for (let i = 0; i < res.staticObject.length; i++) {
            if (staticObjectGroup[res.staticObject[i].material] === undefined) staticObjectGroup[res.staticObject[i].material] = [];
            staticObjectGroup[res.staticObject[i].material].push(res.staticObject[i]);
        }

        let materialNames = Object.keys(staticObjectGroup);
        await Promise.all(materialNames.map(queue.wrap(async (r, i) => {
            let material = loadedMaterial.current.find(x => x.name === materialNames[i]);
            if (material == undefined || material.material == undefined) {
                let m = await LoadMaterial(materialNames[i], sceneName, false, assetRef, res.materialDatas);
                material.material = m.material;
            }

            let geometries = [];

            await Promise.all(staticObjectGroup[materialNames[i]].map(queue2.wrap(async (data) => {
                addProgress();
                // console.log(assetRef);
                const bufferGeometry = await LoadClonedGeometry(loadedGeometry, data.name, data.position, data.rotation, data.scale, sceneName, null, null, assetRef, res.meshes);
                geometries.push(bufferGeometry);
            })))

            let mergedGeometries = mergeBufferGeometries(geometries);
            mergedGeometries.computeBoundsTree();


            staticObjectGroups.push(
                <StaticObject
                    key={"static_" + i}
                    keyObject={"static_" + materialNames[i]}
                    datas={staticObjectGroup[materialNames[i]]}
                    material={material}
                    loadedGeometry={loadedGeometry}
                    sceneName={sceneName}
                    geometry={mergedGeometries}
                />
            );
        })))
    }

    async function loadClickable(res, sceneName, assetRef) {
        const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
        await Promise.all(res.clickableObject.map(queue.wrap(async (r, i) => {
            let material = loadedMaterial.current.find(x => x.name === res.clickableObject[i].obj.material);

            if (!material.material) {
                const getMaterial = await LoadMaterial(material.name, sceneName, true, assetRef, res.materialDatas);
                material = { name: material.nam, material: getMaterial.material };
                let matIdx = loadedMaterial.current.findIndex(x => x.name === material.name);
                loadedMaterial.current[matIdx] = material;
            }

            clickableObjects.push(
                <ClickableObject
                    key={"clickable_" + i}
                    keyObject={"clickable_" + i}
                    data={res.clickableObject[i]}
                    material={material}
                    loadedGeometry={loadedGeometry}
                    onClick={onClick}
                    onPointerEnter={onPointerEnter}
                    onPointerOut={onPointerOut}
                    setToggleAction={clickableActions}
                    sceneName={sceneName}
                    clickableObjects={clickableObjectsRef}
                />
            );


            addProgress();
        })))
    }

    function loadSpawnPoint(res, sceneName) {
        let scaleUnit = 10000;
        spawnPoint.current = [];

        for (let i = 0; i < res.spawnPoint.length; i++) {
            let sp = {
                name: res.spawnPoint[i].name,
                position: new Vector3(res.spawnPoint[i].position.x / scaleUnit, res.spawnPoint[i].position.y / scaleUnit, res.spawnPoint[i].position.z / scaleUnit),
                rotation: new Quaternion(res.spawnPoint[i].rotation.x / scaleUnit, res.spawnPoint[i].rotation.y / scaleUnit, res.spawnPoint[i].rotation.z / scaleUnit, res.spawnPoint[i].rotation.w / scaleUnit),
            };

            if (res.spawnPoint[i].forward) {
                sp.forward = new Vector3(res.spawnPoint[i].forward.x, res.spawnPoint[i].forward.y, res.spawnPoint[i].forward.z);
            }

            spawnPoint.current.push(sp);

            addProgress();
        }
    }

    async function loadBanner(res, sceneName) {
        // console.log("BANNER", res.banners);
        for (let i = 0; i < res.banners.length; i++) {

            let newBanners = null;

            if (res.banners[i].type == 0) {

                let data = res.banners[i];
                let geometry = await LoadClonedGeometry(loadedGeometry, data.obj.meshid, data.obj.position, data.obj.rotation, data.obj.scale, sceneName, false, false, assetRef, res.meshes);
                newBanners = <VideoBanner
                    key={"banner_" + i}
                    keyObject={"banner_" + i}
                    data={data}
                    loadedGeometry={loadedGeometry}
                    sceneName={sceneName}
                    geometry={geometry}
                />
            }
            else {
                newBanners = <HTMLVideo
                    key={"banner_" + i}
                    link={res.banners[i].link}
                    width={res.banners[i].width}
                    height={res.banners[i].height}
                    position={[res.banners[i].obj.position.x / 10000, res.banners[i].obj.position.y / 10000, res.banners[i].obj.position.z / 10000]}
                    direction={[res.banners[i].obj.forward.x / 10000, res.banners[i].obj.forward.y / 10000, res.banners[i].obj.forward.z / 10000]}
                />
            }

            banners.push(newBanners);

            addProgress();
        }
    }

    async function loadPopup(res, sceneName, assetRef) {
        if (!res.popups) return;

        ;
        let popup = [];

        const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
        await Promise.all(res.popups.map(queue.wrap(async (r, i) => {

            let detectionGeometry = null;

            if (res.popups[i].detection.meshid.length > 0) {
                let detectionGeometrySource = await LoadObject(res.popups[i].detection.meshid, sceneName, false, assetRef, res.meshes);


                let detectionObjectPosition = new Vector3(res.popups[i].detection.position.x / 10000, res.popups[i].detection.position.y / 10000, res.popups[i].detection.position.z / 10000);
                let detectionObjectQuaternion = new Quaternion(res.popups[i].detection.rotation.x / 10000, res.popups[i].detection.rotation.y / 10000, res.popups[i].detection.rotation.z / 10000, res.popups[i].detection.rotation.w / 10000);
                let detectionObjectScale = new Vector3(res.popups[i].detection.scale.x / 10000, res.popups[i].detection.scale.y / 10000, res.popups[i].detection.scale.z / 10000);

                detectionGeometry = CloneGeometryAndSetMatrix(detectionGeometrySource, detectionObjectPosition, detectionObjectQuaternion, detectionObjectScale, true);

                let matrix = new THREE.Matrix4().compose(detectionObjectPosition, detectionObjectQuaternion, detectionObjectScale);
                detectionGeometry.applyMatrix4(matrix);

            }

            // console.log(res.popups[i]);
            let material = loadedMaterial.current.find(x => x.name === res.popups[i].obj.material);
            if (!material.material) {
                material = await LoadMaterial(res.popups[i].obj.material, sceneName, true, assetRef, res.materialDatas);
            }

            let geometry = await LoadClonedGeometry(loadedGeometry, res.popups[i].obj.meshid, res.popups[i].obj.position, res.popups[i].obj.rotation, res.popups[i].obj.scale, sceneName, true, true, assetRef, res.meshes);
            let geometryArtifact = null;
            let materialArtifact = null;
            if (res.popups[i].artifact.meshid.length > 0) {
                materialArtifact = loadedMaterial.current.find(x => x.name === res.popups[i].artifact.material)

                if (!materialArtifact.material) {
                    materialArtifact = await LoadMaterial(res.popups[i].artifact.material, sceneName, true, assetRef, res.materialDatas);
                }
            }

            if (res.popups[i].artifact.name.length > 0) {
                geometryArtifact = await LoadClonedGeometry(loadedGeometry, res.popups[i].artifact.meshid, res.popups[i].artifact.position, res.popups[i].artifact.rotation, res.popups[i].artifact.scale, sceneName, true, true, assetRef, res.meshes);
            }

            popup.push(
                <PopupObject
                    key={i}
                    material={material}
                    popupMaterial={popupMaterial.current}
                    materialArtifact={materialArtifact}
                    loadedGeometry={loadedGeometry}
                    sceneName={sceneName}
                    object={res.popups[i].obj}
                    artifact={res.popups[i].artifact}
                    popupID={res.popups[i].popupID}
                    keyObject={i}
                    visible={!(res.popups[i].visible == false)}
                    popupReadedMaterial={popupReadedMaterial.current}
                    geometry={geometry}
                    detectionGeometry={detectionGeometry}
                    geometryArtifact={geometryArtifact}

                />
            );


            addProgress();
        })))

        setPopups(popup);
    }

    async function loadSocialMediaButton(res, sceneName, assetRef) {

        if (!res.socialMediaButtons) return;


        let buttons = [];
        const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
        await Promise.all(res.socialMediaButtons.map(queue.wrap(async (ad, i) => {
            
            
            let detectionGeometry = null;

            if (res.socialMediaButtons[i].detection.meshid.length > 0) {
                let detectionGeometrySource = await LoadObject(res.socialMediaButtons[i].detection.meshid, sceneName, null, assetRef, res.meshes);


                let detectionObjectPosition = new Vector3(res.socialMediaButtons[i].detection.position.x / 10000, res.socialMediaButtons[i].detection.position.y / 10000, res.socialMediaButtons[i].detection.position.z / 10000);
                let detectionObjectQuaternion = new Quaternion(res.socialMediaButtons[i].detection.rotation.x / 10000, res.socialMediaButtons[i].detection.rotation.y / 10000, res.socialMediaButtons[i].detection.rotation.z / 10000, res.socialMediaButtons[i].detection.rotation.w / 10000);
                let detectionObjectScale = new Vector3(res.socialMediaButtons[i].detection.scale.x / 10000, res.socialMediaButtons[i].detection.scale.y / 10000, res.socialMediaButtons[i].detection.scale.z / 10000);

                detectionGeometry = CloneGeometryAndSetMatrix(detectionGeometrySource, detectionObjectPosition, detectionObjectQuaternion, detectionObjectScale, true);

                let matrix = new THREE.Matrix4().compose(detectionObjectPosition, detectionObjectQuaternion, detectionObjectScale);
                detectionGeometry.applyMatrix4(matrix);
            }

            let material = loadedMaterial.current.find(x => x.name === res.socialMediaButtons[i].obj.material);
            if (!material.material) {
                material = await LoadMaterial(res.socialMediaButtons[i].obj.material, sceneName, null, assetRef, res.materialDatas);
            }

            let materialArtifact = null;
            if (res.socialMediaButtons[i].artifact.meshid.length > 0) {
                materialArtifact = loadedMaterial.current.find(x => x.name === res.socialMediaButtons[i].artifact.material)

                if (!materialArtifact.material) {
                    materialArtifact = await LoadMaterial(res.socialMediaButtons[i].artifact.material, sceneName, null, assetRef, res.materialDatas);
                }
            }

            let geometry = await LoadClonedGeometry(loadedGeometry, res.socialMediaButtons[i].obj.meshid, res.socialMediaButtons[i].obj.position, res.socialMediaButtons[i].obj.rotation, res.socialMediaButtons[i].obj.scale, sceneName, true, true, assetRef, res.meshes);
            let geometryArtifact = null;
            if (res.socialMediaButtons[i].artifact) {
                if (res.socialMediaButtons[i].artifact.length > 0) {
                    geometryArtifact = await LoadClonedGeometry(loadedGeometry, res.socialMediaButtons[i].artifact.meshid, res.socialMediaButtons[i].artifact.position, res.socialMediaButtons[i].artifact.rotation, res.socialMediaButtons[i].artifact.scale, sceneName, true, true, assetRef, res.meshes);
                }
            }

            buttons.push(
                <SocialMediaButton
                    key={i}
                    geometry={geometry}
                    geometryArtifact={geometryArtifact}
                    material={material}
                    popupMaterial={popupMaterial.current}
                    materialArtifact={materialArtifact}
                    loadedGeometry={loadedGeometry}
                    sceneName={sceneName}
                    object={res.socialMediaButtons[i].obj}
                    artifact={res.socialMediaButtons[i].artifact}
                    id={res.socialMediaButtons[i].id}
                    keyObject={i}
                    visible={!(true)}
                    detectionGeometry={detectionGeometry}
                    popupColor={res.socialMediaButtons[i].clickMeBackgroundColor}
                    text={res.socialMediaButtons[i].clickMeText}
                    textColor={res.socialMediaButtons[i].clickMeTextColor}
                    clickMePosition={res.socialMediaButtons[i].clickMePosition}
                    textReadedColor={res.socialMediaButtons[i].clickMeTextColorReaded}
                    data={{
                        id: res.socialMediaButtons[i].id,
                        name: res.socialMediaButtons[i].name,
                        mainLink: res.socialMediaButtons[i].mainLink,
                        socialLinks: res.socialMediaButtons[i].socialLinks,
                        photos: res.socialMediaButtons[i].photo,
                        sceneName: sceneName
                    }}
                />
            );


            addProgress();
        })))

        setSocialMediaButtons(buttons);
    }

    async function loadActionButton(res, sceneName, assetRef) {

        if (!res.actionButtons) return;


        let buttons = [];
        const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
        await Promise.all(res.actionButtons.map(queue.wrap(async (ad, i) => {

            let detectionGeometry = null;

            if (res.actionButtons[i].detection.meshid.length > 0) {
                let detectionGeometrySource = await LoadObject(res.actionButtons[i].detection.meshid, sceneName, null, assetRef, res.meshes);


                let detectionObjectPosition = new Vector3(res.actionButtons[i].detection.position.x / 10000, res.actionButtons[i].detection.position.y / 10000, res.actionButtons[i].detection.position.z / 10000);
                let detectionObjectQuaternion = new Quaternion(res.actionButtons[i].detection.rotation.x / 10000, res.actionButtons[i].detection.rotation.y / 10000, res.actionButtons[i].detection.rotation.z / 10000, res.actionButtons[i].detection.rotation.w / 10000);
                let detectionObjectScale = new Vector3(res.actionButtons[i].detection.scale.x / 10000, res.actionButtons[i].detection.scale.y / 10000, res.actionButtons[i].detection.scale.z / 10000);

                detectionGeometry = CloneGeometryAndSetMatrix(detectionGeometrySource, detectionObjectPosition, detectionObjectQuaternion, detectionObjectScale, true);

                let matrix = new THREE.Matrix4().compose(detectionObjectPosition, detectionObjectQuaternion, detectionObjectScale);
                detectionGeometry.applyMatrix4(matrix);
            }

            let material = loadedMaterial.current.find(x => x.name === res.actionButtons[i].obj.material);
            if (!material.material) {
                material = await LoadMaterial(res.actionButtons[i].obj.material, sceneName, null, assetRef, res.materialDatas);
            }

            let materialArtifact = null;
            if (res.actionButtons[i].artifact.meshid.length > 0) {
                materialArtifact = loadedMaterial.current.find(x => x.name === res.actionButtons[i].artifact.material)

                if (!materialArtifact.material) {
                    materialArtifact = await LoadMaterial(res.actionButtons[i].artifact.material, sceneName, null, assetRef, res.materialDatas);
                }
            }

            let geometry = await LoadClonedGeometry(loadedGeometry, res.actionButtons[i].obj.meshid, res.actionButtons[i].obj.position, res.actionButtons[i].obj.rotation, res.actionButtons[i].obj.scale, sceneName, true, true, assetRef, res.meshes);
            let geometryArtifact = null;
            if (res.actionButtons[i].artifact) {
                if (res.actionButtons[i].artifact.length > 0) {
                    geometryArtifact = await LoadClonedGeometry(loadedGeometry, res.actionButtons[i].artifact.meshid, res.actionButtons[i].artifact.position, res.actionButtons[i].artifact.rotation, res.actionButtons[i].artifact.scale, sceneName, true, true, assetRef, res.meshes);
                }
            }

            buttons.push(
                <ActionButton
                    key={i}
                    geometry={geometry}
                    geometryArtifact={geometryArtifact}
                    material={material}
                    popupMaterial={popupMaterial.current}
                    materialArtifact={materialArtifact}
                    loadedGeometry={loadedGeometry}
                    sceneName={sceneName}
                    object={res.actionButtons[i].obj}
                    artifact={res.actionButtons[i].artifact}
                    id={res.actionButtons[i].id}
                    keyObject={i}
                    visible={!(true)}
                    detectionGeometry={detectionGeometry}
                    popupColor={res.actionButtons[i].clickMeBackgroundColor}
                    text={res.actionButtons[i].clickMeText}
                    textColor={res.actionButtons[i].clickMeTextColor}
                    clickMePosition={res.actionButtons[i].clickMePosition}
                    textReadedColor={res.actionButtons[i].clickMeTextColorReaded}
                    data={{
                        id: res.actionButtons[i].id,
                        name: res.actionButtons[i].name,
                        parameters: res.actionButtons[i].parameters
                    }}
                />
            );


            addProgress();
        })))

        setActionButtons(buttons);
    }

    const loadConversationSpace = async (res, sceneName, assetRef) => {

        if (!res.conversationSpaces) return

        const convSpace = [];

        for (let i = 0; i < res.conversationSpaces.length; i++) {

            let detectionGeometry = null;

            if (res.conversationSpaces[i].detection.meshid.length > 0) {
                let detectionGeometrySource = await LoadObject(res.conversationSpaces[i].detection.meshid, sceneName, null, assetRef, res.meshes);


                let detectionObjectPosition = new Vector3(res.conversationSpaces[i].detection.position.x / 10000, res.conversationSpaces[i].detection.position.y / 10000, res.conversationSpaces[i].detection.position.z / 10000);
                let detectionObjectQuaternion = new Quaternion(res.conversationSpaces[i].detection.rotation.x / 10000, res.conversationSpaces[i].detection.rotation.y / 10000, res.conversationSpaces[i].detection.rotation.z / 10000, res.conversationSpaces[i].detection.rotation.w / 10000);
                let detectionObjectScale = new Vector3(res.conversationSpaces[i].detection.scale.x / 10000, res.conversationSpaces[i].detection.scale.y / 10000, res.conversationSpaces[i].detection.scale.z / 10000);

                detectionGeometry = CloneGeometryAndSetMatrix(detectionGeometrySource, detectionObjectPosition, detectionObjectQuaternion, detectionObjectScale, true);

                let matrix = new THREE.Matrix4().compose(detectionObjectPosition, detectionObjectQuaternion, detectionObjectScale);
                detectionGeometry.applyMatrix4(matrix);
            }

            let newConversationSpace = <ConversationSpace
                key={i}
                detectionGeometry={detectionGeometry}
                theme={res.conversationSpaces[i].theme}
            />
            convSpace.push(newConversationSpace);
        }

        setConversationSpaces(convSpace);
    }


    // if (name !== sceneName) {
    //     // console.log('CHANGE SCENE', name)
    //     setAsset({ staticObject: [], clcikableObject: [] });
    //     setIsAllLoaded(false);
    //     setSceneName(name);
    //     LoadData(name);
    // }
    // else {
    //     // console.log('SCENE UPDATE', sceneName)
    // }

    useEffect(() => {

        // LoadData(sceneName);

        const clickableObjectSubs = GamePlayerContext.subscribe((state) => state.seatTaken, (seatTaken) => {
            let keys = Object.keys(clickableObjectsRef.current);

            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                clickableObjectsRef.current[key].current.visible = !seatTaken.includes(key);
            }
        });

        return () => {
            clickableObjectSubs();
        };
    }, []);

    return meshCollider !== undefined && (
        <group
            ref={sceneRef}
            dispose={null}
        >
            {photoSpot}
            {actionButtons}
            {socialMediaButtons}
            {areaDetections}
            {trivia}
            {animatedPhysics}
            {popups}
            {meshCollider}
            {textMesh}
            {teleportDetection}
            {changeSceneDetection}
            {asset.staticObject}
            {asset.clcikableObject}
            {asset.banners}
            {animationObjects}
            {droppedItems}
            {staticCollectableItems}
            {isAllLoaded && children}
            {animatedCamera}
            {conversationSpaces}
        </group>
    )
});

export default Scene;