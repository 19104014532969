import * as THREE from 'three';
import { useFrame, useThree } from "@react-three/fiber";
import { PlayerName } from "./player-component/player-name";
import React, { Suspense, useContext, useEffect, useRef, useState } from 'react';
import { Vector3 } from 'three';
import { PlayerShoutout } from './player-component/player-shoutout';
import { PlayerControl } from './player-component/context/player-control';
import { HoverBoard } from './player-component/context/hoverboard-movement';
import { PlayerSpeaker } from './player-component/player-speaker';
import { FunctionVariableContext, GameConfigContext, GamePlayerContext, JoystickContext, SceneContext } from '../context/game-context';
import { PlayerAnimation, PlayerModelAndAnimation } from './player-model-animation';
import { CapsuleCollider, RigidBody, useAfterPhysicsStep } from '@react-three/rapier';
import { Html, Sphere } from '@react-three/drei';

const CharacterMovement = ({ setHoverBoard, sceneName, isHoverBoard, ...props }) => {


    const setIsHoverboard = GamePlayerContext((state) => state.setIsHoverboard)
    const [hoverBoard, setHoverBoardToggle] = useState(false);
    const [lastSceneName, setLastSceneName] = useState(sceneName);

    const setHover = (condition) => {
        setHoverBoardToggle(condition);
        setIsHoverboard(condition);
        isHoverBoard.current = condition;
        // console.log(condition);
    }

    if (setHoverBoard !== undefined) {
        setHoverBoard.current = setHover;
    }

    if (sceneName !== lastSceneName) {
        setLastSceneName(sceneName);
    }

    const position = JoystickContext((state) => state.position);
    const playerAnalogInput = useRef(position);
    const jump = JoystickContext((state) => state.jump);
    const down = JoystickContext((state) => state.down);
    const jumpInput = useRef(jump);
    const downInput = useRef(down);


    useEffect(() => {

        const joystickSub = JoystickContext.subscribe(state => state.position, (data) => {
            playerAnalogInput.current = data;
        })

        const jumpSub = JoystickContext.subscribe(state => state.jump, (data) => {
            jumpInput.current = data;
        })

        const downSub = JoystickContext.subscribe(state => state.down, (data) => {
            downInput.current = data;
        })
        return () => {
            joystickSub();
            jumpSub();
            downSub();
        }
    }, [])



    return (
        <group>
            {
                // console.log("RENDER CONTROLL")
            }
            {
                hoverBoard && <HoverBoard sceneName={sceneName} {...props} analogInput={playerAnalogInput} jumpInput={jumpInput} downInput={downInput}></HoverBoard>
            }
            {
                !hoverBoard && <PlayerControl sceneName={sceneName} {...props} analogInput={playerAnalogInput} jumpInput={jumpInput} downInput={downInput}></PlayerControl>
            }
        </group>
        // <ControllerState hoverBoard={hoverBoard} {...props}/>
    );
}

// const ControllerState = ({hoverBoard, ...props}) => {

//     console.log(props);

//     return (
//         hoverBoard ? <HoverBoard {...props}></HoverBoard> : <PlayerControl {...props}></PlayerControl>
//     );
// }

export default function Player({
    localPlayer, localPlayerPosition,
    isLocal, position, rotation, forward,
    name,
    skin, base, skinColor, hairColor, head, eyebrow, eyes, mouth, upperBody, feet, hair, lowerBody, hat, helmet, topHead, mask, tiara, earing, facialHair,
    playerId, colliders, orbitControl, setCameraTarget,
    onSelectEmoji, setMessage, setHoverBoard, sceneName, setPlayerPosition, onPlayerSit,
    audioId, assetRef, onLocalPlayerFinishLoad, refPlayerPosition
}) {
    const refPlayerModel = useRef();
    const animationClips = useRef();
    const groupRef = useRef();
    const onCharacterCollideBegin = useRef();
    const onCharacterCollideStop = useRef();
    const onRayHit = useRef();
    const setPlayerMessage = useRef();
    const playerBase = useRef();
    const playerSyncSpeed = 25;
    const playerHoverBoardSyncSpeed = 25;
    const apiPosition = useRef(new Vector3(0, 0, 0));
    const isHoverBoard = useRef(false);
    const isFPS = useRef(GamePlayerContext((state) => state.isFirstPersonCamera));
    const onFBXUpdated = useRef();
    const setLocalPlayerObject = GamePlayerContext((state) => state.setLocalPlayerObject);
    const localPlayerLastPosition = GamePlayerContext((state) => state.localPlayerLastPosition);
    const [isTeleporting, setIsTeleporting] = useState();
    const [npcSelected, setNpcSelected] = useState(SceneContext(state => state.npcSelected));
    const setLoading = GameConfigContext((state) => state.setLoading);
    const [showPlayerName, setShowPlayerName] = useState(true);
    const setFirstAngle = GamePlayerContext(state => state.setFirstAngle);

    const ref = useRef();
    const playerReadyCount = useRef(0);
    const playerReady = useRef(false);
    // console.log("LOCALLASTPOSITION", localPlayerLastPosition);

    if (!isLocal) setMessage = setPlayerMessage;

    const [isLoading, setIsLoading] = useState(GameConfigContext((state) => state.loading));
    const cameraDistance = useRef(0);

    // console.log("PlayerSettings", name, gender, body, shoes, hair, pant, props);

    useEffect(() => {

        let npcSubs = SceneContext.subscribe(state => state.npcSelected, data => {
            setNpcSelected(data);

            if (!isFPS.current) playerBase.current.visible = (data == null);
        });

        let firstPersonSubs = GamePlayerContext.subscribe((state) => state.isFirstPersonCamera, (data) => {
            isFPS.current = data;
        });

        let loadingSubs = GameConfigContext.subscribe((state) => state.loading, (data) => {
            setIsLoading(data);
        });

        let loadSceneFinishSubs = GameConfigContext.subscribe((state) => state.loadSceneFinish, (data) => {
            loadSceneFinishSubs.current = data;
        })

        const listPlayerSub = GamePlayerContext.subscribe(state => state.listPlayer, (data) => {
            // console.log("FINISH LOAD?");
        });

        let sceneSubs = SceneContext.subscribe(state => state.scene, (data) => {
            // console.log(data);
        });

        let cameraDistanceSubs = GamePlayerContext.subscribe(state => state.cameraDistance, (data) => {
            // console.log(data);
            cameraDistance.current = data;
            setShowPlayerName(data > 0.4);
        });

        return () => {
            sceneSubs();
            npcSubs();
            firstPersonSubs();
            loadingSubs();
            listPlayerSub();
            cameraDistanceSubs();
        };
    }, []);

    onFBXUpdated.current = (fbx) => {
        window.playerObject = fbx;
    }

    let radius = 0.3;

    if (localPlayerLastPosition[0] != 0 && localPlayerLastPosition[1] != 0 && localPlayerLastPosition[2] != 0) {
        position[0] = localPlayerLastPosition[0];
        position[1] = localPlayerLastPosition[1];
        position[2] = localPlayerLastPosition[2];
    }

    const setOnTeleportDetection = FunctionVariableContext((state) => state.setOnTeleportDetection);

    const forcePosition = (position, i, forward) => {

        if (setPlayerPosition.current) setPlayerPosition.current(position[0], position[1], position[2]);
        i--;

        if (forward) {
            const currPos = new Vector3(position[0], position[1], position[2]);
            const forwardPos = new Vector3(forward[0], forward[1], forward[2]);
            forwardPos.add(currPos);

            let angle = Math.atan2((forwardPos.x - currPos.x), (forwardPos.z - currPos.z));
            setFirstAngle(angle);

            if (refPlayerModel.current) refPlayerModel.current.rotation.set(0, angle, 0, 'XYZ');

        }

        if (i > 0) {
            setTimeout(() => {
                forcePosition(position, i, forward);
            }, 300);
        }
        else {
            setIsTeleporting(false);
        }
    }

    useEffect(() => {
        setOnTeleportDetection(function (data, collider, force, forward) {

            setIsTeleporting(true);
            const position = [data.position.x / 10000, data.position.y / 10000, data.position.z / 10000];
            const forwardFace = forward ? [forward.forward.x / 10000, forward.forward.y / 10000, forward.forward.z / 10000] : null;

            forcePosition(position, 2, forwardFace);
        });
    }, []);


    setPlayerPosition.current = (x, y, z) => {
        if (ref.current) {
            ref.current.setTranslation({
                x: x,
                y: y + (radius * 2) + 0.5,
                z: z
            });
            ref.current.setLinvel({
                x: 0,
                y: 0,
                z: 0
            });
        }
    }

    useEffect(() => {

        if (isLocal) {
            window.localPlayer = playerBase;
        }

    }, []);

    const { camera } = useThree();

    useEffect(() => {
        window.playerModel = refPlayerModel;

        if (forward.current) {
            const currPos = new Vector3(position[0], position[1], position[2]);
            const forwardPos = new Vector3(forward.current[0], forward.current[1], forward.current[2]);
            currPos.add(forwardPos);
            refPlayerPosition.current = currPos;

            let angle = Math.atan2((forwardPos.x - currPos.x), (forwardPos.z - currPos.z));
            setFirstAngle(angle);

            if (refPlayerModel.current) refPlayerModel.current.rotation.set(0, angle, 0, 'XYZ');
        }

    }, [refPlayerModel.current, forward.current]);

    useEffect(() => {
        let firstPosition = new Vector3(position[0], position[1], position[2]);
        ref.current.setTranslation(firstPosition);
    }, []);

    useEffect(() => {
        let npcSelectedSubs = SceneContext.subscribe(state => state.npcSelected, (data) => {
            refPlayerModel.current.visible = data == null || cameraDistance.current < 1;
        });

        return () => {
            npcSelectedSubs();
        }
    }, []);

    useAfterPhysicsStep(() => {
        let position = ref.current.translation();
        playerBase.current.position.set(position.x, position.y, position.z);
    });

    return (
        <>

            <RigidBody
                ref={ref}
                colliders={false}
                lockRotations
                gravityScale={0}
            // type="kinematicPosition"
            // restitution={1}
            >
                <CapsuleCollider position={[0, 0.5, 0]} args={[0.5, 0.2]} />
            </RigidBody>
            <group ref={playerBase}>
                {/* <Sphere scale={0.3} /> */}
                {/* <group ref={isLocal ? ref : groupRef}></group> */}
                {/* <Suspense fallback={null}> */}


                <RigidBody>

                </RigidBody>
                <PlayerModelAndAnimation
                    assetRef={assetRef}
                    playerModel={refPlayerModel}
                    playerAction={animationClips}
                    skin={skin}
                    base={base}
                    skinColor={skinColor}
                    hairColor={hairColor}
                    head={head}
                    eyebrow={eyebrow}
                    eyes={eyes}
                    mouth={mouth}
                    hair={hair}
                    upperBody={upperBody}
                    lowerBody={lowerBody}
                    feet={feet}
                    playerId={playerId}
                    colliderRadius={radius}
                    onFBXUpdated={onFBXUpdated}
                    isLocal={true}

                    hat={hat}
                    helmet={helmet}
                    topHead={topHead}
                    mask={mask}
                    tiara={tiara}
                    earing={earing}
                    facialHair={facialHair}
                    onLocalPlayerFinishLoad={onLocalPlayerFinishLoad}
                >
                    <PlayerSpeaker
                        playerModel={refPlayerModel}
                        name={`speaker-${audioId}`}
                        opacity={1}
                        position={[0, 2, 0]}
                        isFPS={isFPS}
                    />
                    {showPlayerName && <PlayerName
                        playerModel={refPlayerModel}
                        name={name}
                        opacity={1}
                        position={[0, 1.37, 0]}
                        isFPS={isFPS}
                        isLocal={true}
                        id={playerId}
                    >
                        <PlayerShoutout
                            playerModel={refPlayerModel}
                            position={[0, 1.8, 0]}
                            duration={5}
                            setMessage={setMessage}
                            isFPS={isFPS}
                        />
                    </PlayerName>}
                </PlayerModelAndAnimation>
                {/* </Suspense> */}
                {
                    !npcSelected && !isTeleporting && refPlayerModel.current && colliders && <CharacterMovement
                        key={"CharacterMovement"}
                        colliderRadius={radius}
                        colliders={colliders}
                        onRayHit={onRayHit}
                        onCharacterCollideBegin={onCharacterCollideBegin}
                        onCharacterCollideStop={onCharacterCollideStop}
                        isLocal={isLocal}
                        refPlayerModel={refPlayerModel}
                        // api={isLocal ? api : groupRef}
                        orbitControl={orbitControl}
                        setCameraTarget={setCameraTarget}
                        playerObject={ref}
                        playerId={playerId}
                        animationClip={animationClips}
                        onSelectEmoji={onSelectEmoji}
                        setMessage={setMessage}
                        setHoverBoard={setHoverBoard}
                        sceneName={sceneName}
                        onPlayerSit={onPlayerSit}
                        isHoverBoard={isHoverBoard}
                        isFPS={isFPS}
                    />
                }
            </group>
        </>
    )
}
