import { useTrimesh } from "@react-three/cannon";
import { RigidBody } from "@react-three/rapier";
import { useEffect } from "react"
import { SceneContext } from "../../context/game-context";

export const AreaDetection = ({ geometry, area }) => {

    const setArea = SceneContext(state => state.setArea);


    useEffect(() => {
        return () => {
            geometry.dispose();
        }
    }, []);

    const onCollideBegin = (collider) => {
        setArea(area);
    }

    const onCollideEnd = (collider) => {
        setArea(null);
    }

    return <RigidBody
        colliders={"trimesh"}
        type={"kinematicPosition"}
        sensor
        onIntersectionEnter={() => {
            onCollideBegin();
        }}
        onIntersectionExit={() => {
            onCollideEnd();
        }}
        includeInvisible
    >
        <mesh geometry={geometry} visible={false}></mesh>
    </RigidBody>
}