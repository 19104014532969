
import { useThree } from '@react-three/fiber';
import React, { useEffect, useState } from "react";

export default function AudioControls() {
  const keys = {
    KeyV: 'openMic',
  }

  const moveFieldByKey = (key) => keys[key]

  const [audioControls, setAudioControls] = useState({
    openMic: false,
  })

  let audioControlsKey = {
    openMic: false,
  }

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (moveFieldByKey(e.code) && audioControlsKey[moveFieldByKey(e.code)] == false) {
        audioControlsKey[moveFieldByKey(e.code)] = true;
        // console.log(movement, movementKey, "WIK WIK");
        setAudioControls((m) => ({ ...m, [moveFieldByKey(e.code)]: true }))
      }
    }
    const handleKeyUp = (e) => {
      if (moveFieldByKey(e.code) && audioControlsKey[moveFieldByKey(e.code)] == true) {
        audioControlsKey[moveFieldByKey(e.code)] = false;
        // console.log(moveFieldByKey(e.code), movement[moveFieldByKey(e.code)], movement['forward'], "WOK WOK");
        setAudioControls((m) => ({ ...m, [moveFieldByKey(e.code)]: false }))
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [])
  return audioControls
}