import create from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { UtilTools } from "../utils/UtilTool";
import axios from "axios";
import { FBXLoader } from '../utils/loader/FBXLoader';
import { Clip, Gltf2 } from 'ossos';
import { VectorKeyframeTrack } from 'three';
import CircularJSON from 'circular-json';
import { get } from 'jquery';

const SceneContext = create(subscribeWithSelector((set, get) => ({
    scene: 'stesstest',
    lastScene: null,
    sceneAssets: {},
    clickableObjects: {},
    time: 0.5,
    autoTime: true,
    orbitOnly: false,
    mainScene: "",
    is360: false,
    scenePanoramic: null,
    spawnPoint: null,
    minimap: null,
    cubemap360: null,
    npcSelected: null,
    changeSceneCounter: null,
    changeSceneName: null,
    endlessRunTrack: null,
    endlessRunPlayer: null,
    isEndlesRun: false,
    isCustomSkybox: null,
    customSkybox: null,
    dynamicGroundColliders: [],
    clickable: null,
    loadProgress: 100,
    muteAmbientSound: true,
    ambientSound: "",
    area: "",
    instructions: [],
    photoIframe: "",
    endlessRunState: "",
    endlessRunScore: 0,
    endlessRunSeat: 0,
    droppedNPC: null,
    animatedCamera: null,
    setAnimatedCamera: (data) => {
        set({ animatedCamera: data });
    },
    setDroppedNPC: (data) => {
        set({ droppedNPC: data });
    },
    setEndlessRunScore: (data) => {
        set({ endlessRunScore: data });
    },
    setEndlessRunSeat: (data) => {
        set({ endlessRunSeat: data });
    },
    setEndlessRunState: (data) => {
        set({ endlessRunState: data });
    },
    setIsEndlessRun: (data) => {
        set({ isEndlesRun: data });
    },
    setPhotoIframe: (data) => {
        set({ photoIframe: data });
    },
    setInstruction: (data) => {
        set({ instructions: data });
    },
    setArea: (data) => {
        set({ area: data });
    },
    setAmbientSound: (data) => {
        set({ ambientSound: data });
    },
    toggleAmbientSound: () => {
        // console.log("TOGGLE");
        set({ muteAmbientSound: !get().muteAmbientSound });
    },
    setLoadProgress: (data) => {
        set({ loadProgress: data });
    },
    setClickable: (data) => {
        // console.log("CLICKABLE", data);
        set({ clickable: data });
    },
    setIsCustomSkybox: (data) => { set({ isCustomSkybox: data }) },
    setChangeSceneCounter: (data, changeSceneName) => { set({ changeSceneCounter: data, changeSceneName: changeSceneName }); },
    setSceneProperties: (orbit, main) => set({ orbitOnly: orbit, mainScene: main }),
    setTime: (data) => { set({ time: data }) },
    setAutoTime: (data) => { set({ autoTime: data }) },
    setSceneAssets: (data) => set({ sceneAssets: data }),
    setScene: (sceneName) => {

        if (sceneName != get().scene) set({ lastScene: get().scene });

        // console.log("LAST SCENE IS", get().lastScene, sceneName, get().scene, sceneName != get().scene);

        set({ scene: sceneName })

    },
    clearClickableObjects: (data) => set({ clickableObjects: [] }),
    setClickableObject: (key, data) => {
        let co = get().clickableObjects;
        co[key] = data;
        set({ clickableObjects: co });
    },
    set360: (cond) => {
        set({ is360: cond });
    },
    setScenePanoramics: (data) => {
        set({ scenePanoramic: data });
    },
    setSceneSpawnPoint: (data) => {
        set({ spawnPoint: data });
    },
    setSceneMinimap: (data, sceneName) => {
        get().setScene(sceneName);
        data.sceneName = sceneName;
        set({ minimap: data });
    },
    set360Cubemap: (data) => {
        set({ cubemap360: data });
    },
    setNPCSelected: (data) => {
        set({ npcSelected: data });
    },
    setEndlessRunTrack: (data) => {
        set({ endlessRunTrack: data });
    },
    setEndlessRunPlayer: (data) => {
        set({ endlessRunPlayer: data });
    },
    setCustomSkybox: (data) => {
        set({ customSkybox: data });
    },
    setDynamicGroundColliders: (data) => {
        set({ dynamicGroundColliders: data });
    }
})))


const AudioContext = create(subscribeWithSelector((set, get) => ({
    scene: 'ut',
    sceneRef: null,
    conversationSpace: "",
    playerListToListen: [],
    playerListToMute: [],
    playerGridId: "",
    playerListSpeaking: [],
    volume: 1.0,
    bgmVolume: 1.0,
    isLocalSpeaking: false,
    setScene: (scn) => {
        set({ scene: scn })
    },
    setShowSpeaker: (playerId) => {
        // // console.log("SHOW SPEAKER CONTEXT", playerId)
    },
    setMuteSpeaker: (playerId) => {

        // // console.log("MUTE SPEAKER CONTEXT", playerId)
    },
    setConversationSpace: (newSpace, isNull) => {
        if (isNull && get().conversationSpace == newSpace) {
            set({ conversationSpace: "" });
        }
        else if (!isNull) {
            set({ conversationSpace: newSpace });
        }
    },
    setPlayerListToListen: (list) => {

        let newArray = [];
        let updateArray = false;
        let oldArray = get().playerListToListen;
        for (let i = 0; i < list.length; i++) {

            newArray.push(list[i]);

            if (!oldArray.find(x => x.i == list[i].i)) {
                updateArray = true;
            }
        }

        for (let i = 0; i < oldArray.length; i++) {
            if (!list.find(x => x.i == oldArray[i].i)) {
                updateArray = true;
            }
        }

        if (updateArray) {
            set({ playerListToListen: newArray });
        }

    },
    setPlayerListToMute: (list) => {

        let newArray = [];
        let updateArray = false;
        let oldArray = get().playerListToMute;
        for (let i = 0; i < list.length; i++) {

            newArray.push(list[i]);

            if (!oldArray.find(x => x.i == list[i].i)) {
                updateArray = true;
            }
        }

        for (let i = 0; i < oldArray.length; i++) {
            if (!list.find(x => x.i == oldArray[i].i)) {
                updateArray = true;
            }
        }

        if (updateArray) {
            set({ playerListToMute: newArray });
        }
    },
    setPlayerGridId: (id) => {
        if (id != get().playerGridId) {
            set({ playerGridId: id });
        }
    },
    setPlayerSpeaking: (playerId) => {
        // // console.log("SHOW SPEAKER CONTEXT", playerId)

        const speakingPlayers = get().playerListSpeaking;
        if (speakingPlayers.indexOf(playerId) == -1) {
            set((state) => ({
                ...state,
                playerListSpeaking: [...speakingPlayers, playerId]
            }))
        }
    },
    setPlayerSilence: (playerId) => {
        // // console.log("SHOW SPEAKER CONTEXT", playerId)

        let speakingPlayers = get().playerListSpeaking;
        set((state) => ({
            ...state,
            playerListSpeaking: null,
        }))

        const newList = speakingPlayers.filter((r) => r !== playerId);
        set((state) => ({
            ...state,
            playerListSpeaking: newList,
        }))

    },
    setVolume: (volume) => {
        set({ volume });
    },
    setBGMVolume: (bgmVolume) => {
        set({ bgmVolume });
    },
    setLocalSpeaking: (data) => {
        set({ isLocalSpeaking: data });
    },
})))

const math = new UtilTools();

const isGridNear = (source, target, range) => {
    return (target >= source - range && target <= source + range);
}

const isIncludeInGrid = (source, target) => {
    return isGridNear(source.x, target.x, 1) && isGridNear(source.y, target.y, 1) && isGridNear(source.z, target.z, 1);
}



const LocalPlayerContext = create(subscribeWithSelector((set, get) => ({
    id: math.makeid(16),
    name: math.makeid(6),
    displayName: "Player#" + math.makeid(6),
    gender: 0, // FIX ME : SEMENTARA DI SET 5 DLU UNTUK KEPERLUAN UT
    hair: 0,
    hand: 0,
    lowerBody: 0,
    upperBody: 0,
    feet: 0,
    head: 0,
    eyebrow: 0,
    eyes: 0,
    mouth: 0,
    skin: "animaverse",
    base: null,
    skinColor: "#e4a981",
    hairColor: "null",
    hat: -1,
    helmet: -1,
    topHead: -1,
    mask: -1,
    tiara: -1,
    earing: -1,
    facialHair: -1,
    preset: null,
    userDefaultBase: {},
    setLocalPlayer: (localPlayer) => {
        const getLocalPlayerSetting = get().localPlayerSetting;

        // console.log(getLocalPlayerSetting, localPlayer, "SET LOCAL PLAYER");
        set({ localPlayer, localPlayerSetting: { ...getLocalPlayerSetting, ...localPlayer } });
    },
    updateLocalPlayerSetting: async (props) => {
        const getLPS = get();
        let newArr = Object.assign({}, getLPS);
        newArr = { ...newArr, ...props };


        // let keys = Object.keys(props);
        // for (let i = 0; i < keys.length; i++) {
        //     const k = keys[i];
        //     newArr[k] = props[k];
        // }

        const stringifyJson = CircularJSON.stringify(newArr);

        // console.log(newArr, get(), "UPDATE ZUSTAND");
        localStorage.setItem("local_player_setting", stringifyJson)

        set({ ...CircularJSON.parse(stringifyJson) });
    },
})))

const GamePlayerContext = create(subscribeWithSelector((set, get) => ({
    isAccountBinded: false,
    isProfileUpdated: false,
    isPlayAsGuest: false,
    linkedProvider: "Guest",
    isSkinColorAvailable: false,
    isHairColorAvailable: false,
    userId: null,
    isHoverboard: false,
    localPlayerObject: null,
    localPlayerLastPosition: [0, 0, 0],
    listPlayer: [],
    listAura: [],
    playerGrid: null,
    positionPlayer: [],
    seatTaken: [],
    speakerPlayer: [],
    listSpeakerActive: [],
    sceneRef: null,
    mixerTime: null,
    mixerRound: null,
    isConnected: false,
    isPlayerHost: false,
    isMixerRoom: null,
    isJoinMixer: false,
    isMixerStarted: false,
    isHostControlledRoom: false,
    isPresenter: false,
    presenterid: "",
    audioRoom: "lobby",
    ping: 0,
    dropItem: null,
    collectedDropItem: [],
    collectedStaticItem: [],
    controlToggle: true,
    isPlayerDie: false,
    dieTeleportPoint: null,
    countDown: -1,
    timer: 0,
    isTimerStart: false,
    cameraDistance: 1,
    cameraMode: 1,
    firstAngle: 0,
    capturePhoto: false,
    controllableAnimation: [],
    isNPCHoverboard: false,
    popupLanguageId: 0,
    capturedPhoto: null,
    photoSpotId: [],
    currentRoom: 0,
    showChangeRoom: false,
    openChat: null,
    sendParamterOnChangeRoomCounter: 0,
    scene360:false,
    setScene360:(data)=>{
        set({scene360:data});
    },
    updateParameterRemotePlayer: '',
    setSendParamterOnChangeRoomCounter: (data) => {
        set({ sendParamterOnChangeRoomCounter: data });
    },
    setOpenChat: (data) => {
        set({ openChat: data });
    },
    setUserId: (data) => {
        set({ userId: data });
    },
    setShowChangeRoom: (data) => {
        set({ showChangeRoom: data });
    },
    setOpenChat: (data) => {
        console.log(data);
        set({ openChat: data });
    },
    setUserId: (data) => {
        set({ userId: data });
    },
    setShowChangeRoom: (data) => {
        set({ showChangeRoom: data });
    },
    setCurrentRoom: (data) => {
        set({ currentRoom: data });
    },
    setPhotoSpotId: (data) => {
        let photoSpotId = get().photoSpotId;
        if (!photoSpotId.includes(data)) {
            let pids = [];
            for (let i = 0; i < photoSpotId.length; i++) {
                pids.push(photoSpotId[i]);
            }
            if (!pids.includes(data)) pids.push(data);
            set({ photoSpotId: pids });
        }
    },
    removePhotoSpotId: (data) => {
        let photoSpotId = get().photoSpotId;
        if (photoSpotId.includes(data)) {

            let pids = [];
            for (let i = 0; i < photoSpotId.length; i++) {
                pids.push(photoSpotId[i]);
            }
            if (!pids.includes(data)) pids.push(data);
            set({ photoSpotId: data });

            const index = pids.indexOf(data);
            if (index > -1) {
                pids.splice(index, 1);
            }
            set({ photoSpotId: pids });
        }
    },
    setCapturedPhoto: (data) => {
        set({ capturedPhoto: data });
    },
    setPopupLanguageId: (data) => {
        set({ popupLanguageId: data });
    },
    setNPCHoverboard: (data) => {
        set({ isNPCHoverboard: data });
    },
    setControllableAnimation: (data) => {
        set({ controllableAnimation: data });
    },
    addControllableAnimation: (data) => {
        let d = get().controllableAnimation;
        
        let isExists = false;
        for (let i = 0; i < d.length; i++) {
            if(d[i].name == data.name){
                isExists = true;
            }
        }
        
        if(isExists) return;
        
        const newData = [...d, data];
        // console.log("CHANGE DATA GAME CONTEXT", data, d);
        set({ controllableAnimation: newData });
    },
    setCapturePhoto: (data) => {
        set({ capturePhoto: data });
    },
    setFirstAngle: (data) => {
        set({ firstAngle: data });
    },
    setCameraMode: (data) => {
        set({ cameraMode: data });
    },
    setCameraDistance: (data) => {
        set({ cameraDistance: data });
    },
    startTimer: (data) => {
        set({ isTimerStart: data });
    },
    setTimer: (data) => {
        set({ timer: data });
    },
    setCountDown: (data) => {
        set({ countDown: data });
    },
    setDieTeleportPoint: (data) => {
        set({ dieTeleportPoint: data, isPlayerDie: true });
    },
    setPlayerDie: (data) => {
        set({ isPlayerDie: data });
    },
    setIsSkinColorAvailable: (data) => {
        set({ isSkinColorAvailable: data });
    },
    setIsHairColorAvailable: (data) => {
        set({ isHairColorAvailable: data });
    },
    setIsHoverboard: (data) => {
        set({ isHoverboard: data });
    },
    setControlToggle: (data) => {
        set({ controlToggle: data });
    },
    setDropItem: (data) => {
        set({ dropItem: data });
    },
    addCollectedDropItemList: (data) => {
        let di = get().collectedDropItem;
        const newData = [...di, data];
        set({ dropItem: newData });
    },
    addCollectedStaticItemList: (data) => {
        let si = get().collectedStaticItem;
        const newData = [...si, data];
        set({ collectedStaticItem: newData });
    },
    getCollectedStaticItemList: () => {
        return get().collectedStaticItem;
    },
    setConnected: (data) => {
        set({ isConnected: data });
    },
    setPing: (data) => {
        set({ ping: data });
    },
    setPresenter: (presenter) => {
        set({ isPresenter: presenter });
    },
    setHostControlledRoom: (control) => {
        set({ isHostControlledRoom: control });
    },
    isFirstPersonCamera: false,
    isCursorLock: false,
    avatarList: null,
    setIsFirstPersonCamera: (data) => {
        set({ isFirstPersonCamera: data });
    },
    setIsCursorLock: (data) => {
        set({ isCursorLock: data });
    },
    setHostControlledRoomPresenterID: (id) => {
        if (id != undefined) {
            set({ presenterid: id });
            set({ isHostControlledRoom: true });
        }
        else {
            set({ presenterid: "" });
            set({ isHostControlledRoom: false });
        }
    },
    setMixer: (time, round) => {
        set({ mixerTime: time, mixerRound: round });
    },
    setAudioRoom: (ar) => {
        if (get().audioRoom != ar) set({ audioRoom: ar });
    },
    setPlayerHost: (isHost) => {
        // console.log("SET HOST", isHost);
        set({ isPlayerHost: isHost });
    },
    setMixerRoom: (mixerRoom) => {
        set({ isMixerRoom: mixerRoom });
    },
    setJoinMixerRoom: (isJoin) => {
        set({ isJoinMixer: isJoin });
    },
    setMixerRoomStarted: (isStarted) => {
        set({ isMixerStarted: isStarted });
    },
    setLocalPlayerObject: (localPlayer) => {
        set({ localPlayerObject: localPlayer });
    },
    setLocalPlayerLastPosition: () => {
        const position = get().localPlayerObject.current.position;
        // // console.log("HERE", position);
        set({ localPlayerLastPosition: [position.x, position.y, position.z] });
    },
    resetLocalPlayerLastPosition: () => {
        set({ localPlayerLastPosition: [0, 0, 0] });
    },

    setListRemotePlayer: (listPlayerRaw) => {

        const listPlayer = listPlayerRaw.filter((data) => {
            return data.g != undefined
        }).map((r) => ({
            id: r.u.i,
            name: r.u.n,
            displayName: r.u.a,
            gender: r.u.g,
            upperBody: r.u.b,
            hair: r.u.h,
            shoes: r.u.d,
            lowerBody: r.u.l,
            props: r.u.sh,
            uid: r.u.uid ? r.u.uid : null,
            skin: "animaverse"
        }));

        const listPlayerSorted = listPlayer.sort(function (a, b) {
            return a.displayName ? a.displayName.localeCompare(b.displayName) : false;
        });

        if (JSON.stringify(get().listPlayer) != JSON.stringify(listPlayerSorted)) {

            // console.log(listPlayerSorted);
            set({ listPlayer: listPlayerSorted })
        }
    },
    setListAuraRemotePlayer: (listPlayerRaw) => {

        const listPlayer = listPlayerRaw.filter((data) => {
            return data.g != undefined && !isIncludeInGrid(get().playerGrid, data.g)
        }).map((r) => ({
            id: r.u.i,
            name: r.u.n,
            displayName: r.u.a,
            gender: r.u.g,
            upperBody: r.u.b,
            hair: r.u.h,
            shoes: r.u.d,
            lowerBody: r.u.l,
            props: r.u.sh
        }));

        const listPlayerSorted = listPlayer.sort(function (a, b) {
            return a.displayName.localeCompare(b.displayName);
        });

        if (JSON.stringify(get().listAura) != JSON.stringify(listPlayerSorted)) {
            set({ listAura: listPlayerSorted })
        }
    },
    setPositionRemotePlayer: (positionPlayer, sceneName) => {
        const key = Object.keys(positionPlayer);
        const rawList = key.map(data => positionPlayer[data]);
        const playerSeat = [];

        // // console.log(rawList)
        // let pID = get().localPlayerSetting.playerId;
        // if (positionPlayer[pID] && positionPlayer[pID].g) set({ playerGrid: positionPlayer[pID].g });
        // else return;

        // for (let i = 0; i < key.length; i++) {
        //     const d = positionPlayer[key[i]];
        //     if (d.p && d.p[11]) playerSeat.push(d.p[11]);
        // }

        // if (rawList.length > 50) {
        //     rawList.splice(50, rawList.length - 2)
        // }
        // get().setListRemotePlayer(rawList);
        // get().setListAuraRemotePlayer(rawList);
        set({ positionPlayer })

        // if (!(playerSeat.length === get().seatTaken.length && playerSeat.every(function (value, index) { return value === get().seatTaken[index] })))
        //     set({ seatTaken: playerSeat })
    },
    setSpeakerVisible: (playerId, visible) => {
        const speakerPlayer = get().speakerPlayer;
        if (speakerPlayer[playerId] != visible) {
            speakerPlayer[playerId] = visible;

            // // console.log(speakerPlayer)
            // set({ speakerPlayer })
            set((state) => ({
                ...state,
                speakerPlayer,
                listSpeakerActive: speakerPlayer.map((r, i) => r == true && i)
            }))


        }
    },
    setGamePlayerContext: (payload) => {
        set({ ...payload })
    },
    refreshAvatarList: async () => {
        await axios.get(`${process.env.RESOURCE_URL}/avatars/avatars.json`, {
            crossDomain: true
        })
            .then(res => res.data)
            .then(async (res) => {
                set({ avatarList: res });
            });
    },
    initiateAvatarList: async () => {
        await axios.get(`${process.env.RESOURCE_URL}/avatars/avatars.json`, {
            crossDomain: true
        })
            .then(res => res.data)
            .then(async (res) => {
                if (get().avatarList == null) {
                    set({ avatarList: res });
                }
            });
    },
    getLocalPlayerSetting: () => {
        const getLocalPlayerSetting = get().localPlayerSetting;
        return getLocalPlayerSetting;
    },
    setUpdateParameterRemotePlayer: (data) => {
        set({ updateParameterRemotePlayer: data })
    },
    getListPlayer: () => {
        return get().listPlayer
    },
    setListPlayer: (data) => {
        set({ listPlayer: data })
    }
})))

const GameConfigContext = create(subscribeWithSelector((set, get) => ({
    antiAliasing: false,
    shadow: false,
    frameLimiter: false,
    loading: true,
    postProcessing: false,
    loadingMessage: '',
    loadingBackground: '',
    loadingLogo: '',
    loadingTextServer: '',
    loadingColorClass: '',
    minimapLogo: '',
    showSettingMenu: false,
    daynightValue: 0.5,
    activeTab: null,
    loadSceneFinish: false,
    graphicQuality: 0,
    hideAnimoji: [],
    chatServer: null,
    appId: null,
    messageLink: null,
    setMessageLink: (data) => {
        set({ messageLink: data });
    },
    setAppId: (data) => {
        set({ appId: data });
    },
    setGraphicQuality: (data) => {
        set({ graphicQuality: data });
    },
    setLoadSceneFinish: (data) => {
        set({ loadSceneFinish: data });
    },
    setLoading: (loading, loadingMessage) => {
        // // console.log("SET LOADING", loading, loadingMessage);
        // console.error("HERE");
        set({ loading, loadingMessage })
    },
    setLoadingScene: (loadingMessage, loadingLogo, loadingBackground) => {
        set({ loadingMessage, loadingLogo, loadingBackground })
    },
    setLoadingTextServer: (text) => {
        // console.log(text)
        set({ loadingTextServer: text })
    },
    setLoadingColorClass: (color) => {
        // console.log(color)
        set({ loadingColorClass: color })
    },
    setMinimapLogo: (logo) => {
        set({ minimapLogo: logo })
    },
    setSetting: (setting) => {
        set({ ...setting })
        // // console.log(get());
    },
    setShowSettingMenu: (isShow, setActiveTab) => {
        set({ showSettingMenu: isShow, activeTab: setActiveTab ? setActiveTab : null })
    },
    setDaynightValue: (val) => {
        set({ daynightValue: val })
    },
    setHideAnimoji: (listAnim) => {
        set({ hideAnimoji: listAnim })
    },
    setChatServer: (data) => {
        set({ chatServer: data });
    }
})))



const PlayerSelectionContext = create(subscribeWithSelector((set, get) => ({
    props: null,
    upperBody: null,
    feet: null,
    lowerBody: null,
    hair: null,
    gender: null,
    skin: null,
    base: null,
    head: null,
    eyebrow: null,
    eyes: null,
    mouth: null,
    skinColor: null,
    hairColor: null,

    _props: null,
    _upperBody: null,
    _feet: null,
    _lowerBody: null,
    _hair: null,
    _gender: null,
    _skin: null,
    _base: null,
    _head: null,
    _eyebrow: null,
    _eyes: null,
    _mouth: null,
    _skinColor: null,
    _hairColor: null,

    selectPlayerCompleted: false,
    setItem: (prop, state) => {
        const propSelection = get();
        propSelection[prop] = state;
        // // console.log(prop, state, propSelection, "PLAYER SELECTION SEBELOM NULL")
        set({ ...propSelection })
        propSelection[prop] = null;
        set({ ...propSelection })

        // // console.log(prop, state, propSelection, "PLAYER SELECTION SETELAH NULL")
    },

    setSelectPlayerCompleted: (state) => {
        set({ selectPlayerCompleted: state })
    }
})))

const RtcLocalContext = create(subscribeWithSelector((set, get) => ({
    listStream: [],
    camera: null,
    screen: null,
    audio: null,
    setShareScreen: (stream) => {
        // // console.log("START SHARE SCREEN", stream);
        set({ screen: stream })
    },
    setStreamPlayer: (playerId) => {
        const listStream = get().listStream;
        const newListStream = Object.assign({}, listStream)
        newListStream[playerId] = true;
        set({ listStream: newListStream })
    },
    getStreamPlayer: (playerId) => {
        const listStream = get().listStream;
        return listStream[playerId];
    }
})));


const JoystickContext = create(subscribeWithSelector((set, get) => ({
    position: null,
    jump: null,
    down: null,
    setPosition: (position) => {
        set({ position })
    },
    setJump: (jump) => {
        set({ jump })
    },
    setDown: (down) => {
        set({ down })
    }
})))

const FunctionVariableContext = create(subscribeWithSelector((set, get) => ({
    createMixerRoom: null,
    setCreateMixerRoom: (func) => {
        // // console.log("SET AH", func);
        set({ createMixerRoom: func });
    },

    joinMixerRoom: null,
    setJoinMixerRoom: (func) => {
        set({ joinMixerRoom: func });
    },

    leaveMixerRoom: null,
    setLeaveMixerRoom: (func) => {
        set({ leaveMixerRoom: func });
    },

    removeMixerRoom: null,
    setRemoveMixerRoom: (func) => {
        set({ removeMixerRoom: func });
    },

    startMixerRoom: null,
    setStartMixerRoom: (func) => {
        set({ startMixerRoom: func });
    },

    stopMixerRoom: null,
    setStopMixerRoom: (func) => {
        set({ stopMixerRoom: func });
    },

    createHostControlRoom: null,
    setCreateHostControlRoom: (func) => {
        set({ createHostControlRoom: func });
    },

    stopHostControlRoom: null,
    setSetStopHostControlRoom: (func) => {
        set({ stopHostControlRoom: func });
    },

    setRoomPresenter: null,
    setSetRoomPresenter: (func) => {
        set({ setRoomPresenter: func });
    },

    playerIdle: null,
    setPlayerIdle: (func) => {
        set({ playerIdle: func });
    },

    reconnectSocket: null,
    setReconnectSocket: (func) => {
        set({ reconnectSocket: func });
    },

    disconnectFromSocket: null,
    setDisconnectSocket: (func) => {
        set({ disconnectFromSocket: func });
    },

    onTeleportDetection: null,
    setOnTeleportDetection: (func) => {
        set({ onTeleportDetection: func });
    },

    clickPopup: null,
    setClickPopup: (func) => {
        set({ clickPopup: func });
    },

    onChangeSceneDetection: null,
    setOnChangeSceneDetection: (func) => {
        set({ onChangeSceneDetection: func });
    },

    onSelectEmoji: null,
    setOnSelectEmoji: (func) => {
        set({ onSelectEmoji: func });
    },

    openMinimap: null,
    setOpenMinimap: (func) => {
        set({ openMinimap: func });
    },

    capturePhoto: null,
    setCapturePhoto: (func) => {
        set({ capturePhoto: func })
    },

    clickSocialMediaButton: null,
    setClickSocialMediaButton: (func) => {
        set({ clickSocialMediaButton: func });
    },

    clickActionButton: null,
    setClickActionButton: (func) => {
        set({ clickActionButton: func });
    },

    changeServerRoom: null,
    setChangeServerRoom: (data) => {
        set({ changeServerRoom: data });
    },

    openLoginPopup: null,
    setOpenLoginPopup: (data) => {
        set({ openLoginPopup: data });
    },

    endlessRunButtonLeft: null,
    setEndlessRunButtonLeft: (data) => {
        set({ endlessRunButtonLeft: data });
    },

    endlessRunButtonRight: null,
    setEndlessRunButtonRight: (data) => {
        set({ endlessRunButtonRight: data });
    },

    onLocalPlayerFinishLoad: null,
    setOnLocalPlayerFinishLoad: (data) => {
        set({ setOnLocalPlayerFinishLoad: data });
    },

    onTriviaAnswer: null,
    setOnTriviaAnswer: (data) => {
        set({ onTriviaAnswer: data });
    },

    onOpenAudioAndCameraSetting: null,
    setOnOpenAudioAndCameraSetting: (data) => {
        set({ onOpenAudioAndCameraSetting: data });
    },

    changePanoramicID:null,
    setChangePanoramicID:(data)=>{
        set({changePanoramicID:data});
    }
})))


const AccountContext = create(subscribeWithSelector((set, get) => ({
    showLogin: false,
    setShowLogin: (show) => {
        set({ showLogin: show });
    }

})))


const NotificationContext = create(subscribeWithSelector((set, get) => ({
    toast: null,
    addToast: (toastData) => {
        set({ toast: { ...toastData, ts: Date.now() } });
        set({ toast: null });
    }

})))

const AnimationContext = create(subscribeWithSelector((set, get) => ({
    animationSource: null,
    isInit: false,
    setAnimationSource: (data) => {
        set({ animationSource: data });
        set({ isInit: true });
    },
    initAnimation: async () => {
        // console.log("INIT ANIMATION", get().isInit);

        if (get().isInit) return;
        set({ isInit: true });

        if (get().animationSource != null) return;
        // console.log("LOAD ANIMATION", get().isInit);

        await axios.get(`${process.env.RESOURCE_URL}/animations/animations.json`, { crossDomain: true })
            .then(res => res.data)
            .then(async (res) => {


                let source = {};
                for (let i = 0; i < res.animation.length; i++) {
                    const [gltf] = await Promise.all([
                        Gltf2.fetch(`${process.env.RESOURCE_URL}/animations/${res.animation[i]}`)
                    ]);

                    let animationName = res.animation[i].replace('.gltf', '');
                    source[animationName] = gltf;
                }

                set({ animationSource: source });
            });
    }

})))



const EndlessRunContext = create(subscribeWithSelector((set, get) => ({
    gameStart: false,
    gameOver: false,
    retryGame: false,
    userCoins: 0,
    getCoins: (coin) => {

        set({ userCoins: get().userCoins / 1 + coin / 1 });
    },
    resetCoins: () => {
        set({ userCoins: 0 });
    },
    setGameStart: (start) => {
        // console.log(start, "SET GAME START ON")
        set({ gameStart: start });
    },
    setGameOver: (over) => {
        set({ gameOver: over })
    },
    setRetry: (retry) => {
        set({ retryGame: retry })
    }

})))


const TreasureHuntContext = create(subscribeWithSelector((set, get) => ({
    userData: {},
    pointCollected: null,
    pointType: null,
    voteModal: null,
    eventModal: null,
    clickTenant: null,
    totem: null,
    setUserData: (data) => {
        set({ userData: data });
    },
    setPoint: (point) => {
        set({ pointCollected: point / 1 });
    },
    addPoint: (id, type) => {
        set({ pointCollected: id, pointType: type });
    },
    showVoteModal: (param) => {
        set({ voteModal: param })

        set({ voteModal: null })
    },
    clickTenantHandler: (param) => {
        set({ clickTenant: param })

        set({ clickTenant: null })
    },
    photoTotem: (param) => {
        set({ totem: param })

        set({ totem: null })
    },
    showEventModal: () => {
        set({ eventModal: true })

        set({ eventModal: null })
    }
})))


const PlayerModelContext = create(subscribeWithSelector((set, get) => ({
    playerModel: {},
    loadPlayerModel: async (src) => {
        let playerModel = get().playerModel;
        if (playerModel[src] == undefined) {
            let result = await axios.get(src, {
                crossDomain: true
            })
                .then(baseData => {
                    playerModel[src] = baseData.data;
                    set({ playerModel: playerModel });
                    return playerModel[src];
                });
            return result;
        }
        else {
            return playerModel[src];
        }

    }
})))


const VisitorTrackerContext = create(subscribeWithSelector((set, get) => ({
    event: null,
    additionalData: null,
    setVisitorTrackerData: (data, additionalDataParam) => {
        set({ event: data, additionalData: additionalDataParam });

        set({ event: null, additionalData: null })
    },
})))

export {
    SceneContext,
    AudioContext,
    GamePlayerContext,
    GameConfigContext,
    PlayerSelectionContext,
    RtcLocalContext,
    JoystickContext,
    FunctionVariableContext,
    AccountContext,
    NotificationContext,
    AnimationContext,
    EndlessRunContext,
    TreasureHuntContext,
    PlayerModelContext,
    VisitorTrackerContext,
    LocalPlayerContext
}

