import AgoraRTC from "agora-rtc-sdk-ng"
import { AudioContext, GamePlayerContext } from "../game/context/game-context";
const { RtcTokenBuilder, RtmTokenBuilder, RtcRole, RtmRole } = require('agora-access-token')
let options =
{
    // Pass your App ID here.
    appId: '',
    // Set the channel name.
    channel: '',
    // Pass your temp token here.
    token: '',
    // Set the user ID.
    uid: 0,
};

let channelParameters =
{
    // A variable to hold a local audio track.
    localAudioTrack: null,
    // A variable to hold a local video track.
    localVideoTrack: null,
    // A variable to hold a remote audio track.
    remoteAudioTrack: [],
    // A variable to hold a remote video track.
    remoteVideoTrack: [],
};

let agoraEngine = null;

export const generateTokenAgora = (roomid, playerId) => {
    const appId = process.env.AGORA_APP_ID;
    const appCertificate = process.env.AGORA_APP_CERTIFICATE;
    const channelName = roomid;
    const uid = playerId;
    const role = RtcRole.PUBLISHER;
    const expirationTimeInSeconds = 3600 * 24
    const currentTimestamp = Math.floor(Date.now() / 1000)
    const privilegeExpiredTs = currentTimestamp + expirationTimeInSeconds
    // Build token with uid
    const tokenA = RtcTokenBuilder.buildTokenWithUid(appId, appCertificate, channelName, uid, role, privilegeExpiredTs);
    console.log("Token with integer number Uid: " + tokenA);
    return tokenA
}

export const InitiateAgora = async ({ room, playerId, playerName, setShareScreen }) => {
    // Create an instance of the Agora Engine
    agoraEngine = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    agoraEngine.enableAudioVolumeIndicator();

    // Dynamically create a container in the form of a DIV element to play the remote video track.
    const remotePlayerContainer = document.createElement("div");

    // Dynamically create a container in the form of a DIV element to play the local video track.
    const localPlayerContainer = document.createElement('div');

    // Specify the ID of the DIV container. You can use the uid of the local user.
    localPlayerContainer.id = playerId;

    // Set the textContent property of the local video container to the local user id.
    localPlayerContainer.textContent = "Local user " + playerId;

    // Set the local video container size.
    localPlayerContainer.style.position = "absolute";
    localPlayerContainer.style.left = "0";
    localPlayerContainer.style.width = "640px";
    localPlayerContainer.style.height = "480px";
    localPlayerContainer.style.padding = "15px 5px 5px 5px";

    // Set the remote video container size.
    remotePlayerContainer.style.position = "absolute";
    remotePlayerContainer.style.right = "0";
    remotePlayerContainer.style.width = "640px";
    remotePlayerContainer.style.height = "480px";
    remotePlayerContainer.style.padding = "15px 5px 5px 5px";

    console.log(process.env.AGORA_APP_ID, room, process.env.AGORA_TOKEN, playerId)
    const token = generateTokenAgora(room, playerId);

    const listLocalTrack = [];

    await agoraEngine.join(process.env.AGORA_APP_ID, room, token, playerId);
    // try {
    //     // Create a local audio track from the audio sampled by a microphone.
    //     channelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    //     channelParameters.localAudioTrack.setMuted(true);

    //     listLocalTrack.push(channelParameters.localAudioTrack)
    // } catch (err) {
    //     console.log(err)
    // }

    // try {
    //     // Create a local video track from the video captured by a camera.
    //     channelParameters.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
    //     channelParameters.localVideoTrack.setMuted(true);

    //     listLocalTrack.push(channelParameters.localVideoTrack);

    //     // Append the local video container to the page body.
    //     document.body.append(localPlayerContainer);

    //     channelParameters.localVideoTrack.play(localPlayerContainer);
    // } catch (err) {
    //     console.log(err)
    // }

    if (listLocalTrack.length > 0) {
        // Publish the local audio and video tracks in the channel.
        await agoraEngine.publish(listLocalTrack);
        // Play the local video track.
    }
    console.log("publish success!");

    // Listen for the "user-published" event to retrieve a AgoraRTCRemoteUser object.
    agoraEngine.on("user-published", async (user, mediaType) => {
        // Subscribe to the remote user when the SDK triggers the "user-published" event.
        await agoraEngine.subscribe(user, mediaType);
        console.log(user, mediaType, "subscribe success");
        // Subscribe and play the remote video in the container If the remote user publishes a video track.
        if (mediaType == "video") {
            //  Media screen / live stream
            if (user?._cname) {
                const streamScreen = new MediaStream();
                streamScreen.addTrack(user?.videoTrack?.getMediaStreamTrack());

                document.getElementById('video-share-screen-elem').srcObject = streamScreen;
                setShareScreen(document.getElementById('video-share-screen-elem'));
            } else {
                // Retrieve the remote video track.
                channelParameters.remoteVideoTrack.push(user.videoTrack);
                // Retrieve the remote audio track.
                channelParameters.remoteAudioTrack.push(user.audioTrack);
                // Save the remote user id for reuse.
                // Specify the ID of the DIV container. You can use the uid of the remote user.
                remotePlayerContainer.id = user.uid.toString();
                remotePlayerContainer.textContent = "Remote user " + user.uid.toString();
                // Append the remote container to the page body.
                document.body.append(remotePlayerContainer);
                channelParameters.remoteAudioTrack.push(user.audioTrack);
            }
        }
        // Subscribe and play the remote audio track If the remote user publishes the audio track only.
        if (mediaType == "audio") {
            // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
            // channelParameters.remoteAudioTrack = user.audioTrack;
            // Play the remote audio track. No need to pass any DOM element.
            user.audioTrack.play();
            channelParameters.remoteAudioTrack.push(user.audioTrack);
        }
        // Listen for the "user-unpublished" event.
        agoraEngine.on("user-unpublished", async (user, mediaType) => {
            console.log(user.uid + "has left the channel", user);
            removeVideoDiv(user.id)
            document.getElementById('video-share-screen-elem').srcObject = null;
            setShareScreen(null);
            channelParameters.remoteVideoTrack = channelParameters.remoteVideoTrack.filter((r) => r._userId == user.uid || r == undefined);
        });


    });
    return agoraEngine
}

export const changeLocalVideoTrack = (track) => {
    channelParameters.localVideoTrack = AgoraRTC.createCustomVideoTrack({
        mediaStreamTrack: track
    });
}

export const changeLocalAudioTrack = async (track) => {
    const shouldPublish = channelParameters.localAudioTrack == null;
    console.log(shouldPublish);
    channelParameters.localAudioTrack = AgoraRTC.createCustomAudioTrack({
        mediaStreamTrack: track
    });

    if (shouldPublish) {
        const listLocalTrack = [];
        listLocalTrack.push(channelParameters.localAudioTrack);
        await agoraEngine.publish(listLocalTrack);
    }
}

export const unmuteAudio = () => {
    channelParameters.localAudioTrack.setMuted(false);
}

export const muteAudio = () => {
    channelParameters.localAudioTrack.setMuted(true);
}

export const unmuteVideo = () => {
    channelParameters.localVideoTrack.setMuted(false);
}

export const muteVideo = () => {
    channelParameters.localVideoTrack.setMuted(true);
}

export const removeVideoDiv = (elementId) => {
    console.log("Removing " + elementId + "Div");
    let Div = document.getElementById(elementId);
    if (Div) {
        Div.remove();
    }
}

export const listRemoteAudioStream = () => {
    return channelParameters.remoteAudioTrack;
}

export const createStreamAgora = async ({onEndedStream}) => {
    const screenVideo = await AgoraRTC.createScreenVideoTrack();
    screenVideo.on('onended', onEndedStream)
    return screenVideo;
}